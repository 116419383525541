import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

// Import necessary Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { reverseDateTime, getMiddleTime } from "utils/ConvertDateToString";

// Register the components needed
ChartJS.register(
  CategoryScale, // For the x-axis category scale
  LinearScale, // For the y-axis linear scale
  LineElement,
  BarElement, // For line charts
  PointElement,
  Title, // For chart title
  Tooltip, // For tooltips
  Legend // For the chart legend
);

const AcceptanceRateChart = ({ analyticsData, type, text, chartType }) => {
  const [chartData, setChartData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!analyticsData || !type) return;

    let labels = [];
    let datasets = [];

    if (type === "paymentMethod" || type === "processorCode") {
      // Group data by date range
      const groupedData = analyticsData.reduce((acc, item) => {
        const dateRange = reverseDateTime(
          getMiddleTime(item.startDate, item.endDate)
        );
        if (!acc[dateRange]) acc[dateRange] = [];
        acc[dateRange].push(item);
        return acc;
      }, {});

      labels = Object.keys(groupedData);
      const groupKey =
        type === "paymentMethod" ? "paymentMethod" : "processorCode";
      const categories = [
        ...new Set(analyticsData.map((item) => item[groupKey])),
      ];

      // Create datasets for each category
      datasets = categories.map((category, index) => ({
        label: category =  category.indexOf("_") > 0 ?  category.split('_').join(' '): category,
        data: labels.map(
          (label) =>
            groupedData[label].find((item) => item[groupKey] === category)
              ?.acceptanceRate || 0
        ),
        backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
          Math.random() * 256
        )}, ${Math.floor(Math.random() * 256)}, 0.6)`,
      }));
    } else if (type === "successGraph") {
      // For successGraph, show only acceptance rate as a line chart
      labels = analyticsData.map((item) =>
        reverseDateTime(getMiddleTime(item.startDate, item.endDate))
      );
      datasets = [
        {
          label: "Acceptance Rate",
          data: analyticsData.map((item) => item.acceptanceRate),
          borderColor: "rgba(54, 162, 235, 0.6)",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          fill: true,
          tension: 0.4,
        },
      ];
    }

    setChartData({
      labels,
      datasets,
    });
  }, [analyticsData, type]);

  if (!chartData) return <p>Loading...</p>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: text ? `Acceptance Rate by ${text}` : "Acceptance Rate",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${(context.raw).toFixed(2)}%`;
          }
        }
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          // text: text, // x-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: "Acceptance Rate (%)", // y-axis label
        },
        ticks: {
          stepSize:10,
          callback: function (value) {
            return value + "%"; // Format y-axis values as percentages
          },
       
        },
        min:0,
        max:100,
      },
    },
  };

  if (type === "successGraph") {
    return <Line data={chartData} options={options} />;
  } else {
    return <Bar data={chartData} options={options} />;
  }
};

export default AcceptanceRateChart;
