import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SignOut from "components/Icons/SignOut";
import Logout from "Logout";
import "./ProfileMenu.css";


const ProfileMenu = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const menuRef = useRef(null);
  const MERCHANT_NAME = useSelector((state) => state.merchant.merchantName);
 
  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  return (
    <div className="position-relative" ref={menuRef}>
      {/* Profile Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="btn rounded-circle p-0"
        style={{ width: "40px", height: "40px" }}
      >
        <div className="profile-icon">
          <span>{MERCHANT_NAME[0]}</span>

        </div>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className="dropdown-menu show position-absolute end-0 mt-2 shadow rounded"
          style={{ width: "270px" }}
        >
          {/* User Info */}
          <div className="dropdown-header d-flex align-items-center p-3 border-bottom">
            <div className="profile-icon me-3">
              <span>{MERCHANT_NAME[0]}</span>

            </div>
            <div>
              <h6 className="mb-0">{MERCHANT_NAME?.split("@")[0]} </h6>
              {/* <small className="text-muted">robert@kt.com</small> */}
            </div>
          </div>

         
          <div className="d-flex align-items-center language-select-wrapper justify-content-between mt-2 mx-3">
            <label htmlFor="language-select" className="me-2">
              {t("LANGUAGE")}:
            </label>
            <select
             
              id="language-select"
              onChange={handleLanguageChange}
              defaultValue={i18n.language} // Ensure the current language is selected in the dropdown
            >
              <option value="en">English</option>
              <option value="fr">Français</option>
              {/* Add more languages here */}
            </select>
          </div>

          {/* Divider */}
          <div className="dropdown-divider"></div>

        
          <button
            onClick={() =>
              Logout()
      
            } className="dropdown-item">
            <SignOut />
            <span className="menu-title"> Log out</span></button>
         
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
