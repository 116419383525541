import React from 'react';
import ExcelJS from 'exceljs';
import useCustomSnackbar from 'components/SnackBar/CustomSnackBar';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const Excel = ({headers,items,isRangeValid,isDisplayColumnChanged,isDownloadProgess,isReadyForDownload,isEmptyData})=>{

  const { showCustomSnackbar  } = useCustomSnackbar();
  dayjs.extend(utc);
  const autoFitColumnWidth = (worksheet, minimalWidth = 10) => {
    worksheet.columns.forEach((column) => {
      let maxColumnLength = 0;
      if (column && typeof column.eachCell === 'function') {
        column.eachCell({ includeEmpty: true }, (cell) => {
          maxColumnLength = Math.max(
            maxColumnLength,
            minimalWidth,
            cell.value ? cell.value.toString().length : 0
          );
        });
        column.width = maxColumnLength + 2; 
      }
    });
    return worksheet; 
  };
  const handleDownload = async () => {
    
    if(isRangeValid){
      showCustomSnackbar("error",'Please select a date range within 90 days',"center","top",2000);
      return;
    }
    else if ((!items || items.length === 0) && (isReadyForDownload || isEmptyData)) {
      showCustomSnackbar("error",'No Data found to download',"center","top",2000);
      return;
      } 
      else if(isDownloadProgess){
        showCustomSnackbar("success",'Download is in Progress',"center","top",400);
        
      }
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        worksheet.addRow(headers.map(header => header.name));
        items.forEach(row => {
          worksheet.addRow(Object.values(row));
        });
        autoFitColumnWidth(worksheet);
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
      const date = dayjs().local();
      const timestamp = date.format('YYYY-MM-DD_HH-mm-ss');
      const filename = `data_${timestamp}.xlsx`;
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        setTimeout(() => { 
        showCustomSnackbar("success","Download completed successfully","center","top",2000);
        }, 1000);
      } catch (error) {
       console.log(error);
      }
  };

  return (
    <>
      <div className="d-flex justify-content-end" onClick={() => !isDisplayColumnChanged && handleDownload()}  disabled={!items || items.length === 0}>
          Excel
      </div>
    </>
  );
}

export default Excel;
