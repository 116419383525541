import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);
const optionsShort = {
    month: 'short',
    day: '2-digit'
};
const getMiddleTime = (startDate, endDate) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  return new Date((start + end) / 2).toLocaleDateString('en-GB',optionsShort);
};

const generateColor = (opacity = 0.6) => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const TotalAmountChart = ({ analyticsData, type, text }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Group data by date and currency
    const groupedData = analyticsData.reduce((acc, item) => {
      const date = getMiddleTime(item.startDate, item.endDate);
      const currency = item.currency;
      const groupKey = `${date}_${currency}`;

      if (!acc[groupKey]) {
        acc[groupKey] = { date, currency, totalAmount: 0, details: {} };
      }

      acc[groupKey].totalAmount += item.totalAmount;

      const detailKey = `${item[type.split("_")[0]]} (${currency})`; // Combine paymentMethod/processorCode with currency
      if (!acc[groupKey].details[detailKey]) {
        acc[groupKey].details[detailKey] = 0;
      }
      acc[groupKey].details[detailKey] += item.totalAmount;

      return acc;
    }, {});

    // Prepare labels, datasets, and detail lines
    const labels = Array.from(
      new Set(Object.values(groupedData).map((group) => group.date))
    );
    const currencies = Array.from(
      new Set(Object.values(groupedData).map((group) => group.currency))
    );

    const currencyColors = currencies.reduce((acc, currency) => {
      acc[currency] = generateColor(0.4); // Assign transparent color for each currency
      return acc;
    }, {});

    const datasets = currencies.map((currency) => {
      return {
        label: currency,
        data: labels.map((label) => {
          const matchingGroup = Object.values(groupedData).find(
            (group) => group.date === label 
          );
          return matchingGroup ? matchingGroup.totalAmount : 0;
        }),
        backgroundColor: currencyColors[currency],
        stack: currency, // Stack bars by currency
      };
    });

    // Add line datasets for paymentMethod or processorCode
   /* const addedDetails = new Set(); // Track added details to avoid duplication
    Object.values(groupedData).forEach((group) => {
      Object.keys(group.details).forEach((key) => {
        if (!addedDetails.has(key)) {
          datasets.push({
            type: "line",
            label: key, // Keep the combined label for hover
            data: labels.map((label) => {
              const matchingGroup = Object.values(groupedData).find(
                (grp) => grp.date === label && grp.currency === group.currency
              );
              return matchingGroup && matchingGroup.details[key]
                ? matchingGroup.details[key]
                : 0;
            }),
            borderColor: "rgba(255, 99, 132, 0.8)",
            borderWidth: 2,
            fill: false,
          });
          addedDetails.add(key);
        }
      });
    });*/

    setChartData({
      labels,
      datasets,
    });
  }, [analyticsData, type]);

  if (!chartData) return <p>Loading...</p>;

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: "Total Amount",
        },
        ticks: {
          callback: function (value) {
            const units = ['', 'K', 'M', 'B'];
            const k = 1000;
            const magnitude = Math.floor(Math.log(value) / Math.log(k));
            return  value !=0 ? (value / Math.pow(k, magnitude)).toFixed(1) + units[magnitude] :value;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          filter: function (legendItem) {
            return legendItem.text && legendItem.text.includes(legendItem.text); // Only show Total Amount legends
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.type === "line") {
              return `${context.dataset.label}: ${context.raw}`;
            }
            const units = ['', 'K', 'M', 'B'];
            const k = 1000;
            const magnitude = Math.floor(Math.log(context.raw) / Math.log(k));
            const formattedAmount = (context.raw !=0) ? (context.raw / Math.pow(k, magnitude)).toFixed(1) + ' ' + units[magnitude] :context.raw;
            return `${context.dataset.label}: ${formattedAmount}`;
          },
        },
      },
      title: {
        display: true,
        text: `Total Amount with ${text}`,
      },
    },
  };
  return (type === 'currency' && <Bar data={chartData} options={options} />);
};

export default TotalAmountChart;
