import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useIdle } from "react-use";
import Home from "./components/Home/Home";
import Logout from "./Logout";
import { httpClient } from "./utils/HttpClient.js";
import { KCClient } from "./utils/KC.js";
import Keycloak from "keycloak-js";
import { KEYCLOAK_URL, REALM, CLIENT_ID } from "constants/Constants";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  setMerchantId,
  setMerchantName,
  setEnvironment,
  setPreviousEnvironment,
  setIsKeyCloakTokenExchanged,
} from "./redux/reducers/merchant";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./i18n";
import { setChangeInEnvironment } from "constants/Constants";
console.log(process.env);

function App() {
  const environment = useSelector((state) => state.merchant.environment);
  const previousEnvironment = useSelector(
    (state) => state.merchant.previousEnvironment
  );
  const isKeyCloakTokenExchanged = useSelector(
    (state) => state.merchant.isKeyCloakTokenExchanged
  );
  const [keycloakInstance, setKc] = useState(null);
  useEffect(() => {
    dispatch(setIsKeyCloakTokenExchanged(null));
    setChangeInEnvironment(process.env.REACT_APP_NODE_ENV);
    dispatch(setEnvironment(process.env.REACT_APP_NODE_ENV));
    dispatch(setPreviousEnvironment(process.env.REACT_APP_NODE_ENV));
  }, []);
  useEffect(() => {
    if (keycloakInstance && keycloakInstance.token) {
      const data = exchangeToken(keycloakInstance);
    }
  }, [environment]);
  const exchangeToken = async (kc) => {
    const PRE_REALM =
      previousEnvironment === "development"
        ? "PaymentRouterDev"
        : previousEnvironment === "production"
        ? "PaymentRouterProd"
        : previousEnvironment === "test"
        ? "PaymentRouterTest"
        : "";
    console.log(previousEnvironment, PRE_REALM);
    fetch(`${KEYCLOAK_URL}realms/${REALM}/protocol/openid-connect/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
        subject_token: kc.token,
        subject_issuer: `${KEYCLOAK_URL}realms/${PRE_REALM}`,
        audience: CLIENT_ID,
        client_id: CLIENT_ID,
        scope: "openid",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Token exchange failed");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Token exchanged successfully:", data);

        // Update Keycloak token
        kc.token = data.access_token;
        return httpClient.setHeader("Authorization", "Bearer " + kc.token);
      })
      .then(() => {
        console.log(
          "Before Dispatch - isKeyCloakTokenExchanged:",
          isKeyCloakTokenExchanged
        );
        dispatch(setIsKeyCloakTokenExchanged(environment));
        console.log(
          "After Dispatch - isKeyCloakTokenExchanged:",
          isKeyCloakTokenExchanged
        );
      })
      .catch((error) => {
        console.error("Error during token exchange:", error);
      });
  };

  const [authenticated, setAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const isIdle = useIdle(10 * 60 * 1000);
  useEffect(() => {
    const keycloakInitParams = {
      url: KEYCLOAK_URL,
      realm: REALM,
      clientId: CLIENT_ID,
    };

    const kc = new Keycloak(keycloakInitParams);
    setKc(kc); // Store Keycloak instance in state
    console.log(kc, "kc");

    kc.onTokenExpired = () => {
      console.log("token expired");
      kc.updateToken(10)
        .then((refreshed) => {
          console.log("refreshed - " + refreshed);
          httpClient.setHeader("Authorization", "Bearer " + kc.token);
        })
        .catch(() => {
          console.error("Failed to refresh token");
          kc.logout();
          // reload the home or login screen
        });
    };

    kc.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    }).then((auth) => {
      KCClient.setKC(kc);

      if (!auth) {
        window.location.reload();
      } else {
        setAuthenticated(true);
        console.log("authenticated");
        const getToken = jwtDecode(kc.token);
        console.log(getToken.merchantId, "get");

        dispatch(setMerchantId(getToken.merchantId));
        dispatch(setMerchantName(getToken.preferred_username));
        console.log(kc);
        httpClient.setHeader("Authorization", "Bearer " + kc.token);
      }
    });
  }, []); // Run only once on component mount

  useEffect(() => {
    // If user is idle for more than 10 minutes, perform an action (e.g., logout)
    if (isIdle) {
      console.log("User has been idle for 10 minutes. Logging out...");
      Logout();
    }
  }, [isIdle]);

  if (!authenticated) {
    // Return a loading indicator or a login screen while waiting for authentication
    return (
      <div className="container-login">
        <img
          className="loading-indicator"
          src="logos/Loader.svg"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <Router>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/index.html" element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
