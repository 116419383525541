import { useEffect, useState } from "react";
import "./TestBanner.css";
import { useSelector, useDispatch } from "react-redux";

const TestBanner = () => {
  const environment = useSelector((state) => state.merchant.environment);

  return (
    <>
      {environment === "test" && (
        <div className="test-mode-banner">
          <span className="test-mode-label">Test mode</span>
          <span className="test-mode-message">You're using test data.</span>
        </div>
      )}
    </>
  );
};

export default TestBanner;
