import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItems } from "utils/MenuItems";
import "./Menu.css";
import Synergy from "components/Icons/Synergy";
import { useMenuFunctions } from "./MenuFunctionProvider";
import { t } from "i18next";

function Menu(props) {
  const items = MenuItems();
  const [toggleExpand, setToggleExpand] = useState(false);
  const { menuToggleExpand } = useMenuFunctions();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 567);
  useEffect(() => {
    const handleResize = () => {
      // setIsSmallScreen(window.innerWidth <= 768);
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <div
          onClick={() => {
            setToggleExpand(!toggleExpand);
            menuToggleExpand();
          }}
          className={` ${toggleExpand ? "expanded" : "contracted"}`}
        >
          {toggleExpand ? (
            <div className="aside-logo  py-8" id="kt_aside_logo">
              {<Synergy className={"hamburger-menu-icon"} />}
              {/* <span className="logo">Payorch</span> */}
            </div>
          ) : (
            <MenuIcon className={"hamburger-menu-icon m-3"} />
          )}

          <div className="aside pb-5 pt-5 pt-lg-0">
            <div className="aside-logo  py-8" id="kt_aside_logo">
              {<Synergy className={"hamburger-menu-icon"} />}
              {/* <span className="logo">Payorch</span> */}
            </div>

            <div className="menu-container-body">
              <ul>
                {items.map((items, key) => {
                  if (items.name !== t("LOGOUT")) {
                    return (
                      <li
                        onClick={() =>
                          props.onClick(items.component, items.name)
                        }
                        key={key}
                      >
                        {items.icon}
                        {/* {toggleExpand && items.name} */}
                        <span className="menu-title"> {items.name}</span>
                      </li>
                    );
                  } else {
                    return (
                      <footer
                        className={toggleExpand ? "expanded" : "contracted"}
                      >
                        <ul>
                          <li
                            className="menu-container-body"
                            onClick={() =>
                              props.onClick(items.component, items.name)
                            }
                            key={key}
                            // style={{
                            //   display: "flex",
                            //   justifyContent: "space-between",
                            //   width: toggleExpand ? "97%" : "61%",
                            // }}
                            // className={
                            //   toggleExpand ? "expanded-menu" : "contracted-menu"
                            // }
                          >
                            {items.icon}
                            <span className="menu-title"> {items.name}</span>
                          </li>
                        </ul>
                      </footer>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {toggleExpand ? (
            <div className="aside-logo  py-8" id="kt_aside_logo">
              {<Synergy className={"hamburger-menu-icon"} />}
              {/* <span className="logo">Payorch</span> */}
            </div>
          ) : (
            <span></span>
          )}

          <div className="aside pb-5 pt-5 pt-lg-0">
            <div className="aside-logo  py-8" id="kt_aside_logo">
              {<Synergy className={"hamburger-menu-icon"} />}
              {/* <span className="logo">Payorch</span> */}
            </div>

            <div className="menu-container-body">
              <ul>
                {items.map((items, key) => {
                  if (items.name !== t("LOGOUT")) {
                    return (
                      <li
                        onClick={() =>
                          props.onClick(items.component, items.name)
                        }
                        key={key}
                      >
                        {items.icon}
                        {/* {toggleExpand && items.name} */}
                        <span className="menu-title"> {items.name}</span>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
