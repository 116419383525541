import React, { useState, useEffect } from "react";
import {
  format,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  addMonths,
  isBefore,
  isToday,
  isSameDay,
  addDays,
  isAfter,
  subMonths,
} from "date-fns";
import "./datepicker.css";
import { getMonth, getYear } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";

const DateRangePicker = ({ calenderDateChange,pageType }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [currentMonthLeft, setCurrentMonthLeft] = useState(new Date());
  const [currentMonthRight, setCurrentMonthRight] = useState(
    addMonths(new Date(), 1)
  );
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(pageType==="Dashboard"?"Last 7 Days":""); // Track the currently selected preset
  const [lastPreset, setLastPreset] = useState(""); // Track the last valid preset before opening calendar

  const isSameMonth = (date1, date2) => {
    return (
      getYear(date1) === getYear(date2) && getMonth(date1) === getMonth(date2)
    );
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const { t } = useTranslation();

  // useEffect(() => {
  //   // Automatically apply "Last 7 Days" preset when the component mounts
  //   applyPreset("Last 7 Days");
  // }, []);

  const applyPreset = (preset) => {
    const today = new Date();
    setLastPreset(selectedPreset); // Store the current preset before changing it
    setSelectedPreset(preset); // Set the selected preset
    switch (preset) {
      case "Today":
        setStartDate(today);
        setEndDate(today);
        calenderDateChange({
          transactionTime_from: moment(setStartDate(today))
            .utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
          transactionTime_to: moment(setEndDate(today))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        });
        break;
      case "Yesterday":
        setStartDate(subDays(today, 1)); // Setting the start date to yesterday
        setEndDate(subDays(today, 1)); // Setting the end date to yesterday as well
        calenderDateChange({
          transactionTime_from: moment(subDays(today, 1))
            .utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
          transactionTime_to: moment(subDays(today, 1))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        });
        break;
      case "Last 7 Days":
        setStartDate(moment(subDays(today, 6))
        .utc()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"));
        setEndDate(moment(today)
        .utc()
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"));
        calenderDateChange({
          transactionTime_from: moment(subDays(today, 6))
            .utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
          transactionTime_to: moment(setEndDate(today))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        });
        break;
      case "Last 30 Days":
        setStartDate(subDays(today, 29));
        setEndDate(today);
        calenderDateChange({
          transactionTime_from: moment(subDays(today, 29))
            .utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
          transactionTime_to: moment(setEndDate(today))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        });
        break;
      case "This Month":
        setStartDate(startOfMonth(today));
        setEndDate(endOfMonth(today));
        calenderDateChange({
          transactionTime_from: moment(startOfMonth(today))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
          transactionTime_to:moment(setEndDate(today))
          .utc()
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        });
        break;
      case "Last Month":
        const lastMonth = subMonths(today, 1);
        setStartDate(startOfMonth(lastMonth));
        setEndDate(endOfMonth(lastMonth));
        calenderDateChange({
          transactionTime_from: moment(startOfMonth(lastMonth))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
          transactionTime_to: moment(endOfMonth(lastMonth))
            .utc()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        });
        break;
      case "Custom Range":
        setShowCalendar(true);
        setStartDate(null);
        setEndDate(null);
        setTempStartDate(null);
        setTempEndDate(null);
       const threeYearsAgo = moment().subtract(3, 'years');
       

      calenderDateChange({
        transactionTime_from: threeYearsAgo
           .utc()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
         transactionTime_to: moment(setEndDate(today))
        .utc()
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
      });
        return;
      default:
        break;
    }
    setShowDropdown(false);
  };

  const handleOk = () => {
    if (
      tempStartDate &&
      tempEndDate &&
      tempStartDate instanceof Date &&
      tempEndDate instanceof Date &&
      (new Date(tempEndDate).getTime()  > new Date(tempStartDate).getTime() || new Date(tempStartDate).getTime()  === new Date(tempEndDate).getTime()) 
    ) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setSelectedPreset("Custom Range");
      calenderDateChange({
        transactionTime_from: moment(tempStartDate).add(1, 'days')
          .utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
        transactionTime_to:moment(tempEndDate).add(1, 'days')
          .utc()
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
      });
    }
    setShowCalendar(false);
    setShowDropdown(false); // Close the dropdown after OK is clicked
  };

  const handleCancel = () => {
    // When cancel is clicked, revert to the last valid preset
  
    applyPreset("Last 7 Days");
    setSelectedPreset("Last 7 Days")

      // If the user was in "Custom Range", keep it as the current preset 
      setTempStartDate(null);
      setTempStartDate(null); 
      setStartDate(null);
      setEndDate(null);
        setShowCalendar(false);
    setShowDropdown(false);
    
    

  };

  const handleInputClick = () => {
    if (selectedPreset === "Custom Range") {
      setShowCalendar(true); // Show the calendar again if Custom Range is selected
    } else {
      setShowCalendar(false);
    }
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const handleDayClick = (date) => {
    if (!tempStartDate || (tempStartDate && tempEndDate)) {
      setTempStartDate(date);
      setTempEndDate(null);
    } else {
      if (isBefore(date, tempStartDate)) {
        setTempStartDate(date);
      } else {
        setTempEndDate(date);
      }
    }
  };

  const customDateClick = (date) => {
    if (showCalendar && !moment(date).isAfter(moment())) {
      if (!tempStartDate) {
        setTempStartDate(date);
        setTempEndDate(date);
      } else if (moment(date).isSame(tempStartDate)) {
        
        setTempStartDate(null);
        setTempEndDate(null);
      } else if (tempStartDate && !tempEndDate) {
        if (moment(date).isBefore(tempStartDate)) {
          setTempStartDate(date);
          setTempEndDate(null);
        } else {
          setTempEndDate(date);
        }
      } else if (tempStartDate && tempEndDate) {
        if (moment(date).isSame(tempEndDate)) {
          setTempEndDate(null);
        } else if (moment(date).isAfter(tempStartDate)) {
          setTempEndDate(date);
        } else {
          setTempStartDate(date);
          setTempEndDate(null);
        }
      }
    }
  };
  
  
  
  const handleDayHover = (date) => {
    if (tempStartDate && !tempEndDate && isAfter(date, tempStartDate)) {
      setHoveredDate(date);
    }
  };

  const handleMouseLeave = () => {
    setHoveredDate(null);
  };

  const formatRange = () => {
    if (
      tempStartDate &&
      tempEndDate &&
      tempStartDate instanceof Date &&
      tempEndDate instanceof Date
    ) {
      return `${format(tempStartDate, "yyyy-MM-dd")} - ${format(
        tempEndDate,
        "yyyy-MM-dd"
      )}`;
    }
    return "Select date range";
  };

  const handleDayClickConditionally = (day) => {
   
    if(selectedPreset === 'Custom Range'){
      customDateClick(day)
    }else{
    if (isBefore(day, new Date()) && !isToday(day)) {
      return null;
    }else{
    return handleDayClick(day);
    }
  }
  };
  const renderCalendar = (currentMonth) => {
    const startOfCurrentMonth = startOfMonth(currentMonth);
    const endOfCurrentMonth = endOfMonth(currentMonth);
    const startDateOfCalendar = startOfWeek(startOfCurrentMonth);
    
    const daysInMonth = [];
    for (
      let day = startDateOfCalendar;
      isBefore(day, endOfCurrentMonth);
      day = addDays(day, 1)
    ) {
      daysInMonth.push(day);
    }
    const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    return (
      <div className="calendar-grid">
        {/* Render week names */}
        {weekDays.map((day) => (
          <div key={day} className="week-day">
            {" "}
            {day}{" "}
          </div>
        ))}
        {daysInMonth.map((day) => (
          <div
            key={day.toString()}
            className={`calendar-day
                        ${isSameDay(day, tempStartDate) ? "selected start" : ""}
                        ${isSameDay(day, tempEndDate) ? "selected end" : ""}
                        ${
                          isBefore(day, new Date()) 
                            ? ""
                            : "disabled"
                        }
                        ${
                          isBefore(day,startOfCurrentMonth) ? 'invisible':''
                        }
                        ${
                          tempStartDate &&
                          tempEndDate &&
                          isBefore(day, tempEndDate) &&
                          isAfter(day, tempStartDate)
                            ? "range-selected"
                            : ""
                        }
                        ${
                          tempStartDate &&
                          !tempEndDate &&
                        
                          isBefore(day, tempEndDate)
                            ? "range-hover"
                            : ""
                        }`}
            onClick={() =>
              handleDayClickConditionally(day)
            }
            onMouseEnter={() => handleDayHover(day)}
            onMouseLeave={handleMouseLeave}
          >
            {format(day, "d")}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={
          selectedPreset && selectedPreset !== "Custom Range"
            ? selectedPreset
            : startDate && endDate
            ? `${format(startDate, "yyyy-MM-dd")} - ${format(
                endDate,
                "yyyy-MM-dd"
              )}`
            : ""
        }
        
        placeholder={t("SELECT_DATE_RANGE")}
        readOnly
        onClick={handleInputClick}
        className="form-control padding"
      />
      {showDropdown && (
        <div className="daterangepicker">
          {/* Options Section */}
          <div className="ranges">
            <ul>
              {[
                "Today",
                "Yesterday",
                "Last 7 Days",
                "Last 30 Days",
                "This Month",
                "Last Month",
                "Custom Range",
              ].map((option) => (
                <li
                  key={option}
                  className={selectedPreset === option ? "active" : ""}
                  onClick={() => applyPreset(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>

          {/* Calendar Section */}
          <div>
            {showCalendar && (
              <div className="calendar-section">
                <div
                  className="calendar"
                  style={{ flex: 1, marginRight: "20px" }}
                >
                  {/* From Calendar */}
                  <div className="calendar-header">
                    <button
                      className="prev-btn"
                      onClick={() => {
                        const updatedMonth = subMonths(currentMonthLeft, 1);
                        const updatedRightMonth = subMonths(currentMonthRight,1);
                        setCurrentMonthLeft(updatedMonth);
                        setCurrentMonthRight(updatedRightMonth);
                      }}
                    >
                      <span className="prev"></span>
                    </button>
                    <div className="d-inline-block w-75 text-center">{format(currentMonthLeft, "MMM yyyy")}</div>
                    
                  </div>
                  {renderCalendar(currentMonthLeft)}
                </div>

                <div className="calendar" style={{ flex: 1 }}>
                  {/* To Calendar */}
                  <div className="calendar-header col-12">
                  <div className="d-inline-block col-10 text-center">{format(currentMonthRight, "MMM yyyy")}</div>
                    <button
                      className="next-btn col-2"
                      onClick={() => {
                        const updatedMonth = addMonths(currentMonthRight, 1);
                        const updatedLeftMonth = addMonths(currentMonthLeft,1);
                        setCurrentMonthRight(updatedMonth);
                        setCurrentMonthLeft(updatedLeftMonth);
                      }}
                    >
                      <span className="next"></span>
                    </button>
                  </div>
                  {renderCalendar(currentMonthRight)}
                </div>
              </div>
            )}

            {/* Date range near OK/Cancel Buttons */}
            {showCalendar && (
              <div className="drp-buttons">
                <span className="drp-selected">
                  {tempStartDate && tempEndDate &&(new Date(tempEndDate).getTime()  > new Date(tempStartDate).getTime() || new Date(tempStartDate).getTime()  === new Date(tempEndDate).getTime())
                    ? `${format(tempStartDate, "yyyy-MM-dd")} - ${format(
                        tempEndDate,
                        "yyyy-MM-dd"
                      )}`
                    : t("SELECT_START_END_DATES")}
                </span>
                <span>
                  <div className="daterangepicker-buttons-container">
                    <button
                      onClick={handleCancel}
                      className="cancelBtn btn btn-sm btn-light-primary m-2"
                    >
                      {t("CANCEL")}
                    </button>
                    <button
                      onClick={handleOk}
                      className="applyBtn btn btn-sm btn-primary m-2"
                      disabled = {!(new Date(tempEndDate).getTime()  > new Date(tempStartDate).getTime() || new Date(tempStartDate).getTime()  === new Date(tempEndDate).getTime())}
                    >
                      {t("APPLY")}
                    </button>
                  </div>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
