const transactionsMap = {
  merchantRefNumber: {
    displayName: "Merchant Reference Id",
    path: "merchantReferenceId",
    order: 1,
    filtered: true,
    filterOptions: {
      order: 2,
      type: "STRING",
      queryParam: "merchantReferenceId",
      selectedOptions: [],
    },
  },
  processorReconId: {
    displayName: "Processor Recon Id",
    path: "processorReconId",
    order: 10,
    filtered: true,
    filterOptions: {
      order: 10,
      type: "STRING",
      queryParam: "processorReconId",
    },
  },
  processorId: {
    displayName: "Processor Id",
    path: "processorTransactionId",
    order: 11,
    filtered: true,
    filterOptions: {
      order: 12,
      type: "STRING",
      queryParam: "processorTransactionId",
    },
  },

  id: {
    displayName: "Id",
    path: "id",
    order: 2,
    filtered: false,
    filterOptions: {
      order: 3,
      type: "STRING",
      queryParam: "id",
      selectedOptions: [],
    },
  },
  status: {
    displayName: "Status",
    path: "status",
    order: 3,
    filtered: true,
    filterOptions: {
      order: 3,
      type: "ENUM",
      options: [
        "CREATED",
        "COMPLETED",
        "PROCESSING",
        "PENDING",
        "FAILED",
        "ERROR",
        "EXPIRED",
        "INITIATED",
        "HELD",
      ],
      queryParam: "status",
      selectedOptions: [],
    },
  },
  paymentType: {
    displayName: "Payment Type",
    path: "paymentMethod",
    order: 4,
    filtered: true,
    filterOptions: {
      order: 3,
      type: "ENUM",
      options: [
        "BANK_TRANSFER",
        "CARDS",
        "QRIS",
        "OVO",
        "GO_PAY",
        "DANA",
        "DOKU",
        "ALFAMART",
        "INDOMARET",
        "MANDIRI_CLICKPAY",
        "ALIPAY",
        "SHOPEEPAY",
        "SAKUKU",
        "NEXCASH",
        "AKULAKU",
        "KREDIVO",
        "LINKAJA",
        "ASTRAPAY",
        "JENIUSPAY",
        "UANGME",
        "INDODANA",
        "ATOME",
        "STCPAY",
        "GOOGLE_PAY",
      ],
      queryParam: "paymentMethod",
      selectedOptions: [],
    },
  },
  processorCode: {
    displayName: "Processor Code",
    path: "processorCode",
    order: 5,
    filtered: true,
    filterOptions: {
      order: 3,
      type: "ENUM",
      options: [
        "XENDIT",
        "MIDTRANS",
        "IPAYMU",
        "DOKU",
        "ADYEN",
        "STRIPE",
        "CHECKOUT",
        "MYFATOORAH",
        "TAP",
        "MOYASAR",
        "HYPERPAY",
        "STCPAY",
        "PAYTABS",
        "CYBERSOURCE",
        "PAYSCAPE",
        "PAYKINGS",
        "PAYLINE",
        "CXPAY",
        "SKY_BANK",
        "T1PAYMENTS",
        "DURANGO",
        "FORTIS",
        "NP_BANK",
        "DHARMA",
        "VIKING",
        "NWIN_BANK",
        "NBOK_BANK_MPGS",
        "SUNCORP_BANK",
        "BSFR_BANK",
        "DOHA_BANK",
        "ANZB_BANK",
        "BOA_BANK",
        "UNION_BANK",
        "KCB_BANK",
        "ADCB_BANK",
        "AFS_BANK",
        "AREEBA_BANK",
        "AXIS_BANK",
        "ALFALAH_BANK",
        "AUDI_BANK",
        "ADIB_BANK",
        "BABE_BANK",
        "BLFS_BANK",
        "BEND_BANK",
        "BRGN_BANK",
        "CCEY_BANK",
        "CREDIMAX",
        "HSBC_BANK",
        "KFHO_BANK",
        "GLOBALPMT",
        "MSHQ_BANK",
        "MCBL_BANK",
        "MCB_EGATE",
        "NBEG_BANK",
        "NBOM_BANK",
        "NOON_PAYMENTS",
        "OPAY",
        "AUTHORIZE_NET",
        "MONNIFY",
        "TwoC2P"
      ],
      queryParam: "processorCode",
      selectedOptions: [],
    },
  },
  amount: {
    displayName: "Amount",
    path: "amount.value",
    order: 4,
    filtered: true,
    filterOptions: {
      order: 1,
      type: "INTEGER",
      queryParamInbound: "amount_to",
      queryParamOutbound: "amount_from",
      selectedOptions: [],
    },
  },

  currencyCode: {
    displayName: "Currency Code",
    path: "amount.currencyCode",
    order: 4,
    filtered: true,
    filterOptions: {
      order: 4,
      type: "ENUM",
      options: [
        "AED",
        "ALL",
        "AMD",
        "ANG",
        "AOA",
        "ARS",
        "AUD",
        "AWG",
        "AZN",
        "BAM",
        "BBD",
        "BDT",
        "BGN",
        "BHD",
        "BIF",
        "BMD",
        "BND",
        "BOB",
        "BRL",
        "BSD",
        "BTN",
        "BWP",
        "BYN",
        "BZD",
        "CAD",
        "CDF",
        "CHF",
        "CLP",
        "CNY",
        "COP",
        "CRC",
        "CUP",
        "CZK",
        "DKK",
        "DJF",
        "DOP",
        "DZD",
        "EGP",
        "ERN",
        "ETB",
        "EUR",
        "FJD",
        "FKP",
        "GBP",
        "GEL",
        "GHS",
        "GIP",
        "GMD",
        "GNF",
        "GTQ",
        "GYD",
        "HKD",
        "HNL",
        "HRK",
        "HTG",
        "HUF",
        "IDR",
        "ILS",
        "INR",
        "IQD",
        "IRR",
        "ISK",
        "JMD",
        "JOD",
        "JPY",
        "KES",
        "KGS",
        "KHR",
        "KMF",
        "KPW",
        "KRW",
        "KWD",
        "KYD",
        "KZT",
        "LAK",
        "LBP",
        "LKR",
        "LRD",
        "LSL",
        "LYD",
        "MAD",
        "MDL",
        "MGA",
        "MKD",
        "MMK",
        "MNT",
        "MOP",
        "MRU",
        "MUR",
        "MVR",
        "MWK",
        "MXN",
        "MYR",
        "MZN",
        "NAD",
        "NGN",
        "NIO",
        "NOK",
        "NPR",
        "NZD",
        "OMR",
        "PAB",
        "PEN",
        "PGK",
        "PHP",
        "PKR",
        "PLN",
        "PYG",
        "QAR",
        "RON",
        "RSD",
        "RUB",
        "RWF",
        "SAR",
        "SBD",
        "SCR",
        "SDG",
        "SEK",
        "SGD",
        "SHP",
        "SLL",
        "SOS",
        "SSP",
        "STN",
        "SVC",
        "SYP",
        "SZL",
        "THB",
        "TJS",
        "TMT",
        "TND",
        "TOP",
        "TRY",
        "TWD",
        "TZS",
        "UAH",
        "UGX",
        "USD",
        "UYU",
        "UZS",
        "VES",
        "VND",
        "VUV",
        "WST",
        "XAF",
        "XCD",
        "XOF",
        "XPF",
        "YER",
        "ZAR",
        "ZMW",
        "ZWL",
      ],
      //["IDR", "USD", "SAR", "AED", "ARS"],
      queryParam: "currency",
      selectedOptions: [],
    },
  },
  date: {
    displayName: "Date",
    path: "transactionTime,createdAt",
    order: 9,
    filtered: true,
    filterOptions: {
      order: 8,
      type: "DATE",
      queryParamInbound: "transactionTime_to",
      queryParamOutbound: "transactionTime_from",
      selectedOptions: [],
    },
  },

  transactionType: {
    displayName: "Transaction Type",
    path: "transactionType",
    order: 1,
    filtered: true,
    filterOptions: {
      order: 1,
      type: "ENUM",
      queryParam: "transactionType",
      options: ["PAYMENT", "CAPTURE", "REFUND", "CREDIT", "CHECKOUT_SESSION"],
    },
  },
};
const ListOfShowingColumns = [
  "merchantRefNumber",
  "id",
  "transactionType",
  "status",
  "paymentType",
  "processorCode",
  "amount",
  "currencyCode",
  "date",
  "processorReconId",
  "processorId",
];
ListOfShowingColumns.sort(
  (a, b) => transactionsMap[a].order - transactionsMap[b].order
);
function getColumnValue(item, path) {
  const paths = path.split(".");
  let value = item;
  for (const path of paths) {
    value = value[path];
    if (value === undefined) return null; // return empty string if any intermediate path is undefined
  }
  return value;
}
export default { transactionsMap, ListOfShowingColumns, getColumnValue };
