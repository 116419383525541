// Declare environment-dependent variables
let URL = "";
let CHECKOUT_URL = "";
let CHECKOUT_API_KEY = "";
let REALM = "";
let KEYCLOAK_URL = "";


// export const isProd = process.env.REACT_APP_NODE_ENV === "production";
// export const isDev =
//   (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) === "development";
// export const isTest = process.env.REACT_APP_NODE_ENV === "test";

// Setter function to update environment values
export const setChangeInEnvironment = (env) => {
  const isDev = env === "development";
  const isTest = env === "test";
  const isProd = env === "production";
  URL = isDev
  ? // ? "https://ec2-13-239-115-132.ap-southeast-2.compute.amazonaws.com:8081/portalbe/api/v1/"
    // ? "https://ec2-13-239-115-132.ap-southeast-2.compute.amazonaws.com:8081/portalbe/api/v1/"
    // ? "https://localhost:8081/portalbe/api/v1/"
    "https://dashboard-api.pr-dev.payorch.com/portalbe/api/v1/"
  : isTest
  ? "https://dashboard-api.pr-test.payorch.com/portalbe/api/v1/"
  : isProd
  ? "https://dashboard-api.pr-prod.payorch.com/portalbe/api/v1/"
  : "";

KEYCLOAK_URL = isProd
  ? "https://keycloak.pr-prod.payorch.com/"
  : "https://keycloak.paymentrouter.payorch.com/";

REALM = isDev
  ? "PaymentRouterDev"
  : isProd
  ? "PaymentRouterProd"
  : isTest
  ? "PaymentRouterTest"
  : "";

CHECKOUT_URL = isDev
  ? "https://checkout.pr-dev.payorch.com/checkout.js"
  : isProd
  ? "https://checkout.pr-prod.payorch.com/checkout.js"
  : isTest
  ? "https://checkout.pr-test.payorch.com/checkout.js"
  : "";

CHECKOUT_API_KEY = isDev ? "bWVyY2hhbnRkZW1vMTpUZXN0QDEyMw==" : "bWVyY2hhbnRkZW1vMjE6VGVzdEAxMjM=";
};

// Export all variables (they'll get updated dynamically)
export { URL, CHECKOUT_URL, CHECKOUT_API_KEY, REALM, KEYCLOAK_URL };

export const CLIENT_ID = "PaymentRouterPortalClient";

export const PROCESSOR_ENDPOINT = "processorsmetadata";
export const PROCESSORLIST_ENDPOINT = "merchantprocessorslist";
export const MERCHANT_CONFIG_ENDPOINT = "merchantconfigs";
export const UPDATE_PROCESSOR_ENDPOINT = "processoraccounts";
export const PAYMENT_TRANSACTIONS_ENDPOINT = "search";
export const RULES_ENDPOINT = "rules";
export const API_ENDPOINT = "apikeys";
export const API_REGENERATE_ENDPOINT = "apikeys/generate";
export const WEBHOOK_SETTINGS_ENDPOINT = "webhooksettings";
export const CHECKOUT_SETTINGS_ENDPOINT = "checkoutsettings";
export const TRANSACTION_DETAILS_ENDPOINT = "fulltransactiondetails";
export const GOOGLEPAY_SETTINGS_ENDPOINT = "googlepaysettings";
export const LOGOUT = "logout";
export const ANALYTICS_ENDPOINT = "analytics";

//Keys
export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_INACTIVE = "INACTIVE";


