import {
  URL,
  PROCESSORLIST_ENDPOINT,
  MERCHANT_CONFIG_ENDPOINT,
  PROCESSOR_ENDPOINT,
} from "constants/Constants";
import { httpClient } from "utils/HttpClient";
import { useSelector } from "react-redux";
import React, { createContext, useContext, useState, useEffect } from "react";
const ProcessorServiceContext = createContext();

export const ProcessorServiceProvider = ({ children }) => {
  const isKeyCloakTokenExchanged = useSelector(
    (state) => state.merchant.isKeyCloakTokenExchanged
  );
  const [processorData, setProcessorData] = useState();
  const [processorMetaData, setProcessorMetaData] = useState();
  const [configData, setCongigData] = useState([]);
  const [isEditingData, setIsEditingData] = useState(false);

  // console.log(configData,"getConfigData")

  useEffect(() => {
    httpClient
      .fetchData(`${URL}${PROCESSORLIST_ENDPOINT}`, "GET")
      .then((data) => {
        // console.log(data, "processorData- test");
        setProcessorData(data);
      });
  }, [isKeyCloakTokenExchanged]);

  useEffect(() => {
    httpClient
      .fetchData(`${URL}${MERCHANT_CONFIG_ENDPOINT}`, "GET")
      .then((data) => {
        setCongigData(data);
      });
  }, [isKeyCloakTokenExchanged]);

  useEffect(() => {
    httpClient.fetchData(`${URL}${PROCESSOR_ENDPOINT}`, "GET").then((data) => {
      // console.log(data, "processorData- test");
      setProcessorMetaData(data);
    });
  }, [isKeyCloakTokenExchanged]);
  return (
    <ProcessorServiceContext.Provider
      value={{ processorData, configData, processorMetaData, setIsEditingData }}
    >
      {children}
    </ProcessorServiceContext.Provider>
  );
};

export const useProcessorData = () => useContext(ProcessorServiceContext);
