import React, { useState } from "react";
import Menu from "components/Menu/Menu";
import Settings from "components/Settings/Settings";
import "./Home.css";
import Dashboard from "components/Dashboard/Dashboard";
import {
  MenuFunctionsProvider,
  useMenuFunctions,
} from "components/Menu/MenuFunctionProvider";
import { useService } from "constants/ServiceProvider";

function Home() {
  const [activeComponent, setActiveComponent] = useState(<Settings />);

  const [componentName, setComponentName] = useState("Settings");
  const [menuClicked, setMenuClicked] = useState(false);

  // const { toggleExpand, menuToggleExpand } = useMenuFunctions();

  const handleComponentOpen = (key, componentName) => {
    if (key && componentName) {
      setActiveComponent(key);
      setComponentName(componentName);
      setMenuClicked(true);
    }
  };

  return (
    <MenuFunctionsProvider>
      {/* <Menu onClick={handleComponentOpen} /> */}
      <div className="action-menu-container">
        <Menu onClick={handleComponentOpen} />
      </div>
      <div className="wrapper d-flex flex-column flex-row-fluid">
        {/* <div id="kt_header" className="header align-items-stretch">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div className="d-flex align-items-center d-lg-none ms-n1 me-2" title="Show aside menu">
    <div className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" 
    id="kt_aside_mobile_toggle">
        <i className="ki-duotone ki-abstract-14 fs-1"><span className="path1"></span>
        <span className="path2"></span></i>
    </div>
</div>
<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
    <a className="d-lg-none" href=""><img alt="Logo" src="" className="h-30px" /></a>
</div>
<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
<div className="d-flex align-items-stretch" id="kt_header_nav">
Header menu
</div>
<div className="d-flex align-items-stretch flex-shrink-0" >
  <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
    <div className="d-flex align-items-stretch">
      <div className="d-flex align-items-center">
        <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
        <span className="ki-duotone ki-magnifier"><span className="path1"></span><span className="path2"></span></span>
        </div>
      </div>
    </div>
    <div className="d-flex align-items-stretch">
  <div className="cursor-pointer symbol symbol-30px symbol-md-40px show menu-dropdown">
    <img src="../../../public/user.jpg" alt="user" ></img>    
</div>
</div>
  </div>
</div>
</div>

            </div>
          </div> */}
        {menuClicked ? (
          <>
            {/* <div className="component-container-header">
            <p>{componentName}</p>
          </div> */}

            <div
              id="kt_content"
              className={`${
                componentName === "Rules" ? "rules-page" : ""
              } content d-flex flex-column flex-column-fluid`}
            >
              {activeComponent}
            </div>
          </>
        ) : (
          <Dashboard />
        )}
      </div>
    </MenuFunctionsProvider>
  );
}

export default Home;
