const processorCountry = {
  Adyen: [
    "AT",
    "AU",
    "BE",
    "BG",
    "BR",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GI",
    "GR",
    "HK",
    "HU",
    "IE",
    "IS",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MT",
    "MX",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SE",
    "SG",
    "SK",
    "SI",
    "US",
  ],
  Airwallex: [
    "AT",
    "AU",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "GR",
    "ES",
    "FI",
    "FR",
    "GB",
    "HK",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SG",
    "SI",
    "SK",
  ],
  Alelo: ["BR"],
  "Authorize.Net": ["AU", "CA", "US"],
  "Bambora Asia-Pacific": ["AU", "NZ"],
  "Banco Sabadell": ["ES", "FR", "GB", "IT", "PL", "PT"],
  "Barclaycard Smartpay": [
    "AL",
    "AD",
    "AM",
    "AT",
    "AZ",
    "BY",
    "BE",
    "BA",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IS",
    "IE",
    "IT",
    "KZ",
    "LV",
    "LI",
    "LT",
    "LU",
    "MK",
    "MT",
    "MD",
    "MC",
    "ME",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "RS",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TR",
    "UA",
    "GB",
    "VA",
  ],
  "Barclays ePDQ Extra Plus": ["GB"],
  "Bambora North America (formerly Beanstream)": ["CA", "US"],
  BluePay: ["US", "CA"],
  BlueSnap: [
    "US",
    "CA",
    "GB",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "AR",
    "BO",
    "BR",
    "BZ",
    "CL",
    "CO",
    "CR",
    "DO",
    "EC",
    "GF",
    "GP",
    "GT",
    "HN",
    "HT",
    "MF",
    "MQ",
    "MX",
    "NI",
    "PA",
    "PE",
    "PR",
    "PY",
    "SV",
    "UY",
    "VE",
  ],
  Borgun: ["IS", "GB", "HU", "CZ", "DE", "DK", "SE"],
  BPoint: ["AU"],
  Braintree: [
    "US",
    "CA",
    "AD",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "GI",
    "DE",
    "GR",
    "GG",
    "HU",
    "IS",
    "IM",
    "IE",
    "IT",
    "JE",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "MC",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SM",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TR",
    "GB",
    "SG",
    "HK",
    "MY",
    "AU",
    "NZ",
  ],
  BridgePay: ["CA", "US"],
  CardConnect: ["US"],
  Cardstream: [
    "GB",
    "US",
    "CH",
    "SE",
    "SG",
    "NO",
    "JP",
    "IS",
    "HK",
    "NL",
    "CZ",
    "CA",
    "AU",
  ],
  "VR Payment (formerly CardProcess)": [
    "BE",
    "BG",
    "CZ",
    "DK",
    "DE",
    "EE",
    "IE",
    "ES",
    "FR",
    "HR",
    "IT",
    "CY",
    "LV",
    "LT",
    "LU",
    "MT",
    "HU",
    "NL",
    "AT",
    "PL",
    "PT",
    "RO",
    "SI",
    "SK",
    "FI",
    "SE",
    "GB",
    "IS",
    "LI",
    "NO",
    "CH",
    "ME",
    "MK",
    "AL",
    "RS",
    "TR",
    "BA",
  ],
  CASHNet: ["US"],
  Cayan: ["US"],
  Cecabank: ["ES"],
  CenPOS: [
    "AD",
    "AI",
    "AG",
    "AR",
    "AU",
    "AT",
    "BS",
    "BB",
    "BE",
    "BZ",
    "BM",
    "BR",
    "BN",
    "BG",
    "CA",
    "HR",
    "CY",
    "CZ",
    "DK",
    "DM",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "GD",
    "GY",
    "HK",
    "HU",
    "IS",
    "IL",
    "IT",
    "JP",
    "LV",
    "LI",
    "LT",
    "LU",
    "MY",
    "MT",
    "MX",
    "MC",
    "MS",
    "NL",
    "PA",
    "PL",
    "PT",
    "KN",
    "LC",
    "MF",
    "VC",
    "SM",
    "SG",
    "SK",
    "SI",
    "ZA",
    "ES",
    "SR",
    "SE",
    "CH",
    "TR",
    "GB",
    "US",
    "UY",
  ],
  "Checkout.com": [
    "AD",
    "AE",
    "AR",
    "AT",
    "AU",
    "BE",
    "BG",
    "BH",
    "BR",
    "CH",
    "CL",
    "CN",
    "CO",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "EG",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HK",
    "HR",
    "HU",
    "IE",
    "IS",
    "IT",
    "JO",
    "JP",
    "KW",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MT",
    "MX",
    "MY",
    "NL",
    "NO",
    "NZ",
    "OM",
    "PE",
    "PL",
    "PT",
    "QA",
    "RO",
    "SA",
    "SE",
    "SG",
    "SI",
    "SK",
    "SM",
    "TR",
    "US",
  ],
  Clearhaus: [
    "DK",
    "NO",
    "SE",
    "FI",
    "DE",
    "CH",
    "NL",
    "AD",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "FO",
    "GL",
    "EE",
    "FR",
    "GR",
    "HU",
    "IS",
    "IE",
    "IT",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "GB",
  ],
  CommerceHub: ["US"],
  Conekta: ["MX"],
  Creditcall: ["US"],
  Credorax: [
    "AD",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FR",
    "DE",
    "GI",
    "GR",
    "GG",
    "HU",
    "IS",
    "IE",
    "IM",
    "IT",
    "JE",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "MC",
    "NO",
    "PL",
    "PT",
    "RO",
    "SM",
    "SK",
    "ES",
    "SE",
    "CH",
    "GB",
  ],
  "PayFacto (formerly CT-Payment)": ["US", "CA"],
  CyberSource: [
    "US",
    "AE",
    "BR",
    "CA",
    "CN",
    "DK",
    "FI",
    "FR",
    "DE",
    "IN",
    "JP",
    "MX",
    "NO",
    "SE",
    "GB",
    "SG",
    "LB",
    "PK",
  ],
  "CyberSource REST": [
    "US",
    "AE",
    "BR",
    "CA",
    "CN",
    "DK",
    "FI",
    "FR",
    "DE",
    "IN",
    "JP",
    "MX",
    "NO",
    "SE",
    "GB",
    "SG",
    "LB",
    "PK",
  ],
  dLocal: [
    "AR",
    "BD",
    "BO",
    "BR",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "DO",
    "EC",
    "EG",
    "GH",
    "GT",
    "IN",
    "ID",
    "JP",
    "KE",
    "MY",
    "MX",
    "MA",
    "NG",
    "PA",
    "PY",
    "PE",
    "PH",
    "SN",
    "SV",
    "TH",
    "TR",
    "TZ",
    "UG",
    "UY",
    "VN",
    "ZA",
  ],
  "MasterCard Payment Gateway Service (formerly DataCash)": ["GB"],
  Datatrans: ["CH", "GR", "US"],
  Decidir: ["AR"],
  "Decidir Plus": ["AR"],
  "Diamond Mind": ["US", "CA"],
  DIBS: ["US", "FI", "NO", "SE", "GB"],
  EBANX: [
    "BR",
    "MX",
    "CO",
    "CL",
    "AR",
    "PE",
    "BO",
    "EC",
    "CR",
    "DO",
    "GT",
    "PA",
    "PY",
    "UY",
  ],
  Elavon: ["US", "CA", "PR", "DE", "IE", "NO", "PL", "LU", "BE", "NL", "MX"],
  "Vantiv Express (formerly Element)": ["US"],
  ePay: ["DK", "SE", "NO"],
  eWAY: ["AU"],
  "eWAY Rapid": ["AU", "NZ", "GB", "SG", "MY", "HK"],
  "Fat Zebra": ["AU"],
  "Payeezy (formerly GGE4) Legacy": ["CA", "US"],
  "Payeezy (formerly GGE4)": ["CA", "US"],
  "First Pay": ["US"],
  FlexCharge: ["US"],
  Forte: ["US"],
  Fortis: ["US", "CA"],
  "First Data Global Gateway": ["US"],
  "Worldline (formerly GlobalCollect)": [
    "AD",
    "AE",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IS",
    "IT",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PL",
    "PN",
    "PS",
    "PT",
    "PW",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SR",
    "ST",
    "SV",
    "SZ",
    "TC",
    "TD",
    "TG",
    "TH",
    "TJ",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "WF",
    "WS",
    "ZA",
    "ZM",
    "ZW",
  ],
  "Global Iris": ["IE", "GB", "FR", "BE", "NL", "LU", "IT", "US", "CA", "ES"],
  "Global Transport": ["CA", "PR", "US"],
  HiPay: ["FR"],
  "Heartland Payment Systems": ["US"],
  "iATS Payments": [
    "AU",
    "BR",
    "CA",
    "CH",
    "DE",
    "DK",
    "ES",
    "FI",
    "FR",
    "GR",
    "HK",
    "IE",
    "IT",
    "NL",
    "NO",
    "PT",
    "SE",
    "SG",
    "TR",
    "GB",
    "US",
    "TH",
    "ID",
    "PH",
    "BE",
  ],
  IPG: ["AR"],
  PayVector: ["GB", "ES"],
  iVeri: ["US", "ZA", "GB"],
  IXOPAY: [
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PS",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "UM",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW",
  ],
  JetPay: ["US", "CA"],
  "JetPay V2": ["US", "CA"],
  Kushki: ["BR", "CL", "CO", "EC", "MX", "PE"],
  Latitude19: ["US", "CA"],
  "Vantiv eCommerce (formerly Litle)": ["US"],
  "maxiPago!": ["BR"],
  MercadoPago: ["AR", "BR", "CL", "CO", "MX", "PE", "UY"],
  "Merchant e-Solutions": ["US"],
  "Merchant Partners": ["US"],
  "Merchant Warrior": ["AU"],
  Mercury: ["US", "CA"],
  "MasterCard Payment Gateway Service (formerly MiGS)": [
    "AU",
    "AE",
    "BD",
    "BN",
    "EG",
    "HK",
    "ID",
    "JO",
    "KW",
    "LB",
    "LK",
    "MU",
    "MV",
    "MY",
    "NZ",
    "OM",
    "PH",
    "QA",
    "SA",
    "SG",
    "TT",
    "VN",
  ],
  Milstar: ["US"],
  MIT: ["MX"],
  Moka: ["GB", "TR", "US"],
  MONEI: [
    "AD",
    "AT",
    "BE",
    "BG",
    "CA",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GB",
    "GI",
    "GR",
    "HU",
    "IE",
    "IL",
    "IS",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
    "TR",
    "US",
    "VA",
  ],
  Moneris: ["CA"],
  Mundipagg: ["US"],
  "NAB Transact": ["AU"],
  "NCR Secure Pay": ["US"],
  NetPay: ["MX"],
  NETbilling: ["US"],
  NMI: ["US", "CA"],
  Nuvei: ["US", "CA", "IN", "NZ", "GB", "AU", "US"],
  "Worldline (formerly Ogone)": ["BE", "DE", "FR", "NL", "AT", "CH"],
  Openpay: ["CO", "MX"],
  "Paysafe (formerly Optimal Payments)": [
    "CA",
    "US",
    "GB",
    "AU",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
  ],
  "Orbital (Chase Paymentech)": ["US", "CA"],
  PAYARC: ["US"],
  PayConex: ["US", "CA"],
  PayJunction: ["US"],
  PayTrace: ["US"],
  Payeezy: ["US", "CA"],
  PayEx: ["DK", "FI", "NO", "SE"],
  "Payflow Pro": ["US", "CA", "NZ", "AU"],
  "Windcave (formerly Payment Express)": [
    "AU",
    "FJ",
    "GB",
    "HK",
    "IE",
    "MY",
    "NZ",
    "PG",
    "SG",
    "US",
  ],
  Paymentez: ["MX", "EC", "CO", "BR", "CL", "PE"],
  Paymill: [
    "AD",
    "AT",
    "BE",
    "BG",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GB",
    "GI",
    "GR",
    "HR",
    "HU",
    "IE",
    "IL",
    "IM",
    "IS",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
    "TR",
    "VA",
  ],
  PayPal: ["CA", "NZ", "GB", "US"],
  "PayPal Commerce Platform": [
    "AU",
    "AT",
    "BE",
    "BG",
    "CA",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "GR",
    "HU",
    "IT",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "US",
    "GB",
  ],
  Paysafe: [
    "AL",
    "AT",
    "BE",
    "BA",
    "BG",
    "CA",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IS",
    "IE",
    "IT",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "ME",
    "NL",
    "MK",
    "NO",
    "PL",
    "PT",
    "RO",
    "RS",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TR",
    "GB",
    "US",
  ],
  Paystation: ["NZ"],
  "PayU Latam": ["AR", "BR", "CL", "CO", "MX", "PA", "PE"],
  Payway: ["US", "CA"],
  "Pin Payments": ["AU", "NZ"],
  Plexo: ["UY"],
  PPRO: [
    "AT",
    "BE",
    "BR",
    "CY",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PT",
    "SK",
    "SI",
    "ES",
    "BG",
    "HR",
    "CZ",
    "DK",
    "HU",
    "PL",
    "RO",
    "SE",
    "GB",
    "IS",
    "LI",
    "MC",
    "NO",
    "SM",
    "CH",
    "MX",
    "AD",
    "VA",
    "PF",
    "TF",
    "GI",
    "GG",
    "IM",
    "JE",
    "BL",
    "PM",
  ],
  Priority: ["US"],
  ProPay: ["US", "CA"],
  PSiGate: ["CA"],
  Quantum: ["US"],
  Quickbooks: ["US"],
  "QuickPay V10": ["DE", "DK", "ES", "FI", "FR", "FO", "GB", "IS", "NO", "SE"],
  Qvalent: ["AU"],
  Rapyd: [
    "CA",
    "CL",
    "CO",
    "DO",
    "SV",
    "PE",
    "PT",
    "VI",
    "AU",
    "HK",
    "IN",
    "ID",
    "JP",
    "MY",
    "NZ",
    "PH",
    "SG",
    "KR",
    "TW",
    "TH",
    "VN",
    "AD",
    "AT",
    "BE",
    "BA",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "GE",
    "DE",
    "GI",
    "GR",
    "GL",
    "HU",
    "IS",
    "IE",
    "IL",
    "IT",
    "LV",
    "LI",
    "LT",
    "LU",
    "MK",
    "MT",
    "MD",
    "MC",
    "ME",
    "NL",
    "GB",
    "NO",
    "PL",
    "RO",
    "RU",
    "SM",
    "SK",
    "SI",
    "ZA",
    "ES",
    "SE",
    "CH",
    "TR",
    "VA",
  ],
  Reach: [
    "AE",
    "AG",
    "AL",
    "AM",
    "AT",
    "AU",
    "AW",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BJ",
    "BM",
    "BN",
    "BO",
    "BR",
    "BS",
    "BW",
    "BZ",
    "CA",
    "CD",
    "CF",
    "CH",
    "CI",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EE",
    "EG",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GG",
    "GH",
    "GI",
    "GN",
    "GR",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HN",
    "HR",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KM",
    "KN",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LC",
    "LK",
    "LR",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MD",
    "MK",
    "ML",
    "MN",
    "MO",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PT",
    "PY",
    "QA",
    "RO",
    "RS",
    "RW",
    "SA",
    "SB",
    "SC",
    "SE",
    "SG",
    "SH",
    "SI",
    "SK",
    "SL",
    "SN",
    "SO",
    "SR",
    "ST",
    "SV",
    "SY",
    "SZ",
    "TD",
    "TG",
    "TH",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UG",
    "US",
    "UY",
    "UZ",
    "VC",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "ZM",
  ],
  Realex: ["IE", "GB", "FR", "BE", "NL", "LU", "IT", "US", "CA", "ES"],
  "BBVA (formerly CatalunyaCaixa, via Redsys)": [
    "ES",
    "FR",
    "GB",
    "IT",
    "PL",
    "PT",
  ],
  "Redsys Rest": ["ES"],
  S5: ["DK"],
  "Nuvei (formerly SafeCharge)": [
    "AT",
    "AU",
    "BE",
    "BG",
    "CA",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "GR",
    "ES",
    "FI",
    "FR",
    "GI",
    "HK",
    "HR",
    "HU",
    "IE",
    "IS",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MT",
    "MX",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SE",
    "SG",
    "SI",
    "SK",
    "GB",
    "US",
  ],
  "Sage Payment Solutions": ["US", "CA"],
  SagePay: ["GB", "IE"],
  SecureNet: ["US"],
  "SecurePay Australia": ["AU"],
  SecurionPay: [
    "AD",
    "BE",
    "BG",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GI",
    "GL",
    "GR",
    "GS",
    "GT",
    "HR",
    "HU",
    "IE",
    "IS",
    "IT",
    "LI",
    "LR",
    "LT",
    "LU",
    "LV",
    "MC",
    "MT",
    "MU",
    "MV",
    "MW",
    "NL",
    "NO",
    "PL",
    "RO",
    "SE",
    "SI",
  ],
  Shift4: [
    "AD",
    "BE",
    "BG",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GI",
    "GL",
    "GR",
    "GS",
    "GT",
    "HR",
    "HU",
    "IE",
    "IS",
    "IT",
    "LI",
    "LR",
    "LT",
    "LU",
    "LV",
    "MC",
    "MT",
    "MU",
    "MV",
    "MW",
    "NL",
    "NO",
    "PL",
    "RO",
    "SE",
    "SI",
  ],
  Simetrik: ["PE", "AR"],
  Stripe: [
    "AE",
    "AT",
    "AU",
    "BE",
    "BG",
    "BR",
    "CA",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HK",
    "HU",
    "IE",
    "IN",
    "IT",
    "JP",
    "LT",
    "LU",
    "LV",
    "MT",
    "MX",
    "MY",
    "NL",
    "NO",
    "NZ",
    "PL",
    "PT",
    "RO",
    "SE",
    "SG",
    "SI",
    "SK",
    "US",
  ],
  "Stripe Payment Intents": [
    "AE",
    "AT",
    "AU",
    "BE",
    "BG",
    "BR",
    "CA",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HK",
    "HU",
    "IE",
    "IN",
    "IT",
    "JP",
    "LT",
    "LU",
    "LV",
    "MT",
    "MX",
    "MY",
    "NL",
    "NO",
    "NZ",
    "PL",
    "PT",
    "RO",
    "SE",
    "SG",
    "SI",
    "SK",
    "US",
  ],
  SumUp: [
    "AT",
    "BE",
    "BG",
    "BR",
    "CH",
    "CL",
    "CO",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
    "US",
  ],
  "Spreedly Test": ["US"],
  "MasterCard Gateway (formerly TNS)": [
    "AR",
    "AU",
    "BR",
    "FR",
    "DE",
    "HK",
    "MX",
    "NZ",
    "SG",
    "GB",
    "US",
  ],
  TransFirst: ["US"],
  "TransFirst Transaction Express": ["US"],
  TrustCommerce: ["US"],
  "USA ePay": ["US"],
  Vanco: ["US"],
  VersaPay: ["US"],
  "VisaNet Peru": ["US", "PE"],
  vPOS: ["PY"],
  WePay: ["US", "CA"],
  Wirecard: [
    "AD",
    "CY",
    "GI",
    "IM",
    "MT",
    "RO",
    "CH",
    "AT",
    "DK",
    "GR",
    "IT",
    "MC",
    "SM",
    "TR",
    "BE",
    "EE",
    "HU",
    "LV",
    "NL",
    "SK",
    "GB",
    "BG",
    "FI",
    "IS",
    "LI",
    "NO",
    "SI",
    "VA",
    "FR",
    "IL",
    "LT",
    "PL",
    "ES",
    "CZ",
    "DE",
    "IE",
    "LU",
    "PT",
    "SE",
  ],
  Wompi: ["CO"],
  WorldPay: [
    "AD",
    "AE",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BM",
    "BN",
    "BO",
    "BR",
    "BS",
    "BT",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CF",
    "CH",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CV",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LC",
    "LI",
    "LK",
    "LS",
    "LT",
    "LU",
    "LV",
    "MA",
    "MC",
    "MD",
    "ME",
    "MG",
    "MH",
    "MK",
    "ML",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PH",
    "PK",
    "PL",
    "PN",
    "PR",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SE",
    "SG",
    "SI",
    "SK",
    "SL",
    "SM",
    "SN",
    "ST",
    "SV",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TM",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "ZA",
    "ZM",
  ],
  "Worldpay US": ["US"],
  "X Pay": [
    "AT",
    "BE",
    "CY",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "PT",
    "SK",
    "SI",
    "ES",
    "BG",
    "HR",
    "DK",
    "NO",
    "PL",
    "RO",
    "RO",
    "SE",
    "CH",
    "HU",
  ],
  MPGS: [
    "AU",
    "AR",
    "BR",
    "FR",
    "DE",
    "HK",
    "MX",
    "NZ",
    "SG",
    "GB",
    "US",
    "AE",
    "BD",
    "BN",
    "EG",
    "ID",
    "JO",
    "KW",
    "LB",
    "LK",
    "MU",
    "MV",
    "MY",
    "OM",
    "PH",
    "QA",
    "SA",
    "TT",
    "VN",
  ],
  Doku: ["ID"],
  Hyperpay: ["SA", "AE", "JO", "OM", "QA", "IQ", "LB", "BH"],
  Ipaymu: ["ID"],
  Midtrans: ["ID"],
  Moyasar: ["SA"],
  Myfatoorah: ["KW", "SA", "AE", "QA", "BH", "OM", "EG", "JO"],
  Xendit: ["ID", "TH", "VN", "PH", "MY", "SG"],
  Stcpay: ["SA", "BH", "KW"],
  Tap: ["KW", "SA", "BH", "AE", "QA", "OM", "EG", "JO", "LB"],
  Paytabs: ["AE", "SA", "EG", "JO", "OM", "IQ", "KW"],
};

export default processorCountry;
