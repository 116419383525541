import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import { SketchPicker } from "react-color"; // Importing the SketchPicker from react-color
import "./CheckoutConfiguration.css"; // Import the CSS file
import { Dropdown } from "@mui/base/Dropdown";
import { Box, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CssTransition } from "@mui/base/Transitions";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/material/styles";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { Menu } from "@mui/base/Menu";
import { Circle } from "@mui/icons-material";
import PaymentPreference from "../PaymentPreference/PaymentPreference";
import { httpClient } from "utils/HttpClient";
import {
  URL,
  CHECKOUT_URL,
  CHECKOUT_API_KEY,
  CHECKOUT_SETTINGS_ENDPOINT,
} from "constants/Constants";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "../../Icons/FloppyDisk";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import CaretDown from "components/Icons/CaretDown";
import { useTranslation } from "react-i18next";
import useCustomSnackbar from "components/SnackBar/CustomSnackBar";

// const fontWeights = ["Light", "Regular", "Medium", "Bold", "ExtraBold"];
const fontWeights = ["Light", "Bold"];

const CheckoutConfiguration = () => {
  const [config, setConfig] = useState({});
  const [defaultConfig, setDefaultConfig] = useState({
    title: "Payorch Checkout",
    logoUrl: "https://dashboard.pr-dev.payorch.com/logos/payorch-logo.svg",
    primaryColor: "#114B5F",
    secondaryColor: "#ffffff",
    primaryFontColor: "#f7f8f6",
    secondaryFontColor: "#525866",
    fontWeight: "Light",
    fontSize: "18",
    paymentPreference: [
      "Credit & Debit Cards",
      "Virtual Account",
      "Qris",
      "Over-The-Counter (OTC)",
      "Wallets",
    ],
  });
  const checkoutRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [activeColor, setActiveColor] = useState("primaryColor");
  const [isLoading, setIsLoading] = useState(false);

  const [weight, setWeight] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [updateFontWeight, setUpdateFontWeight] = useState({
    changeFontWeight: { weight: "" },
  });
  const { t } = useTranslation();
  const { showCustomSnackbar } = useCustomSnackbar();

  useEffect(() => {
    if (config.fontWeight) {
      setUpdateFontWeight({
        changeFontWeight: { weight: config.fontWeight },
      });
    }
  }, [config.fontWeight]);

  const onHandleFontWeight = (getFontWeight) => {
    setUpdateFontWeight((prevFontWeight) => ({
      ...prevFontWeight,
      changeFontWeight: { weight: getFontWeight },
    }));
    setDropdown(false);
    setConfig((prevConfig) => ({
      ...prevConfig,
      fontWeight: getFontWeight,
    }));
  };

  // useEffect(() => {
  //   setUpdateFontWeight((prevFontWeight) => ({
  //     ...prevFontWeight,
  //     changeFontWeight: { weight: weight },
  //   }));
  // }, [weight]);

  // console.log(weight, "weight");

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(updateFontWeight, "updateFont");

  const fetchConfigurationData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${CHECKOUT_SETTINGS_ENDPOINT}`,
        "GET"
      );
      const isInvalid =
        !Object.keys(data).length ||
        Object.values(data).every((v) => v == null);
      setConfig(isInvalid ? defaultConfig : data);
      console.log(data, "Config data");
      return isInvalid ? defaultConfig : data;
    } catch (error) {
      console.error("Error fetching Configuration data:", error);
      return null;
    }
  };

  // const saveConfigurationSettings = () => {
  //   console.log(config, "Config data");
  //   //setIsLoading(true);
  //   httpClient
  //     .fetchData(`${URL}${CHECKOUT_SETTINGS_ENDPOINT}`, "POST", config)
  //     .then((response) => {
  //       fetchConfigurationData();
  //       setIsLoading(false);
  //     });
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setOpen(true);
  //   }, 2000);

  //   showCustomSnackbar(
  //     "success",
  //     `Checkout Configuration settings have been saved successfully`,
  //     "right",
  //     "top",
  //     3000
  //   );
  // };

  const saveConfigurationSettings = () => {
    console.log(config, "Config data");
    // setIsLoading(true);

    httpClient
      .fetchData(`${URL}${CHECKOUT_SETTINGS_ENDPOINT}`, "POST", config)
      .then((response) => {
        fetchConfigurationData();
        setIsLoading(false);
        showCustomSnackbar(
          "success",
          "Checkout Configuration Settings have been saved successfully",
          "right",
          "top",
          3000
        );
      })
      .catch((error) => {
        console.error("Error saving configuration:", error);
        setIsLoading(false);
        showCustomSnackbar(
          "error",
          "Failed to save Checkout Configuration settings",
          "right",
          "top",
          3000
        );
      });

    setTimeout(() => {
      setIsLoading(false);
      setOpen(true);
    }, 2000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  const handleFontSizeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        fontSize: value,
      }));
    }
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const blue = {
    50: "#F0F7FF",
    100: "#C2E0FF",
    200: "#99CCF3",
    300: "#66B2FF",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E6",
    700: "#0059B3",
    800: "#004C99",
    900: "#003A75",
  };

  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 8px;
      cursor: default;
      user-select: none;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &:focus {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[200]
        };
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${menuItemClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
      `
  );

  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border: 0;
      background-color: white;
      `
  );

  // Handle color selection from color picker
  const handleColorChange = (color) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [activeColor]: color.hex,
    }));
    setShowColorPicker(!showColorPicker);
  };

  // Toggle the visibility of the color picker
  const toggleColorPicker = (colorType) => {
    setActiveColor(colorType);
    setShowColorPicker(!showColorPicker);
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setConfig((prevConfig) => ({
  //       ...prevConfig,
  //       logoUrl: URL.createObjectURL(file),
  //     }));
  //   }
  // };

  const handleImageChange = (event) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      logoUrl: event.target.value,
    }));
  };

  const handleDataFromChild = (data) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      paymentPreference: data,
    }));
  };

  const Listbox = styled("ul")(
    ({ theme }) => `
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 6px;
      margin: 12px 0;
      min-width: 200px;
      border-radius: 12px;
      overflow: auto;
      outline: 0px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      box-shadow: 0px 4px 30px ${
        theme.palette.mode === "dark" ? grey[900] : grey[200]
      };
      z-index: 1;
    
      .closed & {
        opacity: 0;
        transform: scale(0.95, 0.8);
        transition: opacity 200ms ease-in, transform 200ms ease-in;
      }
      
      .open & {
        opacity: 1;
        transform: scale(1, 1);
        transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
      }
    
      .placement-top & {
        transform-origin: bottom;
      }
    
      .placement-bottom & {
        transform-origin: top;
      }
      `
  );

  const AnimatedListbox = React.forwardRef(function AnimatedListbox(
    props,
    ref
  ) {
    const { ownerState, ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
      throw new Error(
        "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
      );
    }

    const verticalPlacement = popupContext.placement.split("-")[0];

    return (
      <CssTransition
        className={`placement-${verticalPlacement}`}
        enterClassName="open"
        exitClassName="closed"
      >
        <Listbox {...other} ref={ref} />
      </CssTransition>
    );
  });

  useEffect(() => {
    // Load PayorchCheckout script
    const loadScriptAndInitialize = async () => {
      const configuration = await fetchConfigurationData();
      if (configuration) {
        const script = document.createElement("script");
        script.src = CHECKOUT_URL;
        // script.src = "http://localhost:3001/checkout.js";
        script.async = true;

        script.onload = function () {
          if (window.PayorchCheckout) {
            console.log("PayorchCheckout loaded");
            // Only initialize checkout once
            if (!checkoutRef.current) {
              checkoutRef.current = new window.PayorchCheckout();
            }
            if (checkoutRef.current) {
              const portalParentDiv =
                document.getElementsByClassName("check-parent-div")[0];
              if (portalParentDiv) {
                checkoutRef.current.whitelabelInitialize({
                  configuration,
                  scroll: true,
                });
              } else {
                console.log(
                  "Screen changed by User before checkout initialization"
                );
              }

              setTimeout(() => {
                console.log(configuration, "config");
                // const mainCheckoutCont = document.createElement("div");
                // mainCheckoutCont.classList.add("main-checkout-container");

                const checkoutParentDiv =
                  document.getElementsByClassName("payorch")[0];
                // checkoutParentDiv.style.display = "none";

                // const apiButton = document.getElementById("simple-tab-0");
                // apiButton.addEventListener("click", function () {
                //   console.log("api button clicked");
                //   checkoutParentDiv.style.display = "none";
                // });

                // const webhooksButton =
                //   document.getElementById("simple-tab-1")?.value;
                // webhooksButton.addEventListener("click", function () {
                //   console.log("webhooks button clicked");
                //   checkoutParentDiv.style.display = "none";
                // });

                // const googlepayButton = document.getElementById("simple-tab-2");

                // googlepayButton.addEventListener("click", function () {
                //   console.log("googlepay button clicked");
                //   checkoutParentDiv.style.display = "none";
                // });

                const checkoutButton = document.getElementById("simple-tab-3");

                checkoutButton.addEventListener("click", function () {
                  console.log("checkout button clicked");
                  checkoutParentDiv.style.display = "block";
                });

                const backdropDivToBeRemoved =
                  document.getElementsByClassName("modal-overlay")[0];
                const modalDivToBeAdded =
                  document.getElementsByClassName("modal-content")[0];
                if (checkoutParentDiv) {
                  checkoutParentDiv.style.width = "50%";
                  // checkoutParentDiv.style.width = "400px";
                  checkoutParentDiv.style.height = "fit-content";
                  modalDivToBeAdded.style.width = "100%";

                  if (checkoutParentDiv.contains(backdropDivToBeRemoved)) {
                    checkoutParentDiv.removeChild(backdropDivToBeRemoved);
                  }
                  checkoutParentDiv.appendChild(modalDivToBeAdded);
                  // mainCheckoutCont.appendChild(checkoutParentDiv);
                  const portalParentDiv =
                    document.getElementsByClassName("check-parent-div")[0];
                  portalParentDiv.appendChild(checkoutParentDiv);
                  document.getElementsByClassName(
                    "modal-body"
                  )[0].style.maxHeight = "100%";
                  checkoutParentDiv.style.border = "1px solid #cacfd8";
                  checkoutParentDiv.style.borderRadius = "26px";
                  document.getElementsByClassName(
                    "modal-footer-action-container"
                  )[0].style.padding = "24px";
                }
              }, 1000);
            }
          } else {
            console.error("PayorchCheckout is not defined");
          }
        };

        script.onerror = function () {
          console.error("Failed to load checkout.js script");
        };

        document.body.appendChild(script);

        // Clean up script when component unmounts
        return () => {
          document.body.removeChild(script);
        };
      } else {
        console.error("Configuration data not available");
      }
    };
    loadScriptAndInitialize();
  }, []);
  useEffect(() => {
    if (checkoutRef.current) {
      checkoutRef.current.whitelabelUpdate(config);
    }
  }, [config]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [open]);

  const previewCheckout = () => {
    // const dataObj = {
    //   autoCapture: true,
    //   type: "PAYMENT",
    //   merchantReferenceId: Date.now(),
    //   createCustomer: true,
    //   savePaymentInstrument: true,
    //   order: {
    //     lineItems: [
    //       {
    //         itemId: "1",
    //         name: "Polo Shirt",
    //         sku: "sku-1",
    //         brand: "Samsung",
    //         type: "goods",
    //         url: "http://localhost:3000/images/shirt.png",
    //         quantity: 1,
    //         category: "services",
    //         amount: {
    //           currencyCode: "IDR",
    //           value: 10000,
    //         },
    //       },
    //     ],
    //   },
    //   amount: {
    //     currencyCode: "IDR",
    //     value: 10000,
    //   },
    //   descriptor: "Test tran",
    //   description: "Test tran",
    //   customer: {
    //     merchantCustomerId: "user1",
    //     firstName: "John",
    //     lastName: "Deo",
    //     email: "john.deo@gmail.com",
    //     nationality: "ID",
    //     phone: {
    //       countryCode: "+61",
    //       number: "5454466465",
    //     },
    //   },
    //   billingAddress: {
    //     name: "Home",
    //     line1: "Pert Selmis Bl I/52",
    //     line2: "",
    //     city: "Jakarta",
    //     state: "Jakarta",
    //     country: "ID",
    //     zip: "12830",
    //   },
    // };
    // var url = "https://api.pr-dev.payorch.com/payorch/api/v1/checkoutsessions";
    // fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Basic bWVyY2hhbnRkZW1vMTpUZXN0QDEyMw==",
    //   },
    //   body: JSON.stringify(dataObj),
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("HTTP error, status = " + response.status);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //   // function (result) {
    //   //   console.log(result);
    //   //   // var searchUrl = `https://api.pr-test.payorch.com/payorch/api/v1/search`;
    //   //   // const payload = { checkoutSessionToken: data.token };
    //   //   // fetch(searchUrl, {
    //   //   //   method: "POST",
    //   //   //   headers: {
    //   //   //     "Content-Type": "application/json",
    //   //   //     Authorization: "Basic bWVyY2hhbnRkZW1vMTpUZXN0QDEyMw==",
    //   //   //   },
    //   //   //   body: JSON.stringify(payload),
    //   //   // })
    //   //   //   .then((response) => {
    //   //   //     if (!response.ok) {
    //   //   //       throw new Error("HTTP error, status = " + response.status);
    //   //   //     }
    //   //   //     return response.json();
    //   //   //   })
    //   //   //   .then((data) => {});
    //   //   // window.location.reload();
    //   // }
    // );
    // });
  };

  return (
    <div>
      <div className="checkout-button-container">
        <div className="hr-line-checkout-label-container">
          <Typography className="config-page-text">
            Checkout Configuration
          </Typography>
          {/* <div className="checkout-hr-line"></div> */}
        </div>
        <div>
          <Tooltip title="Save">
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={() => saveConfigurationSettings()}
              className="btn btn-primary"
              startIcon={<SaveIcon />}
            >
              {t("SAVE")}
            </LoadingButton>
          </Tooltip>
        </div>
      </div>
      <div className="check-parent-div">
        <div className="main-container">
          <Grid>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className="config-page-text">
                Checkout Configuration
              </Typography>
              <div className="hide-save-button">
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={() => saveConfigurationSettings()}
                  className="btn btn-primary"
                  startIcon={<SaveIcon />}
                >
                  {t("SAVE")}
                </LoadingButton>
              </div>
            </Box> */}

            <Box>{/* <div className="hr-line"></div> */}</Box>
            <Grid item xs={12} className="customization-grid">
              <Box>
                <div className="section">
                  <div>
                    <label htmlFor="checkoutTitle" className="checkout-text">
                      Checkout Title:
                    </label>
                  </div>
                  <div className="input-50">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={config.title}
                      onChange={handleChange}
                      min="10"
                      max="50"
                    />
                  </div>
                </div>
                <div className="section">
                  <div>
                    <label htmlFor="logoUrl" className="checkout-text">
                      Logo URL:
                    </label>
                  </div>
                  <div className="input-50">
                    <input
                      type="text"
                      id="imageUpload"
                      name="Logo"
                      placeholder="Logo URL"
                      value={config.logoUrl}
                      onChange={handleImageChange}
                      // onKeyDown={(event) => {
                      //   if (event.key === "Backspace") {
                      //     console.log(event.target.value, "back space");
                      //   }
                      // }}
                      min="10"
                      max="50"
                    />
                  </div>
                </div>
                {/* <div className="section">
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                ̰
              </div>
            </div> */}
              </Box>
            </Grid>
            <Grid item xs={12} className="customization-grid">
              <Box>
                <div className="section">
                  <div>
                    <label htmlFor="primaryColor" className="checkout-text">
                      Primary Color:
                    </label>
                  </div>
                  <div>
                    <div
                      className="color-picker-swatch"
                      style={{
                        backgroundColor: config.primaryColor || "#ffffff",
                      }}
                      onClick={() => toggleColorPicker("primaryColor")}
                    />
                    {showColorPicker && activeColor === "primaryColor" && (
                      <div className="color-picker-popup">
                        <SketchPicker
                          color={config.primaryColor || "#ffffff"}
                          onChange={handleColorChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="section">
                  <div>
                    <label htmlFor="secondaryColor" className="checkout-text">
                      Secondary Color:
                    </label>
                  </div>
                  <div>
                    <div
                      className="color-picker-swatch"
                      style={{
                        backgroundColor: config.secondaryColor || "#ffffff",
                      }}
                      onClick={() => toggleColorPicker("secondaryColor")}
                    />
                    {showColorPicker && activeColor === "secondaryColor" && (
                      <div className="color-picker-popup">
                        <SketchPicker
                          color={config.secondaryColor || "#ffffff"}
                          onChange={handleColorChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} className="customization-grid">
            <Box>
              <div className="section">
                <div>
                  <label className="checkout-text" htmlFor="primaryFontColor">
                    Primary Font Color:
                  </label>
                </div>
                <div>
                  <div
                    className="color-picker-swatch"
                    style={{
                      backgroundColor: config.primaryFontColor || "#ffffff",
                    }}
                    onClick={() => toggleColorPicker("primaryFontColor")}
                  />
                  {showColorPicker && activeColor === "primaryFontColor" && (
                    <div className="color-picker-popup">
                      <SketchPicker
                        color={config.primaryFontColor || "#ffffff"}
                        onChange={handleColorChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="section">
                <div>
                  <label className="checkout-text" htmlFor="secondaryFontColor">
                    Secondary Font Color:
                  </label>
                </div>
                <div>
                  <div
                    className="color-picker-swatch"
                    style={{
                      backgroundColor: config.secondaryFontColor || "#ffffff",
                    }}
                    onClick={() => toggleColorPicker("secondaryFontColor")}
                  />
                  {showColorPicker && activeColor === "secondaryFontColor" && (
                    <div className="color-picker-popup">
                      <SketchPicker
                        color={config.secondaryFontColor || "#ffffff"}
                        onChange={handleColorChange}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="section">
                <div>
                  <label className="checkout-text" htmlFor="secondaryFontColor">
                    Font Weight:
                  </label>
                </div>
                <div>
                  <div
                    style={{ position: "relative", disply: "inline-block" }}
                    ref={dropdownRef}
                  >
                    {/* <Dropdown>
                  <MenuButton
                    sx={{ paddingBottom: "20px", marginRight: "4px" }}
                  >
                    <Chip
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            className="settings-title"
                            sx={{ fontSize: "13px", marginTop: "2px" }}
                          >
                            {config.fontWeight}{" "}
                          </Typography>
                          <KeyboardArrowDownIcon className="settings-title" />
                        </Box>
                      }
                    />
                  </MenuButton>
                  <Menu slots={{ listbox: AnimatedListbox }}>
                    <Typography className="menu-header">
                      Select font weight
                    </Typography>
                    {fontWeights.map((value) => (
                      <MenuItem
                        key={value}
                        // disabled={checkIfCurrencyExists(currency.code)}
                        onClick={() =>
                          handleChange({
                            target: { name: "fontWeight", value: value },
                          })
                        }
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor:
                            config.fontWeight === value
                              ? "#ecd1d1"
                              : "transparent",
                        }}
                      >
                        <Circle className="dot-circle" />
                        <Typography className="currency-name">
                          {value}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown> */}
                    <div
                      className="font-weight-button-container"
                      onClick={() => {
                        setDropdown(!dropdown);
                      }}
                    >
                      <p>{updateFontWeight?.changeFontWeight?.weight}</p>
                      <KeyboardArrowDownIcon className="settings-title" />
                      {/* <CaretDown /> */}
                    </div>
                    {dropdown && (
                      <div className="font-weight-dropdown-container">
                        {fontWeights?.map((eachFontValue, index) => (
                          <div
                            key={index}
                            className={
                              config?.fontWeight === eachFontValue
                                ? "selected-background"
                                : "each-font-weight-list"
                            }
                            onClick={() => {
                              onHandleFontWeight(eachFontValue);
                              //setWeight(eachFontValue);
                            }}
                          >
                            <Circle className="dot-circle" />
                            {/* <Circle className="font-weight-dot-circle" /> */}
                            <Typography className="currency-name">
                              {" "}
                              {eachFontValue}{" "}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="section">
                <div>
                  <label className="checkout-text" htmlFor="fontSize">
                    Font Size:
                  </label>
                </div>
                <div className="input-50">
                  <input
                    type="number"
                    id="fontSize"
                    name="fontSize"
                    value={config.fontSize}
                    onChange={handleFontSizeChange}
                    min="10"
                    max="50"
                  />
                </div>
              </div>

              <div className="section">
                <div>
                  <label className="checkout-text" htmlFor="fontSize">
                    Arrange Payment methods
                  </label>
                </div>
                <div>
                  <PaymentPreference
                    onDataFromChild={handleDataFromChild}
                    updatedPaymentPreference={config.paymentPreference}
                  />
                </div>
              </div>
            </Box>
          </Grid>
          {/* <div className="section" style={{ justifyContent: "end" }}>
        <Box sx={{ width: "100%" }}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              Updated Checkout Configuration Successfully!
            </Alert>
          </Collapse>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={saveConfigurationSettings}
            className="color-picker-button btn btn-primary"
            startIcon={<SaveIcon />}
          >
            Save Settings
          </LoadingButton>
        </Box>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default CheckoutConfiguration;
