import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  FormControl,
  Select,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  TableFooter,
  Pagination,
  Tooltip,
  Typography,
  OutlinedInput,
  IconButton,
  Radio,
} from "@mui/material";
import { DashboardBreadcrumbIcon } from "components/Icons/Dashboard";
import "react-datetime-range-super-picker/dist/index.css";
import { DNA } from "react-loader-spinner";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import data from "../../utils/TransactionsMap";
import TransactionDetails from "../../components/Elements/Transactions/TransactionDetails";
import CaretDown from "components/Icons/CaretDown";
import XCircleIcon from "components/Icons/XCircle";
import SaveIcon from "components/Icons/FloppyDisk";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import {
  URL,
  PAYMENT_TRANSACTIONS_ENDPOINT,
  ANALYTICS_ENDPOINT,
} from "constants/Constants";

import "./Dashboard.css";

import { httpClient } from "utils/HttpClient";
import Logout from "Logout";
import "reactjs-popup/dist/index.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useMenuFunctions } from "components/Menu/MenuFunctionProvider";
import { useTranslation } from "react-i18next";
import AcceptanceRateChart from "./AcceptanceRateChart";
import TotalAmountChart from "./TotalAmountChart";
import TransactionCountChart from "./TransactionCountChart";

import DateRangePicker from "components/Transactions/DateRangePicker";
import { getInitialDate } from "utils/ConvertDateToString";
import dayjs from "dayjs";
import moment from "moment";
import TestBanner from "components/TestBanner/TestBanner";

const Dashboard = () => {
  const isKeyCloakTokenExchanged = useSelector(
    (state) => state.merchant.isKeyCloakTokenExchanged
  );
  const initialDate = getInitialDate();
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  let initialState = [];
  const [filters, setFilters] = useState({});
  const [chipDelete, setChipDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedChipIndex, setClickedChipIndex] = useState(null);
  const allColumns = Object.keys(data.transactionsMap);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptionsMap, setSelectedOptionsMap] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState(
    allColumns.filter((columnKey) =>
      data.ListOfShowingColumns.includes(columnKey)
    )
  );
  const [page, setPage] = useState(1);
  const [dropdownFilter, setDropdownFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [clickedLinkId, setClickedLinkId] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: t("DASHBOARD"), component: <DashboardBreadcrumbIcon /> },
  ]);
  const [fromButtonStatus, setFromButtonStatus] = useState(false);
  const [toButtonStatus, setToButtonStatus] = useState(false);
  const [dateLabel, setDateLabel] = useState(false);
  const [merchantReferenceId, setMerchantReferenceId] = useState(false);
  const [amount, setAmount] = useState(false);
  const [processorReconId, setProcessorReconId] = useState(false);
  const [processorId, setProcessorId] = useState(false);
  const [getDate, setGetDate] = useState(false);
  const [selectCalenderDate, setSelectCalenderDate] = useState(false);
  const [twoDatesValidation, setTwoDatesValidation] = useState(false);
  const [dropdownFilters, setDropdownFilters] = useState(false);

  const { toggleExpand } = useMenuFunctions();

  const statusLength = filters?.status?.length;
  const currencyLength = filters?.currency && filters.currency !== "" ? 1 : 0;
  const paymentMethodLength =
    filters?.paymentMethod && filters.paymentMethod !== "" ? 1 : 0;
  const processorCodeLength =
    filters?.processorCode && filters.processorCode !== "" ? 1 : 0;
  const transactionTypeLength = filters?.transactionType?.length;

  const handleLinkClick = (id, type) => {
    setSelectedTransactionType(type);
    setClickedLinkId(id);
  };

  console.log(filters, "filters");
  console.log(selectedOptionsMap, "map");
  console.log(transactions, "transactions");

  useEffect(() => {
    if (filters.transactionTime_from && filters.transactionTime_to) {
      setTwoDatesValidation(true);
    }
  }, [filters.transactionTime_from, filters.transactionTime_to]);

  useEffect(() => {
    if (filters?.merchantReferenceId?.length > 0) {
      setMerchantReferenceId(true);
    } else {
      setMerchantReferenceId(false);
    }

    if (filters?.processorReconId?.length > 0) {
      setProcessorReconId(true);
    } else {
      setProcessorReconId(false);
    }

    if (filters?.processorTransactionId?.length > 0) {
      setProcessorId(true);
    } else {
      setProcessorId(false);
    }
  });

  const applyfilters = () => {
    console.log("apply");
    handleClose();
  };

  const handleDetailsClose = () => {
    console.log("close");
    setClickedLinkId(null);
    setSelectedTransactionType(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const hasFilters = (filter) =>
    filters[filter.filterOptions.queryParam]?.length ||
    (filters[filter.filterOptions.queryParamInbound]?.length &&
      filters[filter.filterOptions.queryParamOutbound]?.length);

  Object.keys(data.transactionsMap).forEach((key) => {
    const selectedOptions =
      data.transactionsMap[key].filterOptions.selectedOptions;
    initialState = {
      ...initialState,
      [key]: selectedOptions,
    };
  });

  useLayoutEffect(() => {
    fetchTransactionData();
  }, []);
  const fetchTransactionData = () => {
    setPage(1);
    const formData = new URLSearchParams();

    formData.append(
      "transactionType",
      "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
    );

    const response = httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,

        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        // console.log(responseData);
        setTransactions(responseData);
        if (responseData.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const fetchAnalyticsDataWithFilter = (dateFilterFrom, dateFilterTo) => {
    console.log(filters, "425==");

    setanalyticsInputData((prevData) => ({
      ...prevData,
      startDate: dateFilterFrom || initialDate.sevenDaysAgo,
      endDate: dateFilterTo || initialDate.todayEndTime,
      filters: { ...filters },
    }));
    console.log(analyticsInputData, "inutdata");
  };

  const handleFilterChange = (type, param) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: param,
    }));

    setSelectedOptionsMap((prevFilters) => ({
      ...prevFilters,
      [type]: param,
    }));
  };

  const reloadFilters = () => {
    setWarning(false);
    fetchAnalyticsDataWithFilter();
  };

  const resetFilters = (filter, queryParamInbound, queryParamOutbound) => {
    setChipDelete((prevValue) => !prevValue);
    if (filter) {
      // Reset specific filter

      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: Array.isArray(filter)
          ? []
          : typeof filter === "string"
          ? null
          : { [queryParamInbound]: null, [queryParamOutbound]: null },
      }));
      if (Array.isArray(filters[filter])) {
        setSelectedOptionsMap((prevSelectedOptionsMap) => ({
          ...prevSelectedOptionsMap,
          [filter]: [],
        }));
      }
      console.log("filters ", filters);
    } else {
      // Reset all filters
      setPage(1);
      setFilters([]);
      applyfilters();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedChipIndex(null);
    setTwoDatesValidation(false);
  };

  const handleChipDelete = (
    queryParam,
    queryParamInbound,
    queryParamOutbound
  ) => {
    resetFilters(queryParam, queryParamInbound, queryParamOutbound);

    handleClose();
  };

  const handleCheckboxChange = (columnKey) => (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedColumns([...checkedColumns, columnKey]);
      if (!data.ListOfShowingColumns.includes(columnKey)) {
        data.ListOfShowingColumns.push(columnKey);
      }
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    } else {
      setCheckedColumns(checkedColumns.filter((col) => col !== columnKey));
      data.ListOfShowingColumns = data.ListOfShowingColumns.filter(
        (col) => col !== columnKey
      );
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    }
  };

  const handleFilterRadioChange = (filterParam, option) => {
    setFilters((prevFilters) => {
      if (option === null || option === "") {
        const { [filterParam]: removed, ...rest } = prevFilters;
        return rest;
      }

      return {
        ...prevFilters,
        [filterParam]: option,
      };
    });

    setSelectedOptionsMap((prevSelectedOptionsMap) => ({
      ...prevSelectedOptionsMap,
      [filterParam]: option,
    }));
  };

  const handleFilterCheckboxChange = (filterParam, option) => {
    setSelectedOptionsMap((prevSelectedOptionsMap) => {
      const selectedOptions = prevSelectedOptionsMap[filterParam] || [];
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((opt) => opt !== option)
        : [...selectedOptions, option];
      handleFilterChange(filterParam, updatedOptions);
      return {
        ...prevSelectedOptionsMap,
        [filterParam]: updatedOptions,
      };
    });
  };

  // Select All Option
  const handleSelectAllOptions = (param, optionsList) => {
    let selectedData;
    Object.keys(data.transactionsMap).filter((paramData) => {
      if (data.transactionsMap[paramData].path === param) {
        selectedData = paramData;
      } else if (data.transactionsMap[paramData].path.slice(7, 15) === param) {
        selectedData = paramData;
      }
    });

    const selectedParamOptionsLength =
      data.transactionsMap[selectedData]?.filterOptions.options.length;
    if (filters[param]?.length === selectedParamOptionsLength) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [param]: "",
      }));
      setSelectedOptionsMap((prevFilters) => ({
        ...prevFilters,
        [param]: "",
      }));
    } else {
      handleFilterChange(param, optionsList);
    }
  };

  const clickFilters = () => {
    setDropdownFilter(!dropdownFilter);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      reloadFilters();
    }
  };

  useEffect(() => {
    if (filters.text === "") {
      reloadFilters();
    }
  }, [filters.text]);

  const calenderDateChange = (data) => {
    const dateFilterFrom =
      data.transactionTime_from.slice(0, 19).replace("T", " ") + ".000";
    const dateFilterTo =
      data.transactionTime_to.slice(0, 19).replace("T", " ") + ".000";
    fetchAnalyticsDataWithFilter(dateFilterFrom, dateFilterTo);
    console.log(
      moment(data.transactionTime_from)
        .utc()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DDTHH:mm:ss.SSS+0000"),
      "292"
    );
  };

  const handleFilterDropdown = () => {
    setDropdownFilters(!dropdownFilters);
  };

  const [analyticsData, setAnalyticsData] = useState({
    paymentMethod: [],
    processorCode: [],
    currency: [],
    all: [],
    paymentMethod_currency: [],
    processorCode_currency: [],
  });
  const [forceRender, setForceRender] = useState(false);
  useEffect(() => {
    console.log(analyticsData, "Updated Analytics Data in useEffect");
  }, [analyticsData]); // This will run whenever analyticsData changes

  const startDate = initialDate.sevenDaysAgo;
  const endDate = initialDate.todayEndTime;
  const [analyticsInputData, setanalyticsInputData] = useState({
    type: "ANALYTICS",
    startDate: startDate,
    endDate: endDate,
    frequency: "day",
    // groupBy: ["currency", "processorCode", "paymentMethod"],
    dataColumns: ["totalAmount", "transactionCount", "acceptanceRate"],
    filters: {},
  });
  const fetchAnalyticsData = async (groupBy) => {
    if (groupBy == "paymentMethod_currency") {
      groupBy = ["paymentMethod", "currency"];
    }
    if (groupBy == "processorCode_currency") {
      groupBy = ["processorCode", "currency"];
    }
    try {
      setIsLoading(true);
      const data = await httpClient.fetchData(
        `${URL}${ANALYTICS_ENDPOINT}`,
        "POST",
        {
          ...analyticsInputData,
          groupBy: groupBy
            ? Array.isArray(groupBy)
              ? groupBy
              : [groupBy]
            : [], // Only fetch data for this specific group
        }
      );
      if (Array.isArray(groupBy)) {
        if (groupBy.includes("paymentMethod")) {
          groupBy = "paymentMethod_currency";
        }
        if (groupBy.includes("processorCode")) {
          groupBy = "processorCode_currency";
        }
      }
      setAnalyticsData((prevData) => ({
        ...prevData,
        [groupBy ? groupBy : "all"]: data.data, // Store the data based on groupBy
      }));
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (error) {
      console.error(`Error fetching Analytics data for ${groupBy}:`, error);
    }
  };
  useEffect(() => {
    const groupByValues = [
      "paymentMethod",
      "processorCode",
      "currency",
      null,
      "paymentMethod_currency",
      "processorCode_currency",
    ];
    groupByValues.forEach(fetchAnalyticsData);
  }, [analyticsInputData, isKeyCloakTokenExchanged]);

  useEffect(() => {
    // If analyticsData changes, trigger a re-render
    setForceRender((prev) => !prev);
  }, [analyticsData]);

  const sections = [
    {
      title: "Acceptance Rate",
      component:
        analyticsData.all?.length > 0 &&
        analyticsData.paymentMethod?.length > 0 &&
        analyticsData.processorCode?.length > 0
          ? [
              <AcceptanceRateChart
                analyticsData={analyticsData.all.filter(
                  (ele) => ele.acceptanceRate !== "N/A"
                )}
                type="successGraph"
                text=""
                chartType="Line"
              />,
              <AcceptanceRateChart
                analyticsData={analyticsData.paymentMethod.filter(
                  (ele) => ele.acceptanceRate !== "N/A"
                )}
                type="paymentMethod"
                text="Payment Method"
                chartType="Bar"
              />,

              <AcceptanceRateChart
                analyticsData={analyticsData.processorCode.filter(
                  (ele) => ele.acceptanceRate !== "N/A"
                )}
                type="processorCode"
                text="Processor"
                chartType="Bar"
              />,
            ]
          : null,
    },

    {
      title: "Total Amount",
      component:
        analyticsData.currency?.length > 0 &&
        analyticsData.paymentMethod_currency?.length > 0 &&
        analyticsData.processorCode_currency?.length > 0
          ? [
              <TotalAmountChart
                analyticsData={analyticsData.currency}
                type="currency"
                text="Currency"
              />,
              <TotalAmountChart
                analyticsData={analyticsData.paymentMethod_currency}
                type="paymentMethod_currency"
                text="Payment Method"
              />,
              <TotalAmountChart
                analyticsData={analyticsData.processorCode_currency}
                type="processorCode_currency"
                text="Processor"
              />,
            ]
          : null,
    },
    {
      title: "Transaction Count",
      component:
        analyticsData.paymentMethod?.length > 0 &&
        analyticsData.processorCode?.length > 0
          ? [
              <TransactionCountChart
                analyticsData={analyticsData.paymentMethod}
                type="paymentMethod"
                text="Payment Method"
              />,
              <TransactionCountChart
                analyticsData={analyticsData.processorCode}
                type="processorCode"
                text="Processor"
              />,
            ]
          : null,
    },
  ];

  return (
    <div>
      {/* Test Mode Banner - Only shown when in test mode */}
      <TestBanner />
      <div className="toolbar py-2" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid container-fluid d-flex align-items-center"
        >
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div>
      <div className="container-xxl" id="kt_content_container">
        <div className="card">
          <Box className="card-header border-0 pt-6">
            <div className="card-title">
              <div className="header-title">{t("DASHBOARD")}</div>
              {/* <TextField  /> */}
              <div className="header-sub-title">
                {t("DASHBOARD_DESCRIPTION")}
              </div>
            </div>
          </Box>

          <Box className="card-header border-0  MuiBox-root css-0">
            <div className="card-toolbar">
              {/* card-title */}
              <DateRangePicker
                calenderDateChange={calenderDateChange}
                pageType="Dashboard"
              />
              {/* <h1>test</h1> */}
            </div>
            <div className="card-toolbar gap" style={{ display: "none" }}>
              <Box className="filter-button" onClick={handleFilterDropdown}>
                {/* <FunnelSimple /> */}
                <i class="ki-duotone ki-filter fs-2">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                </i>
                <Typography sx={{ color: "white", fontSize: "15px" }}>
                  {t("FILTER")}
                </Typography>
              </Box>
            </div>
          </Box>

          {dropdownFilters && (
            <div className="card-header">
              <div className={"filter-container-details mb-3"}>
                <Typography variant="paragraph" className="filters-text">
                  {t("FILTERS")}
                </Typography>
                <Box className="filter-frame-container">
                  {Object.values(data.transactionsMap).map((filter, index) => {
                    //console.log(filter, "filter Details");

                    const paymentTypePopupHeight =
                      filter.displayName === "Payment Type"
                        ? "payment-type-popup-height"
                        : filter.displayName === "Processor Code"
                        ? "payment-type-popup-height"
                        : "drop-down-menu-item";

                    return filter.filtered &&
                      filter.filterOptions.type === "STRING" ? (
                      ""
                    ) : filter.filtered === false ? (
                      ""
                    ) : (
                      <Box
                        key={filter.displayName}
                        className={
                          filter.displayName === "Currency Code" ||
                          filter.displayName === "Processor Code" ||
                          filter.displayName === "Payment Type"
                            ? "each-filter-frame"
                            : "hide-date-frame"
                        }
                      >
                        <Typography>
                          {filter.filterOptions.queryParam ===
                            "paymentMethod" && (
                            <span
                              className={
                                filters?.paymentMethod?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {t("paymentMethod")}
                            </span>
                          )}

                          {filter.filterOptions.queryParam ===
                            "processorCode" && (
                            <span
                              className={
                                filters?.processorCode?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {t("processorCode")}
                            </span>
                          )}

                          {filter.displayName === "Currency Code" && (
                            <span
                              className={
                                filters?.currency?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {t("currencyCode")}
                            </span>
                          )}

                          {/* {filter.displayName === "Transaction Type" && (
                            <span
                              className={
                                filters?.transactionType?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${t("transactionType")}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )} */}
                        </Typography>
                        <Box className="drop-down-container">
                          <div
                            className="click-dropdown"
                            onClick={(event) =>
                              setAnchorEl(
                                {
                                  index,
                                  currentTarget: event.currentTarget,
                                },
                                setClickedChipIndex(index),
                                setSearchTerm(""),
                                filter.displayName === "Date" &&
                                  setSelectCalenderDate(false)
                              )
                            }
                            onDelete={
                              hasFilters
                                ? () =>
                                    handleChipDelete(
                                      filter.filterOptions.queryParam,
                                      filter.filterOptions.queryParamInbound,
                                      filter.filterOptions.queryParamOutbound
                                    )
                                : null
                            }
                          >
                            <Typography className="placehoder-text">
                              {filter?.displayName === "Status" &&
                              statusLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.status
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {statusLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Status" &&
                                `${t("SELECT")} ${t("STATUS")}`
                              )}

                              {/* {filter.displayName === "Amount" &&
                                `${t("SELECT")}  ${t("AMOUNT")}`}
                              {filter.displayName === "Merchant Reference Id" &&
                                `${t("SELECT")}  ${t("AMOUNT")}`}
                              {filter.displayName === "Processor Recon Id" &&
                                `${t("SELECT")}  ${t("processorReconId")}`}
                              {filter.displayName === "Processor Id" &&
                                `${t("SELECT")}  ${t("processorReconId")}`} */}

                              {filter?.displayName === "Payment Type" &&
                              paymentMethodLength > 0 ? (
                                <div style={{ display: "flex" }}>
                                  {filters?.paymentMethod && (
                                    <div key={filters.paymentMethod}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0px 5px",
                                          marginRight: "5px",
                                        }}
                                        className="chip-background"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "10px",
                                            color: "black",
                                          }}
                                        >
                                          {filters.paymentMethod}
                                        </Typography>
                                        <IconButton
                                          onClick={(e) => {
                                            handleFilterRadioChange(
                                              filter.filterOptions.queryParam,
                                              ""
                                            );
                                            e.stopPropagation();
                                          }}
                                        >
                                          <XCircleIcon
                                            sx={{ fontSize: "15px" }}
                                          />
                                        </IconButton>
                                      </Box>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                filter?.displayName === "Payment Type" &&
                                `${t("SELECT")}  ${t("PAYMENT_TYPE")}`
                              )}

                              {filter?.displayName === "Currency Code" &&
                              currencyLength > 0 ? (
                                <div style={{ display: "flex" }}>
                                  {filters?.currency && (
                                    <div key={filters.currency}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0px 5px",
                                          marginRight: "5px",
                                        }}
                                        className="chip-background"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "10px",
                                            color: "black",
                                          }}
                                        >
                                          {filters.currency}
                                        </Typography>
                                        <IconButton
                                          onClick={(e) => {
                                            handleFilterRadioChange(
                                              filter.filterOptions.queryParam,
                                              ""
                                            );
                                            e.stopPropagation();
                                          }}
                                        >
                                          <XCircleIcon
                                            sx={{ fontSize: "15px" }}
                                          />
                                        </IconButton>
                                      </Box>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                filter?.displayName === "Currency Code" &&
                                `${t("SELECT")}  ${t("currencyCode")}`
                              )}

                              {filter?.displayName === "Processor Code" &&
                              processorCodeLength > 0 ? (
                                <div style={{ display: "flex" }}>
                                  {filters?.processorCode && (
                                    <div key={filters.processorCode}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0px 5px",
                                          marginRight: "5px",
                                        }}
                                        className="chip-background"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "10px",
                                            color: "black",
                                          }}
                                        >
                                          {filters.processorCode}
                                        </Typography>
                                        <IconButton
                                          onClick={(e) => {
                                            handleFilterRadioChange(
                                              filter.filterOptions.queryParam,
                                              ""
                                            );
                                            e.stopPropagation();
                                          }}
                                        >
                                          <XCircleIcon
                                            sx={{ fontSize: "15px" }}
                                          />
                                        </IconButton>
                                      </Box>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                filter?.displayName === "Processor Code" &&
                                // `Select  ${filter.displayName}`
                                `${t("SELECT")}  ${t("processorCode")}`
                              )}

                              {filter?.displayName === "Transaction Type" &&
                              transactionTypeLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.transactionType
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            key={index}
                                            className="chip-background"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {transactionTypeLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Transaction Type" &&
                                // `Select  ${filter.displayName}`
                                `${t("SELECT")} ${t("transactionType")}`
                              )}
                            </Typography>
                            {Boolean(anchorEl && anchorEl.index === index) ? (
                              <ExpandLessIcon className="cart-down-icon" />
                            ) : (
                              <CaretDown className="cart-down-icon" />
                            )}
                          </div>
                        </Box>

                        <Menu
                          className="pop-up-alignment"
                          anchorEl={
                            anchorEl && anchorEl.index === index
                              ? anchorEl.currentTarget
                              : null
                          }
                          open={Boolean(anchorEl && anchorEl.index === index)}
                          onClose={() => {
                            setAnchorEl(null);
                            setClickedChipIndex(null);
                            setFromButtonStatus(false);
                            setToButtonStatus(false);
                          }}
                        >
                          {filter.filterOptions.type === "INTEGER" && (
                            <div>
                              <MenuItem className="drop-down-menu-item">
                                <TextField
                                  key=""
                                  size="small"
                                  type="number"
                                  label="From"
                                  placeholder="From"
                                  fullWidth
                                  value={filters.amount_from}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParamOutbound,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                              <MenuItem>
                                <TextField
                                  key=""
                                  size="small"
                                  type="number"
                                  fullWidth
                                  label="To"
                                  placeholder="To"
                                  value={filters.amount_to}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParamInbound,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            </div>
                          )}

                          {filter.filterOptions.type === "ENUM" && (
                            <MenuItem className={paymentTypePopupHeight}>
                              <TextField
                                className="dropdown-search-field"
                                type="search"
                                size="small"
                                label="Search"
                                value={searchTerm}
                                fullWidth
                                onChange={(e) => {
                                  setSearchTerm(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                              />

                              <FormGroup>
                                {filter.filterOptions.options
                                  .filter((option) =>
                                    option
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  )
                                  .map((option) => {
                                    const isSelected =
                                      filters[
                                        filter.filterOptions.queryParam
                                      ] === option;

                                    return (
                                      <FormControlLabel
                                        key={option}
                                        control={
                                          <Radio
                                            checked={isSelected}
                                            onChange={(e) => {
                                              if (!isSelected) {
                                                handleFilterRadioChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  option
                                                );
                                              }
                                              e.stopPropagation();
                                            }}
                                            value={option}
                                          />
                                        }
                                        label={option}
                                      />
                                    );
                                  })}
                              </FormGroup>
                            </MenuItem>
                          )}
                          {filter.filterOptions.type !== "INTEGER" &&
                            filter.filterOptions.type !== "ENUM" &&
                            filter.filterOptions.type !== "DATE" && (
                              <MenuItem className="drop-down-menu-item">
                                <TextField
                                  size="small"
                                  type="search"
                                  fullWidth
                                  label={filter.displayName}
                                  placeholder={`Enter ${filter.displayName}`}
                                  value={
                                    filters[filter.filterOptions.queryParam]
                                  }
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParam,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            )}
                          <MenuItem
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            {filter.filterOptions.type === "STRING" ? (
                              ""
                            ) : (
                              <>
                                <Button
                                  size="small"
                                  className="cancelBtn btn btn-sm btn-light-primary"
                                  onClick={() => {
                                    if (filter.displayName === "Date") {
                                      delete filters.transactionTime_from;
                                      delete filters.transactionTime_to;

                                      handleClose();
                                    } else if (
                                      filter.displayName === "Amount"
                                    ) {
                                      setAmount(false);
                                      delete filters.amount_from;
                                      delete filters.amount_to;
                                      handleClose();
                                    } else if (filter.displayName !== "Date") {
                                      resetFilters(
                                        filter.filterOptions.queryParam
                                      );
                                      setFromButtonStatus(false);
                                      setToButtonStatus(false);
                                      setDateLabel(false);
                                      setGetDate(false);
                                      handleClose();

                                      filter.displayName === "Processor Id" &&
                                        setProcessorId(false);
                                    }
                                  }}
                                >
                                  {t("CANCEL")}
                                </Button>
                                <Button
                                  size="small"
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    if (twoDatesValidation) {
                                      applyfilters(filter);
                                      setFromButtonStatus(false);
                                      setToButtonStatus(false);

                                      reloadFilters();
                                      filter.displayName ===
                                        "Merchant Reference Id" &&
                                        setMerchantReferenceId(true);

                                      filter.displayName ===
                                        "Processor Recon Id" &&
                                        setProcessorReconId(true);
                                      filter.displayName === "Processor Id" &&
                                        setProcessorId(true);
                                      setSelectCalenderDate(true);
                                    } else if (filter.displayName !== "Date") {
                                      applyfilters(filter);
                                      setFromButtonStatus(false);
                                      setToButtonStatus(false);

                                      filter.displayName ===
                                        "Merchant Reference Id" &&
                                        setMerchantReferenceId(true);

                                      filter.displayName ===
                                        "Processor Recon Id" &&
                                        setProcessorReconId(true);
                                      filter.displayName === "Processor Id" &&
                                        setProcessorId(true);
                                    }
                                  }}
                                >
                                  {t("OK")}
                                </Button>
                              </>
                            )}
                          </MenuItem>
                        </Menu>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                  className="apply-cancel-button-container"
                >
                  <Box>
                    <Tooltip title="Cancel">
                      <Button
                        variant="outlined"
                        className="btn btn-light-primary me-3 mb-2"
                        startIcon={<XCircleIcon />}
                        onClick={() => {
                          setFilters(filters);
                          setSelectedOptionsMap([]);
                          fetchTransactionData();
                          setGetDate(false);
                          setDateLabel(false);
                          setDropdownFilters(!dropdownFilters);
                        }}
                      >
                        {t("CANCEL")}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Apply">
                      <Button
                        variant="contained"
                        className="btn btn-primary mb-2"
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          reloadFilters();
                        }}
                      >
                        {t("APPLY")}
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </div>
            </div>
          )}
        </div>

        <div>
          {isLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : analyticsData.paymentMethod?.length > 0 ||
            analyticsData.processorCode?.length > 0 ||
            analyticsData.currency?.length > 0 ? (
            <div className="responsive-grid" key={forceRender}>
              {sections.map(({ title, component }, index) => (
                <div className="card rule-box">
                  <div
                    key={index}
                    className={`card-header card-header-stretch`}
                  >
                    <h3 className="card-title fw-bold m-0 text-gray-800">
                      {title}
                    </h3>
                  </div>
                  <div className="chart-container">
                    {Array.isArray(component) ? (
                      component.map((comp, compIndex) => (
                        <div key={compIndex}>{comp}</div>
                      ))
                    ) : (
                      <div>{component}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            "No data available for the selected period"
          )}
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
