import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";
console.log("Loaded Environment", process.env.REACT_APP_NODE_ENV);
const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    merchantId: null,
    merchantName: null,
    transactCheckedColumns: null,
    environment: process.env.REACT_APP_NODE_ENV,
    previousEnvironment: process.env.REACT_APP_NODE_ENV,
    isKeyCloakTokenExchanged: null,
  },
  reducers: {
    setMerchantId: (state, action) => {
      state.merchantId = action.payload;
    },
    setMerchantName: (state, action) => {
      state.merchantName = action.payload;
    },
    setTransactCheckedColumns: (state, action) => {
      state.transactCheckedColumns = action.payload;
    },
    setEnvironment: (state, action) => {
      state.environment = action.payload;
    },
    setPreviousEnvironment: (state, action) => {
      state.previousEnvironment = action.payload;
    },
    toggleTestMode: (state) => {
      console.log("Current Environment:", state.environment);
      console.log("Prev Environment:", state.previousEnvironment);

      if (state.environment === "test") {
        state.environment = "development";
        state.previousEnvironment = "test";
      } else {
        state.previousEnvironment = "development";
        state.environment = "test";
      }
      console.log("Toggled Environment", state.environment);
    },
    setIsKeyCloakTokenExchanged: (state, action) => {
      state.isKeyCloakTokenExchanged = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMerchantId,
  setMerchantName,
  setTransactCheckedColumns,
  setEnvironment,
  setPreviousEnvironment,
  toggleTestMode,
  setIsKeyCloakTokenExchanged,
} = merchantSlice.actions;

export default merchantSlice.reducer;
