import React, { useState, useEffect } from "react";
import { httpClient } from "utils/HttpClient";
import { URL, GOOGLEPAY_SETTINGS_ENDPOINT } from "constants/Constants";
import Logout from "Logout";
import { TextField, Grid, Typography, Box, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "../../Icons/FloppyDisk";
import "./GooglePaySettings.css";
import { SettingsEthernet } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useCustomSnackbar from "components/SnackBar/CustomSnackBar";
import { useSelector } from "react-redux";

const GooglePaySettings = () => {
  const isKeyCloakTokenExchanged = useSelector(
    (state) => state.merchant.isKeyCloakTokenExchanged
  );
  const [googlePaySettings, setGooglePaySettings] = useState({
    merchantName: "",
    merchantId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [merchantNameError, setMerchantNameError] = useState(true);
  const [merchantIdError, setMerchantIdError] = useState(true);
  const { t } = useTranslation();
  const { showCustomSnackbar } = useCustomSnackbar();

  useEffect(() => {
    fetchGooglePayEventsData();
  }, [isKeyCloakTokenExchanged]);

  const fetchGooglePayEventsData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${GOOGLEPAY_SETTINGS_ENDPOINT}`,
        "GET"
      );
      setGooglePaySettings(data?.googlePaySettings);
      //console.log(data, "GooglePay data");
    } catch (error) {
      console.error("Error fetching Google Pay data:", error);
    }
  };

  const handleSave = async () => {
    if (googlePaySettings?.merchantName === "") {
      setMerchantNameError(false);
    }
    if (googlePaySettings?.merchantId === "") {
      setMerchantIdError(false);
    }

    console.log("Save Button");
    if (
      googlePaySettings?.merchantName?.length &&
      googlePaySettings?.merchantId?.length
    ) {
      try {
        setIsLoading(true);
        const response = await httpClient.fetchData(
          `${URL}${GOOGLEPAY_SETTINGS_ENDPOINT}`,
          "POST",
          { googlePaySettings }
        );
        showCustomSnackbar(
          "success",
          `Google Pay Settings have been saved successfully.`,
          "right",
          "top",
          3000
        );
        if (response.ok) {
          console.log("post created success");
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            console.log("Failed saving data:", response.status);
          }
        }
      } catch (error) {
        console.error("Error saving webhook data:", error);
        showCustomSnackbar(
          "error",
          `An error occurred, please try again.`,
          "right",
          "top",
          3000
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setMerchantNameError(false);
      setMerchantIdError(false);
    }
  };

  return (
    <div className="googlepay-setting-main-container">
      <Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="google-pay-settings-text">
            {t("GOOGLE_PAY_SETTINGS")}
          </Typography>
          <Grid>
            <Tooltip title="Save">  
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={handleSave}
              // className="save-btn-text"
              className="btn btn-primary"
              startIcon={<SaveIcon />}
            >
              {t("SAVE")}
            </LoadingButton>
            </Tooltip>
          </Grid>
        </Box>
        <Grid>
          <Grid className="merchant-name-row-container">
            <Grid className="merchant-name-container">
              <Typography className="merchant-name">
                {t("GOOGLE_PAY_MERCHANT_NAME")}
              </Typography>
            </Grid>
            <Box
              className="w-100"
              // sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {/* <Grid className="merchant-name-input-field-container"> */}
              <Grid>
                <input
                  value={googlePaySettings?.merchantName}
                  variant="outlined"
                  // sx={{
                  //   "& fieldset": { border: "none" },
                  // }}
                  // size="small"
                  // className="merchant-name-input-field"
                  // className="form-control"
                  onChange={(e) => {
                    setGooglePaySettings((prev) => {
                      return { ...prev, merchantName: e.target.value };
                    });
                  }}
                  placeholder={t("ADD_MERCHANT_NAME")}
                />
              </Grid>
              <p
                className={
                  merchantNameError
                    ? "hide-text"
                    : googlePaySettings?.merchantName?.length > 0
                    ? "hide-text"
                    : "error-message"
                }
              >
                {t("MERCHANT_NAME_IS_REQUIRED")}
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid>
          <Grid className="merchant-name-row-container">
            <Grid className="merchant-name-container">
              <Typography className="merchant-name">
                {t("GOOGLE_PAY_MERCHANT_ID")}
              </Typography>
            </Grid>
            <Box
              className="w-100"
              // sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {/* <Grid className="merchant-name-input-field-container"> */}
              <Grid>
                <input
                  value={googlePaySettings?.merchantId}
                  fullWidth
                  // sx={{
                  //   "& fieldset": { border: "none" },
                  // }}
                  size="small"
                  placeholder={t("ADD_MERCHANT_ID")}
                  className="merchant-name-input-field"
                  onChange={(e) => {
                    setGooglePaySettings((prev) => {
                      return { ...prev, merchantId: e.target.value };
                    });
                  }}
                />
              </Grid>
              <p
                className={
                  merchantIdError
                    ? "hide-text"
                    : googlePaySettings?.merchantId?.length > 0
                    ? "hide-text"
                    : "error-message"
                }
              >
                {t("MERCHANT_ID_IS_REQUIRED")}
              </p>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            // loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
            className="save-btn-text"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default GooglePaySettings;
