const paymentTypeIcons = {
  CARDS: "/logos/card.jpg",
  BANK_TRANSFER: "/logos/va.png",
  GO_PAY: "/logos/GoPay-Logo.jpg",
  GOOGLE_PAY: "/logos/GoPay-Logo.jpg",
  SHOPEEPAY: "/logos/shopeepay-logo.svg",
  QRIS: "/logos/qris.svg",
  INDOMARET: "/logos/indomaret_new_png.png",
  ALFAMART: "/logos/alfamart-logo.svg",
  AKULAKU: "/logos/akulaku-logo.svg",
  KREDIVO: "/logos/kredivo.svg",
  OVO: "/logos/ovo-logo.svg",
  DANA: "/logos/dana-logo.svg",
  ALIPAY: "/logos/alipay-svgrepo-com.svg",
  PAYPAL: "/logos/paypal.svg",
  MANDIRI_CLICKPAY: "icons/mandiri-clickpay-icon.png",
  NEXCASH: "/logos/nexcash-logo.svg",
  ASTRAPAY: "/logos/astrapay-logo.svg",
  LINKAJA: "/logos/linkaja-logo.svg",
  JENIUSPAY: "/logos/jeniuspay.svg",
  UANGME: "/logos/uangme.jpg",
  INDODANA: "/logos/indodana.jpg",
  ATOME: "/logos/Atome.jpg",
  DOKU: "logos/DOKU.svg",
};

export default paymentTypeIcons;
