// Data for regions
export const regions = [
  "Africa, Sub-Saharan",
  "Canada & U.S.",
  "East Asia",
  "Europe",
  "South America",
  "South & Central Asia",
  "Southeast Asia & Oceania",
  "Middle East & North Africa",
  "North America",
];

// Data for countries (region-wise)
export const countries = {
  "Africa, Sub-Saharan": [
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cameroon",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Congo",
    "Cote D'Ivoire",
    "Djibouti",
    "Equatorial Guinea",
    "Eritrea",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Bissau",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "Sao Tome and Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Swaziland",
    "Tanzania",
    "Togo",
    "Uganda",
    "Zambia",
    "Zimbabwe",
  ],
  "Canada & U.S.": ["Canada", "United States"],
  "East Asia": [
    "China",
    "Hong Kong",
    "Japan",
    "South Korea",
    "North Korea",
    "Macau",
    "Mongolia",
    "Taiwan",
  ],
  "North America": [
    "Cayman Islands",
    "Martinique",
    "Montserrat",
    "Turks and Caicos",
    "Virgin Islands",
  ],
  "Caribbean, Central America & Mexico": [
    "Anguilla",
    "Antigua and Barbuda",
    "Aruba",
    "Bahamas",
    "Barbados",
    "Belize",
    "Bermuda",
    "Costa Rica",
    "Cuba",
    "Dominican Republic",
    "El Salvador",
    "Grenada",
    "Guatemala",
    "Haiti",
    "Honduras",
    "Jamaica",
    "Mexico",
    "Nicaragua",
    "Panama",
    "Puerto Rico",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Saint Kitts and Nevis",
    "Trinidad and Tobago",
  ],
  Europe: [
    "Albania",
    "Andorra",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    // "Kazakhstan",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russian Federation",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ],
  "South America": [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Guyana",
    "Paraguay",
    "Peru",
    "Suriname",
    "Uruguay",
    "Venezuela",
  ],
  "Middle East & North Africa": [
    "Algeria",
    "Bahrain",
    "Egypt",
    "Iran",
    "Iraq",
    "Israel",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Libya",
    "Morocco",
    "Oman",
    "Palestine",
    "Qatar",
    "Saudi Arabia",
    "Syria",
    "Tunisia",
    "United Arab Emirates",
    "Yemen",
  ],
  "South & Central Asia": [
    "Afghanistan",
    "Bangladesh",
    "Bhutan",
    "India",
    "Kazakhstan",
    "Kyrgyzstan",
    "Maldives",
    "Nepal",
    "Pakistan ",
    "Sri Lanka",
    "Tajikistan",
    "Turkmenistan",
    "Uzbekistan",
  ],
  "Southeast Asia & Oceania": [
    "Australia",
    "brunei darussalam",
    "Cambodia",
    "Timor-Leste",
    "Fiji",
    "French Polynesia",
    "Indonesia",
    "Kiribati",
    "Laos",
    "Malaysia",
    "Marshall Islands",
    "Micronesia ",
    "Myanmar",
    "Nauru",
    "New Zealand",
    "Palau",
    "Papua New Guinea",
    "Philippines",
    "Samoa",
    "Singapore",
    "Solomon Islands",
    "Thailand",
    "Tonga",
    "Tuvalu",
    "Vanuatu",
    "Vietnam",
  ],
};

// Data for currencies
export const currencies = [
  "IDR",
  "USD",
  "SAR",
  "AED",
  "ARS",
  "EUR",
  "GBP",
  "AUD",
  "CAD",
  "JPY",
  "CNY",
  "BRL",
];

//Curriencies map

export const countrycurrencies = {
  AED: ["United Arab Emirates"],
  AFN: ["Afghanistan"],
  ALL: ["Albania"],
  AMD: ["Armenia"],
  AOA: ["Angola"],
  ARS: ["Argentina"],
  AUD: ["Australia", "Kiribati", "Nauru", "Tuvalu"],
  AWG: ["Aruba"],
  AZN: ["Azerbaijan"],

  BAM: ["Bosnia and Herzegovina"],
  BBD: ["Barbados"],
  BDT: ["Bangladesh"],
  BGN: ["Bulgaria"],
  BHD: ["Bahrain"],
  BIF: ["Burundi"],
  BMD: ["Bermuda"],
  BND: ["brunei darussalam", "Singapore"],
  BOB: ["Bolivia"],
  BRL: ["Brazil"],
  BSD: ["Bahamas"],
  BTN: ["Bhutan"],
  BWP: ["Botswana"],
  BYN: ["Belarus"],
  BZD: ["Belize"],

  CAD: ["Canada"],
  CDF: ["Congo"],
  CHF: ["Liechtenstein", "Switzerland"],
  CLP: ["Chile"],
  CNY: ["China"],
  COP: ["Colombia"],
  CRC: ["Costa Rica"],
  CUP: ["Cuba"],
  CVE: ["Cape Verde"],
  CZK: ["Czech Republic"],

  DJF: ["Djibouti"],
  DKK: ["Denmark"],
  DOP: ["Dominican Republic"],
  DZD: ["Algeria"],

  EGP: ["Egypt", "Palestine"],
  ERN: ["Eritrea"],
  ETB: ["Ethiopia"],
  EUR: [
    "Andorra",
    "Austria",
    "Belgium",
    "Croatia",
    "Cyprus",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Martinique",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "Portugal",
    "San Marino",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Vatican City",
  ],

  FJD: ["Fiji"],

  GBP: ["United Kingdom"],
  GEL: ["Georgia"],
  GHS: ["Ghana"],
  GMD: ["Gambia"],
  GNF: ["Guinea"],
  GTQ: ["Guatemala"],
  GYD: ["Guyana"],

  HKD: ["Hong Kong"],
  HNL: ["Honduras"],
  HTG: ["Haiti"],
  HUF: ["Hungary"],

  IDR: ["Indonesia"],
  ILS: ["Israel", "Palestine"],
  INR: ["Bhutan", "India"],
  IQD: ["Iraq"],
  IRR: ["Iran"],
  ISK: ["Iceland"],

  JMD: ["Jamaica"],
  JOD: ["Jordan", "Palestine"],
  JPY: ["Japan"],

  KES: ["Kenya"],
  KGS: ["Kyrgyzstan"],
  KHR: ["Cambodia"],
  KMF: ["Comoros"],
  KPW: ["North Korea"],
  KRW: ["South Korea"],
  KWD: ["Kuwait"],
  KYD: ["Cayman Islands"],
  KZT: ["Kazakhstan"],

  LAK: ["Laos"],
  LBP: ["Lebanon"],
  LKR: ["Sri Lanka"],
  LRD: ["Liberia"],
  LSL: ["Lesotho"],
  LYD: ["Libya"],

  MAD: ["Morocco"],
  MDL: ["Moldova"],
  MGA: ["Madagascar"],
  MKD: ["Macedonia"],
  MMK: ["Myanmar"],
  MNT: ["Mongolia"],
  MOP: ["Macau"],
  MRU: ["Mauritania"],
  MUR: ["Mauritius"],
  MVR: ["Maldives"],
  MWK: ["Malawi"],
  MXN: ["Mexico"],
  MYR: ["Malaysia"],
  MZN: ["Mozambique"],

  NAD: ["Namibia"],
  NGN: ["Nigeria"],
  NIO: ["Nicaragua"],
  NOK: ["Norway"],
  NPR: ["Nepal"],
  NZD: ["New Zealand"],

  OMR: ["Oman"],

  PAB: ["Panama"],
  PEN: ["Peru"],
  PGK: ["Papua New Guinea"],
  PHP: ["Philippines"],
  PKR: ["Pakistan "],
  PLN: ["Poland"],
  PYG: ["Paraguay"],

  QAR: ["Qatar"],

  RON: ["Romania"],
  RSD: ["Serbia"],
  RUB: ["Russian Federation"],
  RWF: ["Rwanda"],

  SAR: ["Saudi Arabia"],
  SBD: ["Solomon Islands"],
  SCR: ["Seychelles"],
  SDG: ["Sudan"],
  SEK: ["Sweden"],
  SGD: ["brunei darussalam", "Singapore"],
  SOS: [" Somalia"],
  SRD: ["Suriname"],
  SSP: ["South Sudan"],
  STN: ["Sao Tome and Principe"],
  SYP: ["Syria"],
  SZL: ["Swaziland"],

  THB: ["Thailand"],
  TJS: ["Tajikistan"],
  TMT: ["Turkmenistan"],
  TND: ["Tunisia"],
  TOP: ["Tonga"],
  TRY: ["Turkey"],
  TTD: ["Trinidad and Tobago"],
  TWD: ["Taiwan"],
  TZS: ["Tanzania"],

  UAH: ["Ukraine"],
  UGX: ["Uganda"],
  USD: [
    "Ecuador",
    "El Salvador",
    "Marshall Islands",
    "Micronesia",
    "Palau",
    "Panama",
    "Puerto Rico",
    "Timor-Leste",
    "Turks and Caicos",
    "United States",
    "Virgin Islands",
  ],
  UYU: ["Uruguay"],
  UZS: ["Uzbekistan"],

  VED: ["Venezuela"],
  VES: ["Venezuela"],
  VND: ["Vietnam"],
  VUV: ["Vanuatu"],

  WST: ["Samoa"],

  XAF: [
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Congo",
    "Equatorial Guinea",
    "Gabon",
  ],
  XCD: [
    "Anguilla",
    "Antigua and Barbuda",
    "Grenada",
    "Montserrat",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
  ],
  XOF: [
    "Benin",
    "Bissau",
    "Burkina Faso",
    "Cote D'Ivoire",
    "Mali",
    "Niger",
    "Senegal",
    "Togo",
  ],
  XPF: ["French Polynesia"],

  YER: ["Yemen"],

  ZAR: ["Lesotho", "Namibia", "South Africa"],
  ZMW: ["Zambia"],
  ZWL: ["Zimbabwe"],
};
// Data for payment types
export const paymentTypes = [
  "BANK_TRANSFER",
  "CARDS",
  "QRIS",
  "OVO",
  "GO_PAY",
  "DANA",
  "DOKU",
  "ALFAMART",
  "INDOMARET",
  "MANDIRI_CLICKPAY",
  "ALIPAY",
  "SHOPEEPAY",
  "SAKUKU",
  "NEXCASH",
  "AKULAKU",
  "KREDIVO",
  "LINKAJA",
  "ASTRAPAY",
  "JENIUSPAY",
  "UANGME",
  "INDODANA",
  "ATOME",
];
