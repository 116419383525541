import React from 'react';
import useCustomSnackbar from 'components/SnackBar/CustomSnackBar';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const Excel = ({headers,items,isRangeValid,isDisplayColumnChanged,isDownloadProgess,isReadyForDownload,isEmptyData})=>{

  const { showCustomSnackbar  } = useCustomSnackbar();
  
  dayjs.extend(utc);
  const handleDownload = async () => {
    if(isRangeValid){
      showCustomSnackbar("error", 'Please select a date range within 90 days', "center", "top", 2000);
      return;
    } 
    else if ((!items || items.length === 0) && (isReadyForDownload||isEmptyData)) {
      showCustomSnackbar("error", 'No Data found to download', "center", "top", 2000);
      return;
    }
    else if(isDownloadProgess){
      showCustomSnackbar("success",'Download is in Progress',"center","top",400);
    }
  
    try {
      
      let csvContent = headers.map(header => header.name).join(',') + '\n';
      items.forEach(row => {
        csvContent += Object.values(row).join(',') + '\n';
      });    
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const date = dayjs().local();
      const timestamp = date.format('YYYY-MM-DD_HH-mm-ss');
      const filename = `data_${timestamp}.csv`;
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      setTimeout(() => {
        showCustomSnackbar("success", "Download completed successfully", "center", "top", 2000);
      }, 1000);
    } catch (error) {
     console.log(error);
    }
  };
  

  return (
    <>
      <div className="d-flex justify-content-end" onClick={() => !isDisplayColumnChanged && handleDownload()}  disabled={!items || items.length === 0}>
          CSV
      </div>
    </>
  );
}

export default Excel;
