import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const showCustomSnackbar = (
    variant,
    message,
    horizontalPosition,
    verticalPosition,
    hideTimer
  ) => {
    console.log("showCustomSnackbar called with arguments:", {
      variant,
      message,
      horizontalPosition,
      verticalPosition,
      hideTimer,
    });

    enqueueSnackbar(`${message}`, {
      autoHideDuration: hideTimer,
      anchorOrigin: {
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      },
      variant: variant,
      action: (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => closeSnackbar()}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ),
    });
  };

  return { showCustomSnackbar };
};

export default useCustomSnackbar;
