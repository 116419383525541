import RulesSettingCard from "components/Elements/RulesSettingCard/RulesSettingCard";
import {
  URL,
  RULES_ENDPOINT,
  // PROCESSORLIST_ENDPOINT,
} from "constants/Constants"; // MERCHANT_ID
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient } from "utils/HttpClient";
import { ProcessorServiceProvider } from "components/Elements/RulesCondition/ProcessorFunctionProvider";
import { SnackbarProvider } from "notistack";
function Rules() {
  const isKeyCloakTokenExchanged = useSelector(
    (state) => state.merchant.isKeyCloakTokenExchanged
  );
  const [rules, setRules] = useState([]);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  console.log(rules, "rules");

  useEffect(() => {
    fetchTransactionData();
    //  fetchProcessorData();
  }, [isKeyCloakTokenExchanged]);

  const fetchTransactionData = () => {
    //console.log("raiden-33", data);
    // setIsLoading(true);

    httpClient.fetchData(`${URL}${RULES_ENDPOINT}`, "GET").then((data) => {
      console.log(data, "saved Rules Data");
      setRules(data);
      setData(data);
      setIsLoading(false);
      // setTableHeadings(Object.keys(data[0]));
    });
  };
  const onDataDeleteChange = (updatedData, idx) => {
    let ob = data || [];
    ob[idx] = updatedData;
    setRules((prev) => [...ob]);
  };

  return (
    <ProcessorServiceProvider>
      <SnackbarProvider maxSnack={5}>
        <>
          {isLoading ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // padding: "200px",
              }}
            >
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="">
              {(rules || []).length === 0 ? (
                <RulesSettingCard
                  index={0}
                  data={{}}
                  onDataDeleteChange={onDataDeleteChange}
                  fetchData={fetchTransactionData}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <div className="">
                  {rules?.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <RulesSettingCard
                          key={index}
                          index={index}
                          arrayData={rules}
                          data={item}
                          onDataDeleteChange={onDataDeleteChange}
                          fetchData={fetchTransactionData}
                          setIsLoading={setIsLoading}
                          setRules={setRules}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </>
      </SnackbarProvider>
    </ProcessorServiceProvider>
  );
}

export default Rules;
