import { useEffect, useState } from "react";
import "./Breadcrumb.css";
import { useTranslation } from "react-i18next";
import ProfileMenu from "components/Profile-Menu/ProfileMenu";
import { useSelector, useDispatch } from "react-redux";
import { toggleTestMode } from "../../redux/reducers/merchant";
import { setChangeInEnvironment } from "constants/Constants";
import Alert from "@mui/material/Alert";

const Breadcrumb = ({ list, onClick }) => {
  const { t, i18n } = useTranslation();
  const environment = useSelector((state) => state.merchant.environment);
  // const previousEnvironment=useSelector((state)=>state.merchant.previousEnvironment);
  const dispatch = useDispatch();
  // const [environment, setEnvironment] = useState(
  //   process.env.REACT_APP_NODE_ENV
  // ); // or "prod"
  // const [previousEnv, setPreviousEnv] = useState(
  //   process.env.REACT_APP_NODE_ENV
  // ); // default starting env

  useEffect(() => {
    setChangeInEnvironment(environment);
  }, [environment]);
  const handleTestToggle = () => {
    dispatch(toggleTestMode());
    // console.log(environment, "After Test Toggle");
  };

  // Handle language change from the dropdown
  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };
  return (
    <>
      {/* Breadcrumb Section */}
      {list.map((item) => (
        <div className="containers">
          <div className="breadcrumb-item">
            {item.src && (
              <img
                loading="lazy"
                src={item.src}
                className="breadcrumb-img"
                onClick={onClick}
              />
            )}

            <div className="breadcrumb-img-text-container">
              {item.component && (
                <div className="breadcrumb-component" onClick={onClick}>
                  {item.component}
                </div>
              )}
              <div className="breadcrumb-title" onClick={onClick}>
                {item.title}
              </div>
            </div>
          </div>

          <div className="toggle-menu-container">
            {/* {environment === "test"&& <Alert icon={false} severity="warning">You are in the Test Environment</Alert>} */}
            <div
              className={`breadcrumb-title ${
                environment === "test" ? "test-toggle-color" : ""
              }`}
            >
              Test Mode
            </div>
            <div
              onClick={() => {
                // console.log(environment, "Before Test Toggle");
                // dispatch(toggleTestMode());
                handleTestToggle();
              }}
            >
              <input
                className={`toggle-switch ${
                  environment !== "test" ? "toggle-grey" : ""
                }`}
                type="checkbox"
                checked={environment === "test"}
                readOnly
              />
            </div>
            <ProfileMenu />
          </div>
        </div>
      ))}
    </>
  );
};

export default Breadcrumb;
