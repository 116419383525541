import React, { useEffect, useState } from "react";
import "./RulesSettingCard.css";
import RulesCondition from "../RulesCondition/RulesCondition";
import {
  MenuItem,
  FormControl,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  IconButton,
  Menu,
  Select,
  Grid,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Tree from "../../Icons/Tree";
import { httpClient } from "utils/HttpClient";
import { URL, RULES_ENDPOINT } from "constants/Constants";
import { processors } from "utils/processors";
import { cloneDeep, get, isArray } from "lodash";
import Plus from "components/Icons/Plus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import { FlashAuto } from "@mui/icons-material";
import { useProcessorData } from "../RulesCondition/ProcessorFunctionProvider";
import { useTranslation } from "react-i18next";
import countriesName from "utils/CountryNameMap";
import useCustomSnackbar from "components/SnackBar/CustomSnackBar";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { set } from "js-cookie";
import { blue } from "@mui/material/colors";
import TestBanner from "components/TestBanner/TestBanner";

function RulesSettingCard(
  {
    data,
    onDataDeleteChange,
    index,
    fetchData,
    setIsLoading,
    arrayData,
    setRules,
    savedFirstRule,
    getProcessorData,
    setSelectParamBasedFilters,
  },
  props
) {
  console.log(data, "saved data in settings");
  const { t } = useTranslation();
  const { showCustomSnackbar } = useCustomSnackbar();
  const [showAddRule, setShowAddRule] = useState(false);
  const [error, setError] = useState({
    nameError: "",
    resultError: "",
    statusError: "",
  });
  const [conditionError, setConditionError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: t("RULES"), component: <Tree /> },
  ]);

  const [processorText, setProcessorText] = useState();
  const [processorButtonHide, setProcessorButtonHide] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getData, setGetData] = useState(getProcessorData);
  const [editRule, setEditRule] = useState(true);
  const [updateProcessorText, setUpdateProcessorText] = useState();
  const [changeProcessorText, setChangeProcessorText] = useState(false);
  const open = Boolean(anchorEl);
  const [processorAnchorEl, setProcessorAnchorEl] = useState(null);
  const [changeEditSaveFirstRule, setChangeEditSaveFirstRule] = useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [addProcessorErrorMsg, setAddProcessorErrorMsg] = useState(false);
  const [selectPaymentMethods, setSelectPaymentMethod] = useState();
  const [savedPaymentMethodsUpdate, setSavedPaymentMethodsUpdate] = useState();
  const [editSaveRule, setEditSaveRule] = useState();
  const [getEditRuleName, setGetEditRuleName] = useState();
  const [getRuleData, setGetRuleData] = useState();
  const [nestedPaymentMethods, setNestedPaymentMethods] = useState();
  const [connectionNameChanged, setconnectionNameChanged] = useState(false);

  const [duplicateProcessorButton, setDuplicateProcessorButton] =
    useState(false);
  const [allObjects, setAllObjects] = useState([]);

  // const [ruleStates, setRuleStates] = useState();
  // const [ruleData, setRuleData] = useState({});

  const [ruleStates, setRuleStates] = useState();
  const [ruleData, setRuleData] = useState({});

  // useEffect(() => {
  //   if (ruleStates) {
  //     setRuleData({
  //       status:
  //         ruleStates?.status?.toUpperCase() === "ACTIVE"
  //           ? "ACTIVE"
  //           : "DISABLED",
  //     });
  //   }
  // }, [ruleStates.status]);

  const handleText = (value) => {
    setProcessorText(value);
    handleClose();
    setProcessorButtonHide(true);
    setAddProcessorErrorMsg(false);
    setDuplicateProcessorButton(true);
  };

  // const [ruleStates, setRuleStates] = useState(() => {
  //   const initialState = {};
  //   arrayData?.forEach((rule) => {
  //     initialState[rule.id] = false;
  //   });
  //   return initialState;
  // });

  // console.log(ruleStates, "rulesStates");
  // console.log(processorButtonHide, "buttonHidden");
  // console.log(updateProcessorText, "update processor");
  // console.log(data, "getData");
  // console.log(processorText, "add processor");
  // console.log(selectPaymentMethods, "selected paymentMethods");
  // console.log(savedPaymentMethodsUpdate, "savedPaymentData update");
  console.log(ruleData, "ruleData");
  console.log(data.condition, "getData");
  console.log(allObjects, "all");
  // console.log(processorButtonHide, "hide");
  // console.log(duplicateProcessorButton, "dhide");
  // console.log(processorText, "text");

  const { processorData, setIsEditingData, configData } = useProcessorData();
  console.log(processorData, "in card");

  // console.log(getData, "getData");

  useEffect(() => {
    processorData?.map((eachItem) => {
      // if (eachItem.processorCode === processorText) {
      if (eachItem?.id === processorText?.id) {
        setSelectPaymentMethod(eachItem.paymentMethods);
      }
    });
  }, [processorText]);

  useEffect(() => {
    processorData?.map((eachItem) => {
      // if (eachItem.processorCode === updateProcessorText) {
      if (eachItem?.id === updateProcessorText?.id) {
        setSavedPaymentMethodsUpdate(
          eachItem.paymentMethods.sort((a, b) => a.localeCompare(b))
        );
      }
    });
  }, [updateProcessorText]);

  useEffect(() => {
    processorData?.map((eachItem) => {
      if (eachItem?.id === getEditRuleName?.id) {
        setSavedPaymentMethodsUpdate(
          eachItem.paymentMethods.sort((a, b) => a.localeCompare(b))
        );
      }
    });
  }, [getEditRuleName]);

  useEffect(() => {
    processorData?.map((eachItem) => {
      if (eachItem?.id === getRuleData?.id) {
        setNestedPaymentMethods(
          eachItem.paymentMethods.sort((a, b) => a.localeCompare(b))
        );
      }
    });
  }, [getRuleData]);

  // console.log(getRuleData, "getRuleData");

  console.log(
    selectPaymentMethods,
    "payment-methods check with",
    processorText || updateProcessorText
  );

  const setpriority = (type) => {
    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (validateAndUpdateConditions(validRule.condition)) {
      let finalObject = cloneDeep(validRule);
      console.log(finalObject, "final");
      deleteProperties(finalObject);
      console.log(finalObject, ruleData, "Rule");
      console.log(finalObject, "update-final-rule");

      if (type === "up") {
        finalObject.priority = finalObject.priority - 1;
      } else if (type === "down") {
        finalObject.priority = finalObject.priority + 1;
      } else if (type === "first") {
        finalObject.priority = 1;
      } else if (type === "last") {
        finalObject.priority = arrayData.length;
      }
      httpClient
        .fetchData(
          `${URL}${RULES_ENDPOINT}/${ruleData?.id}`,
          "PUT",
          finalObject
        )
        .then((res) => {
          console.log("Rule updated:", res);
          fetchData();
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error in updating rule:", error);
        });
    }
  };
  const handleMoveItem = (index, direction) => {
    const newArray = [...arrayData];
    const itemToMove = newArray[index];

    if (direction === "up" && index > 0) {
      newArray?.splice(index, 1);
      newArray?.splice(index - 1, 0, itemToMove);
      setRuleData((prevState) => ({
        ...prevState,
        priority: ruleData.priority - 1,
      }));
      setpriority("up");
    } else if (direction === "down" && index < newArray?.length - 1) {
      newArray?.splice(index, 1);
      newArray?.splice(index + 1, 0, itemToMove);
      setRuleData((prevState) => ({
        ...prevState,
        priority: ruleData.priority - 1,
      }));
      setpriority("down");
    }
    setRules(newArray);
  };

  const goToTop = () => {
    setpriority("first");
  };

  const goToBottom = () => {
    setpriority("last");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setRuleData({
      ...data,
    });
  }, [data]);

  const recurse = (obj, value) => {
    for (let i = 0; i < obj?.length; i++) {
      if (obj[i].hasOwnProperty("nestedOperation")) {
        recurse(obj[i].conditions, value);
      } else {
        if (value.id === obj[i].id) {
          obj[i] = value;
        }
      }
    }
  };

  const handleEditSaveClick = (obj) => {
    console.log(obj, "toggle");
    setChangeEditSaveFirstRule(true);
    setIsEditingData(true);
    setEditSaveRule(obj);
    console.log(obj, "Edit Object");
    // setUpdateProcessorText((prev) => ({
    //   ...prev,
    //   processorCode: obj?.result,
    // }));

    if (!isEditing) {
      const updatedObj = cloneDeep(obj);
      setIsEditing(true);
      if (
        updatedObj.condition.conditions &&
        isArray(updatedObj.condition.conditions)
      )
        setRuleData({ ...updatedObj });
      else {
        setRuleData({
          ...updatedObj,
          condition: {
            nestedOperation: "AND",
            conditions: [updatedObj.condition],
          },
        });
      }
      return;
    }
    setIsLoading(true);
    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (validateAndUpdateConditions(validRule.condition)) {
      let finalObject = cloneDeep(validRule);
      console.log(finalObject, "final");
      deleteProperties(finalObject);
      console.log(finalObject, ruleData, "Rule");
      if (finalObject?.condition?.conditions?.length === 1) {
        const updatedCondition = finalObject.condition.conditions[0];
        finalObject = {
          ...finalObject,
          condition: { ...updatedCondition },
        };
      }
      console.log(finalObject, "update-final-rule");
      // if (Array.isArray(finalObject?.condition?.conditions)) {
      //   finalObject.condition.conditions = finalObject.condition.conditions.map(
      //     (condition) => {
      //       if (
      //         [
      //           "shippingDetails.address.country",
      //           "billingAddress.country",
      //         ].includes(condition.param) &&
      //         typeof condition.value === "string"
      //       ) {
      //         condition.value = condition.value
      //           .split(",")
      //           .map(
      //             (country) =>
      //               Object.keys(countriesName).find(
      //                 (key) => countriesName[key] === country.trim()
      //               ) || country.trim()
      //           )
      //           .join(",");
      //       }
      //       return condition;
      //     }
      //   );
      // } else if (
      //   finalObject.condition &&
      //   typeof finalObject.condition === "object"
      // ) {
      //   if (
      //     [
      //       "shippingDetails.address.country",
      //       "billingAddress.country",
      //     ].includes(finalObject.condition.param) &&
      //     typeof finalObject.condition.value === "string"
      //   ) {
      //     finalObject.condition.value = finalObject.condition.value
      //       .split(",")
      //       .map(
      //         (country) =>
      //           Object.keys(countriesName).find(
      //             (key) => countriesName[key] === country.trim()
      //           ) || country.trim()
      //       )
      //       .join(",");
      //   }
      // }
      function updateCountryValues(condition) {
        if (!condition || typeof condition !== "object") return;

        if (Array.isArray(condition.conditions)) {
          condition.conditions.forEach(updateCountryValues);
        }

        if (
          [
            "shippingDetails.address.country",
            "billingAddress.country",
          ].includes(condition.param) &&
          typeof condition.value === "string"
        ) {
          condition.value = condition.value
            .split(",")
            .map(
              (country) =>
                Object.keys(countriesName).find(
                  (key) => countriesName[key] === country.trim()
                ) || country.trim()
            )
            .join(",");
        }
      }

      // Start the recursive update from the main object
      if (finalObject?.condition) {
        updateCountryValues(finalObject.condition);
      }

      httpClient
        .fetchData(
          `${URL}${RULES_ENDPOINT}/${ruleData?.id}`,
          "PUT",
          finalObject
        )
        .then((res) => {
          console.log("Rule updated:", res);
          fetchData();
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error in updating rule:", error);
        });
    }
  };

  const screenWidth = window.innerWidth;

  const handleAddClick = () => {
    setShowAddRule(true);
    // setIsEditing(true);
    setRuleData({
      id: new Date().getTime(),
      name: "",
      result: "",
      priority: 1,
      status: "ACTIVE",
      condition: {
        nestedOperation: "AND",
        conditions: [
          {
            id: new Date().getTime(),
            param: undefined,
            operation: undefined,
            value: undefined,
            isError: false,
            errorMessage: "",
          },
        ],
      },
    });
    setChangeEditSaveFirstRule(false);
  };

  const handleCancelClick = () => {
    setProcessorText([]);
    setShowAddRule(false);
    setIsEditing(false);
    setEditRule(true);
    setProcessorButtonHide(false);
    setAddProcessorErrorMsg(false);
    setError((prev) => {
      return { ...prev, nameError: "" };
    });
    setDuplicateProcessorButton(false);
  };

  function deleteProperties(obj) {
    // Define the properties to delete
    const propertiesToDelete = new Set(["isError", "errorMessage"]);

    // Recursive function to traverse and delete properties
    function traverseAndDelete(currentObj) {
      if (Array.isArray(currentObj)) {
        // If it's an array, loop through its elements
        currentObj.forEach((item) => traverseAndDelete(item));
      } else if (currentObj !== null && typeof currentObj === "object") {
        // If it's an object, loop through its properties
        Object.keys(currentObj).forEach((key) => {
          if (propertiesToDelete.has(key)) {
            // Delete the property if it's in the list
            delete currentObj[key];
          } else if (key === "id" && typeof currentObj[key] === "number") {
            // Delete the 'id' property only if its value is a number
            delete currentObj[key];
          } else {
            // Recursively call the function for nested objects
            traverseAndDelete(currentObj[key]);
          }
        });
      }
    }
    // Start the recursive deletion
    traverseAndDelete(obj);
  }

  const checkIfValidNameAndResult = (ruleData) => {
    const regexPattern = /^[A-Za-z0-9_-]+$/;
    if (ruleData.name.length === 0 && ruleData.result.length === 0) {
      //if (ruleData.name.length === 0 && ruleData.connectionName.length === 0) {
      setError({
        nameError: "Rule name should not be empty!",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else if (
      !regexPattern.test(ruleData.name) &&
      ruleData.result.length === 0
      //ruleData.connectionName.length === 0
    ) {
      setError({
        nameError: "Rule name should not contain special characters except _ -",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else if (ruleData.name.length === 0) {
      setError({ ...error, nameError: "Rule name should not be empty!" });
      return false;
    } else if (!regexPattern.test(ruleData.name)) {
      setError({
        ...error,
        nameError:
          "Rule name should not contain special characters except _ & -!",
      });
      return false;
    } else if (ruleData.result.length === 0) {
      //else if (ruleData.connectionName.length === 0) {
      setError({
        nameError: "",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else {
      return true;
    }
  };
  function validateAndUpdateConditions(condition) {
    // Helper function to check if a string is empty
    const isEmpty = (str) => !str || str.trim() === "";

    // Variable to keep track of the validation status
    let noErrors = true;

    // Recursive function to validate and update each condition
    const validateAndUpdate = (cond) => {
      if (cond.conditions && Array.isArray(cond.conditions)) {
        // If there are nested conditions, validate and update each one recursively
        cond.conditions.forEach(validateAndUpdate);
      } else {
        // Initialize errorMessage as an empty array
        cond.errorMessage = [];

        // Check for empty fields and set isError flag and errorMessage accordingly
        if (isEmpty(cond.param)) {
          cond.isError = true;
          cond.errorMessage.push("Param cannot be empty");
          noErrors = false;
        }
        if (isEmpty(cond.operation)) {
          cond.isError = true;
          cond.errorMessage.push("Operation cannot be empty");
          noErrors = false;
        }
        if (isEmpty(cond.value)) {
          cond.isError = true;
          cond.errorMessage.push("Value cannot be empty");
          noErrors = false;
        }

        // Join error messages if there are multiple
        if (cond.errorMessage.length > 0) {
          cond.errorMessage = cond.errorMessage.join("; ");
        } else {
          delete cond.errorMessage; // Remove errorMessage if there are no errors
        }
      }
    };

    // Start the validation and update process
    validateAndUpdate(condition);

    // Return true if no errors were found, false otherwise
    return noErrors;
  }

  const handleAddRule = () => {
    // setIsLoading(true);
    // setProcessorButtonHide(false);
    // setAddProcessorErrorMsg(true);
    if (!processorButtonHide) {
      setAddProcessorErrorMsg(true);
    }

    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (
      checkIfValidNameAndResult(ruleData) &&
      validateAndUpdateConditions(validRule.condition)
    ) {
      let finalObject = cloneDeep(validRule);
      deleteProperties(finalObject);
      if (finalObject?.condition?.conditions?.length === 1) {
        const updatedCondition = finalObject.condition.conditions[0];
        finalObject = {
          ...finalObject,
          condition: { ...updatedCondition },
        };
      }
      console.log(finalObject, "Before Processing");
      // if (Array.isArray(finalObject?.condition?.conditions)) {
      //   finalObject.condition.conditions = finalObject.condition.conditions.map(
      //     (condition) => {
      //       if (
      //         [
      //           "shippingDetails.address.country",
      //           "billingAddress.country",
      //         ].includes(condition.param) &&
      //         typeof condition.value === "string"
      //       ) {
      //         condition.value = condition.value
      //           .split(",")
      //           .map(
      //             (country) =>
      //               Object.keys(countriesName).find(
      //                 (key) => countriesName[key] === country.trim()
      //               ) || country.trim()
      //           )
      //           .join(",");
      //       }
      //       return condition;
      //     }
      //   );
      // } else if (
      //   finalObject.condition &&
      //   typeof finalObject.condition === "object"
      // ) {
      //   if (
      //     [
      //       "shippingDetails.address.country",
      //       "billingAddress.country",
      //     ].includes(finalObject.condition.param) &&
      //     typeof finalObject.condition.value === "string"
      //   ) {
      //     finalObject.condition.value = finalObject.condition.value
      //       .split(",")
      //       .map(
      //         (country) =>
      //           Object.keys(countriesName).find(
      //             (key) => countriesName[key] === country.trim()
      //           ) || country.trim()
      //       )
      //       .join(",");
      //   }
      // }

      function updateCountryValues(condition) {
        if (!condition || typeof condition !== "object") return;

        if (Array.isArray(condition.conditions)) {
          condition.conditions.forEach(updateCountryValues);
        }

        if (
          [
            "shippingDetails.address.country",
            "billingAddress.country",
          ].includes(condition.param) &&
          typeof condition.value === "string"
        ) {
          condition.value = condition.value
            .split(",")
            .map(
              (country) =>
                Object.keys(countriesName).find(
                  (key) => countriesName[key] === country.trim()
                ) || country.trim()
            )
            .join(",");
        }
      }

      // Start the recursive update from the main object
      if (finalObject?.condition) {
        updateCountryValues(finalObject.condition);
      }
      console.log(finalObject, "Updated Final Object");

      if (processorButtonHide) {
        setShowAddRule(false);
        setIsEditing(false);
        httpClient
          .fetchData(`${URL}${RULES_ENDPOINT}`, "POST", {
            id: null,
            status: ruleData?.status,
            priority: ruleData?.priority,
            ...finalObject,
          })
          .then((res) => {
            console.log("New rule added:", res);
            fetchData();
            setProcessorButtonHide(false);
            setAddProcessorErrorMsg(false);

            if (res.id) {
              showCustomSnackbar(
                "success",
                `Rule ${res.name} has been added successfully`,
                "right",
                "top",
                3000
              );
            } else {
              showCustomSnackbar(
                "error",
                `${res.fieldErrors[0].errors}`,
                "right",
                "top",
                3000
              );
            }
          })
          .catch((error) => {
            showCustomSnackbar(
              "error",
              "An error occurred, please try again.",
              "right",
              "top",
              3000
            );
            setIsLoading(false);
          });
      }
    }
  };

  const handleDeleteClick = () => {
    setIsLoading(true);
    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}/${ruleData.id}`, "DELETE")
      .then((res) => {
        console.log("Deleted rule:", res);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting rule:", error);
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    // setIsLoading(false);
    setRuleData({});
    fetchData();
    setIsEditing(false);
  };
  const handleEnableDisableClick = (status, name) => {
    //  setIsLoading(true);

    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}/${ruleData.id}`, "PATCH", {
        status: status === "ACTIVE" ? "DISABLED" : "ACTIVE",
      })
      .then((res) => {
        const changedStatus = status === "ACTIVE" ? "disabled" : "enabled";
        showCustomSnackbar(
          changedStatus === "enabled" ? "success" : "warning",
          `Rule ${name} has been ${changedStatus} successfully`,
          "right",
          "top",
          3000
        );
        setIsLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error updating rule status:", error, "errorrrsss");
        showCustomSnackbar(
          "error",
          `An error occurred, please try again.`,
          "right",
          "top",
          3000
        );
        setIsLoading(false);
      });
  };

  const handleDuplicateCall = (obj) => {
    const updatedObj = cloneDeep(obj);
    console.log(updatedObj, "object");
    setShowAddRule(true);
    setIsEditing(false);
    const updatedName = `${updatedObj.name}_COPY`;

    if (
      updatedObj.condition.conditions &&
      isArray(updatedObj.condition.conditions)
    ) {
      setRuleData({
        ...updatedObj,
        id: new Date().getTime(),
        name: updatedName,
        priority: 1,
      });
    } else {
      setRuleData({
        id: new Date().getTime(),
        name: updatedName,
        priority: 1,
        result: updatedObj.result,
        status: obj?.status?.toUpperCase() === "ACTIVE" ? "ACTIVE" : "DISABLED",
        // connectionName: updatedObj.connectionName,
        condition: {
          nestedOperation: "AND",
          conditions: [updatedObj.condition],
        },
      });
    }
    setRuleStates(obj);
    setDuplicateProcessorButton(true);

    const newObj = {
      id: obj.id,
      name: obj?.result,
      status: obj.status,
      priority: obj.priority,
    };

    handleText(newObj);
    // setProcessorButtonHide(true);
  };

  const handleRuleNameChange = (event) => {
    setRuleData({
      ...ruleData,
      name: event.target.value,
    });
    setError({ ...error, nameError: "" });
  };

  const handleProcessorChange = (value) => {
    setconnectionNameChanged(true);
    if (connectionNameChanged) {
      setRuleData({
        id: new Date().getTime(),
        name: ruleData.name ? ruleData.name : "",
        result: value,
        priority: ruleData.priority,
        status: "ACTIVE",
        condition: {
          nestedOperation: "AND",
          conditions: [
            {
              id: new Date().getTime(),
              param: undefined,
              operation: undefined,
              value: undefined,
              isError: false,
              errorMessage: "",
            },
          ],
        },
      });
    } else {
      setRuleData({
        ...ruleData,
        result: value,

        //connectionName: value,
      });
    }
    setError({ ...error, resultError: "" });
  };

  // const handleStatusChange = (event) => {
  //   setRuleData({
  //     ...ruleData,
  //     status: event.target.value,
  //   });
  //   setError({ ...error, statusError: "" });
  // };

  const handleStatusChange = (event) => {
    setRuleData((prevState) => ({
      ...prevState,
      status: event.target.value,
    }));
  };

  const getResultData = processorData?.map((each) => {
    // getData
    if (each.status === "ACTIVE") {
      return each.processorCode.toLowerCase();
    }
  });

  const Header = () => {
    return (
      // <>

      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="header-title">{t("RULES")}</div>
          <div className="header-sub-title">{t("RULES_INITIAL_TRACK")}</div>
        </div>

        <Tooltip title="Add New Rule">
          <div className={`card-toolbar`} onClick={handleAddClick}>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary">
                <i class="ki-duotone ki-plus fs-4"></i>
                {t("NEW_RULE")}
                {/* {Object.keys(data)?.length <= 0 ? "Add Rule" : "Add New Rule"} */}
              </button>
            </div>
          </div>
        </Tooltip>
      </div>

      //</>
    );
  };

  const handleProcessorClick = (event) => {
    setProcessorAnchorEl(event.currentTarget);
  };

  const handleProcessorClose = () => {
    setProcessorAnchorEl(null);
  };

  const handleProcessorSelect = () => {
    handleProcessorClose();
  };
  const handleProcessorText = (value) => {
    setUpdateProcessorText(value);
    handleProcessorClose();
  };

  const handleUpdateProcessorChange = (value) => {
    setRuleData({
      ...ruleData,
      result: value,
      // connectionName: value,
    });

    setError({ ...error, resultError: "" });
    setChangeProcessorText(true);
  };

  return (
    <div>
      <div key={ruleData.id}>
        {index === 0 && (
          <div>
            {/* Test Mode Banner - Only shown when in test mode */}
            <TestBanner />
            <div className="toolbar py-2" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-fluid container-fluid d-flex align-items-center"
              >
                <Breadcrumb list={breadcrumbList} />
              </div>{" "}
            </div>
            <div className="container-xxl">
              <div className="card">
                <Header />
              </div>
            </div>
          </div>
        )}

        {showAddRule && (
          <div className="container-xxl rulescontainer">
            <div className="card rule-box">
              <div className="card-header card-header-stretch">
                <div className="card-title d-flex align-items-center ">
                  <i class="ki-duotone ki-document fs-1 text-primary me-3 lh-0">
                    <span class="path1"></span>
                    <span class="path2"> </span>
                  </i>
                  <h3 class="fw-bold m-0 text-gray-800">{t("NEW_RULE")}</h3>
                </div>
              </div>
              <div className="nested-rules-container">
                <div className="conditions-main-container">
                  <div className="row">
                    <div className="col-sm-2 text-sm-end">
                      <p
                      // style={{
                      //   marginTop:
                      //     error.nameError.length > 0 ? "-18px" : "0px",
                      // }}
                      // className="new-rule-text"
                      >
                        {t("RULE")}
                      </p>
                    </div>
                    <div className="col-sm-10">
                      <FormControl fullWidth>
                        <TextField
                          className="new-rule-input-field"
                          fullWidth
                          label={t("RULE")}
                          variant="outlined"
                          onChange={handleRuleNameChange}
                          value={ruleData?.name}
                          key={index}
                          error={error.nameError.length > 0}
                          helperText={error.nameError}
                          spellCheck={false}
                          // sx={{
                          //   width: "100%",
                          //   "& .MuiOutlinedInput-root": {
                          //     height: "40px",
                          //     "& input": {
                          //       height: "40px",
                          //       padding: "0 14px",
                          //     },
                          //   },
                          //   "& .MuiInputLabel-root": {
                          //     marginTop: "-0.5rem",
                          //     "&.Mui-focused": {
                          //       marginTop: "0px",
                          //     },
                          //   },
                          // }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 text-sm-end">
                      <p> {t("STATUS")}</p>
                    </div>
                    <div className="col-sm-10">
                      <FormControl fullWidth>
                        <RadioGroup
                          row
                          className="radiobtn"
                          aria-label="status"
                          name="status"
                          value={ruleData.status?.toUpperCase()}
                          onChange={handleStatusChange}
                        >
                          <FormControlLabel
                            value="ACTIVE"
                            control={<Radio />}
                            label={t("ENABLED")}
                          />
                          <FormControlLabel
                            value="DISABLED"
                            control={<Radio />}
                            label={t("DISABLED")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 text-sm-end">
                        <p> {t("Connection")}</p>
                      </div>
                      <div
                        // style={{
                        //   marginLeft: "100px",
                        //   marginTop: "7px",
                        // }}
                        className="col-sm-10"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Button
                              sx={{
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                padding: "12px 13px",
                              }}
                              className="select-connection-button"
                              id="fade-button"
                              aria-controls={open ? "fade-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(event) => {
                                handleClick(event);
                              }}
                            >
                              <Typography className="processor-button">
                                {t("SELECT_CONNECTION")}
                              </Typography>
                              <ExpandMoreIcon sx={{ marginLeft: "10px" }} />
                            </Button>
                            <p
                              className={
                                addProcessorErrorMsg
                                  ? "add-processor-error-text"
                                  : "hide-error-text"
                              }
                            >
                              {t("SELECT_CONNECTION")}
                            </p>

                            <Menu
                              id="fade-menu"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                              {processorData
                                ?.sort((a, b) => {
                                  return a.processorCode.localeCompare(
                                    b.processorCode
                                  );
                                })
                                ?.map((processor) => {
                                  console.log(processor.name, "connection");
                                  return (
                                    <MenuItem
                                      sx={{
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 123, 255, 0.1)",
                                          color: "#1b84ff",
                                        },
                                      }}
                                      onClick={() => {
                                        handleText(processor);
                                        handleProcessorChange(processor?.name);
                                      }}
                                      key={processor.displayName}
                                      value={processor.processorCode}
                                    >
                                      {`${processor.name} (${processor.processorCode})`}
                                    </MenuItem>
                                  );
                                  // );
                                })}
                            </Menu>
                          </div>
                          <div>
                            {processorButtonHide && (
                              <Button
                                variant="outlined"
                                sx={{ marginTop: "6px" }}
                                className="select-new-processor-text"
                                disabled
                              >
                                {`${processorText?.name} ${
                                  duplicateProcessorButton
                                    ? processorText?.processorCode
                                      ? `(${processorText?.processorCode})`
                                      : ""
                                    : ""
                                }`}
                              </Button>
                            )}
                          </div>

                          {/* <div>
                            {duplicateProcessorButton && (
                              <Button
                                variant="outlined"
                                sx={{ marginTop: "6px" }}
                                className={"select-new-processor-text"}
                                disabled
                              >
                                {`${ruleStates?.result}`}
                              </Button>
                            )}
                          </div> */}
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 text-sm-end">
                      <p>{t("CONDITIONS")}</p>
                    </div>
                    <div className="col-sm-10">
                      <div className="condition-container ">
                        {ruleData.condition?.conditions?.length > 0 ? (
                          <>
                            <RulesCondition
                              data={{ condition: ruleData.condition }}
                              level={0}
                              setRuleData={setRuleData}
                              ruleData={ruleData}
                              indexPath={[]}
                              conditionError={conditionError}
                              key={ruleData.id}
                              setConditionError={setConditionError}
                              ruleId={ruleData.id}
                              // isEditing={isEditing}
                              isEditing={
                                isEditing ? changeEditSaveFirstRule : true
                              }
                              processorText={processorText}
                              selectPaymentMethods={selectPaymentMethods}
                              savedPaymentMethodsUpdate={
                                savedPaymentMethodsUpdate
                              }
                              processorButtonHide={processorButtonHide}
                              addProcessorErrorMsg={addProcessorErrorMsg}
                              updateProcessorText={updateProcessorText}
                              setUpdateProcessorText={setUpdateProcessorText}
                              setGetEditRuleName={setGetEditRuleName}
                              setGetRuleData={setGetRuleData}
                              nestedPaymentMethods={nestedPaymentMethods}

                              // editSaveRule={editSaveRule}
                              // getBackendProcessor={arrayData}
                            />
                          </>
                        ) : (
                          <RulesCondition
                            data={{
                              condition: {
                                nestedOperation: "AND",
                                conditions: [
                                  ruleData?.condition?.conditions[0] || {
                                    id: new Date().getTime(),
                                    param: undefined,
                                    operation: undefined,
                                    value: undefined,
                                    isError: false,
                                    errorMessage: "",
                                  },
                                ],
                              },
                            }}
                            level={0}
                            setRuleData={setRuleData}
                            ruleData={ruleData}
                            indexPath={[]}
                            conditionError={conditionError}
                            key={ruleData.id}
                            setConditionError={setConditionError}
                            ruleId={ruleData.id}
                            isEditing={
                              isEditing ? changeEditSaveFirstRule : true
                            }
                            processorText={processorText}
                            addProcessorErrorMsg={addProcessorErrorMsg}
                            updateProcessorText={updateProcessorText}
                            setUpdateProcessorText={setUpdateProcessorText}
                            setGetEditRuleName={setGetEditRuleName}
                            setGetRuleData={setGetRuleData}
                            nestedPaymentMethods={nestedPaymentMethods}
                            // editSaveRule={editSaveRule}
                            // getBackendProcessor={arrayData}
                          />
                        )}
                        <br></br>
                        {/* <div
                          style={{
                            marginLeft: "100px",
                            marginTop: "7px",
                         
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <Button
                                sx={{
                                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                  padding: "12px 13px",
                                }}
                                className="select-connection-button"
                                id="fade-button"
                                aria-controls={open ? "fade-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(event) => {
                                  handleClick(event);
                                }}
                              >
                                <Typography className="processor-button">
                                  {t("SELECT_CONNECTION")}
                                </Typography>
                                <ExpandMoreIcon sx={{ marginLeft: "10px" }} />
                              </Button>
                              <p
                                className={
                                  addProcessorErrorMsg
                                    ? "add-processor-error-text"
                                    : "hide-error-text"
                                }
                              >
                                {t("SELECT_CONNECTION")}
                              </p>

                              <Menu
                                id="fade-menu"
                                MenuListProps={{
                                  "aria-labelledby": "fade-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                              >
                                {processorData
                                  ?.sort((a, b) => {
                                    return a.processorCode.localeCompare(
                                      b.processorCode
                                    );
                                  })
                                  ?.map((processor) => {
                                    console.log(processor.name, "connection");
                                    return (
                                     
                                      <MenuItem
                                      
                                        sx={{
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(0, 123, 255, 0.1)",
                                            color: "#1b84ff",
                                          },
                                        }}
                                        onClick={() => {
                                          handleText(processor);
                                          handleProcessorChange(
                                           
                                            processor?.name
                                          );
                                        }}
                                        key={processor.displayName}
                                        value={processor.processorCode}
                                      >
                                        {`${processor.name} (${processor.processorCode})`}
                                      </MenuItem>
                                    );
                                    // );
                                  })}
                              </Menu>
                            </div>

                            {processorButtonHide && (
                              <Button
                                variant="outlined"
                                sx={{ marginTop: "6px" }}
                                className="select-new-processor-text"
                                disabled
                              >

                                {`${processorText.name} (${processorText.processorCode})`}
                              </Button>
                            )}
                          </Box>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rules-settings-buttons-container">
                <button
                  className=" btn btn-sm btn-flex btn-light-primary"
                  onClick={handleCancelClick}
                >
                  {t("CANCEL")}
                </button>
                <button
                  className=" btn btn-sm btn-flex btn-light-primary"
                  onClick={handleAddRule}
                >
                  {t("ADD")}
                </button>
              </div>
            </div>
          </div>
        )}

        <div>
          {Object.keys(data)?.length !== 0 ? (
            <div className="container-xxl rulescontainer">
              <div className="">
                <Box
                  className=""
                  key={data.id}
                  // sx={{ padding: { xs: "10px", sm: "18px" } }}
                >
                  <Box className="card rule-box">
                    <Grid
                      className="card-header card-header-stretch"
                      // container
                      // alignItems="center"
                      // justifyContent="space-between"
                      // sx={{ marginTop: "10px" }}
                    >
                      <Grid className="card-title d-flex align-items-center">
                        {/* <Typography
                      className=""
                     
                    > */}
                        <i class="ki-duotone ki-document fs-1 text-primary me-3 lh-0">
                          <span class="path1"></span>
                          <span class="path2"> </span>
                        </i>
                        <h3 className="fw-bold m-0 text-gray-800">
                          {t("RULE")} {data?.priority} : {data?.name}
                        </h3>
                        {/* </Typography> */}
                        <div
                          className={
                            data.status === "ACTIVE"
                              ? "status enable"
                              : "status disable"
                          }
                        >
                          {data.status === "ACTIVE" ? "Enabled" : "Disabled"}
                        </div>
                      </Grid>

                      <Grid className="card-toolbar">
                        {!isEditing && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <IconButton
                        onClick={() => handleMoveItem(index, "up")}
                        disabled={index === 0}
                      > */}
                            {/* <NorthSharpIcon /> */}
                            <button
                              type="button"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Move to top"
                              class="btn p-0"
                              disabled={index === 0}
                            >
                              <KeyboardDoubleArrowUpOutlinedIcon
                                sx={{
                                  backgroundColor: "#b6d3fe",
                                  color: "#0d6efd",
                                  height:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  width:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  pointerEvents: index === 0 ? "none" : "auto",
                                }}
                                className={`${
                                  index === 0
                                } ? "arrow-icon double rounded me-1 disabled" : "arrow-icon double rounded me-1 enabled" `}
                                onClick={() => {
                                  goToTop();
                                }}
                              />
                            </button>
                            <button
                              onClick={() => handleMoveItem(index, "up")}
                              type="button"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Move to top"
                              class="btn p-0"
                              disabled={index === 0}
                            >
                              <KeyboardArrowUpOutlinedIcon
                                className={`${
                                  index === 0
                                } ? "arrow-icon double rounded me-1 disabled" : "arrow-icon double rounded me-1 enabled" `}
                                sx={{
                                  backgroundColor: "#b6d3fe",
                                  color: "#0d6efd",
                                  height:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  width:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  pointerEvents: index === 0 ? "none" : "auto",
                                }}
                              />
                            </button>

                            <button
                              onClick={() => handleMoveItem(index, "down")}
                              type="button"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Move to top"
                              class="btn p-0"
                              disabled={index === arrayData.length - 1}
                            >
                              <KeyboardArrowDownOutlinedIcon
                                className={`${
                                  index === 0
                                } ? "arrow-icon double rounded me-1 disabled" : "arrow-icon double rounded me-1 enabled" `}
                                sx={{
                                  backgroundColor: "#b6d3fe",
                                  color: "#0d6efd",
                                  height:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  width:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  pointerEvents:
                                    index === arrayData.length - 1
                                      ? "none"
                                      : "auto",
                                }}
                              />
                            </button>

                            <button
                              type="button"
                              class="btn p-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Move to bottom"
                              disabled={index === arrayData.length - 1}
                            >
                              <KeyboardDoubleArrowDownOutlinedIcon
                                title="Move to bottom"
                                sx={{
                                  backgroundColor: "#b6d3fe",
                                  color: "#0d6efd",
                                  height:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                  width:
                                    screenWidth > 1199 ? "1.5rem" : "1.1rem",
                                }}
                                className="arrow-icon rounded"
                                onClick={() => {
                                  goToBottom();
                                }}
                              />
                            </button>
                            {/* </IconButton> */}
                            {/* <IconButton
                        onClick={() => handleMoveItem(index, "down")}
                        disabled={index === arrayData?.length - 1}
                      >
                        <SouthSharpIcon />
                      </IconButton> */}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Box className="nested-rules-container">
                      <Box
                        className="conditions-main-container"
                        sx={{ marginTop: "10px" }}
                      >
                        <RulesCondition
                          data={{
                            condition: Array.isArray(
                              data?.condition?.conditions
                            )
                              ? data.condition
                              : {
                                  nestedOperation: "AND",
                                  conditions: [data.condition],
                                },
                          }}
                          level={0}
                          setRuleData={setRuleData}
                          ruleData={ruleData}
                          indexPath={[]}
                          conditionError={conditionError}
                          setConditionError={setConditionError}
                          key={data.id}
                          ruleId={data.id}
                          isEditing={isEditing}
                          selectPaymentMethods={selectPaymentMethods}
                          savedPaymentMethodsUpdate={savedPaymentMethodsUpdate}
                          processorText={processorText}
                          getBackendProcessor={arrayData}
                          updateProcessorText={updateProcessorText}
                          setUpdateProcessorText={setUpdateProcessorText}
                          editSaveRule={editSaveRule}
                          setGetEditRuleName={setGetEditRuleName}
                          setGetRuleData={setGetRuleData}
                          nestedPaymentMethods={nestedPaymentMethods}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "15px",
                          // marginTop: "-8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Button
                            sx={{
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              padding: "12px 13px",
                              width: "100%",
                              maxWidth: "200px",
                            }}
                            className="processor-button"
                            disabled={editRule}
                            onClick={handleProcessorClick}
                          >
                            {t("CONNECTION_NAME")}
                          </Button>
                          <Menu
                            anchorEl={processorAnchorEl}
                            open={Boolean(processorAnchorEl)}
                            onClose={handleProcessorClose}
                          >
                            {processorData
                              ?.sort((a, b) =>
                                a.processorCode.localeCompare(b.processorCode)
                              )
                              ?.map((processor) => (
                                <MenuItem
                                  // sx={{ width: "165px" }}
                                  onClick={() => {
                                    handleProcessorText(processor);
                                    handleUpdateProcessorChange(
                                      processor?.name
                                    );
                                    handleProcessorSelect();
                                  }}
                                  key={processor.displayName}
                                  value={processor.processorCode}
                                >
                                  {`${processor.name} ${processor.processorCode}`}
                                </MenuItem>
                              ))}
                          </Menu>
                        </Box>
                        <Button
                          className="selected-processor-text"
                          sx={{ marginLeft: "10px" }}
                          variant="outlined"
                          disabled
                        >
                          {changeProcessorText
                            ? `${updateProcessorText?.name} ${updateProcessorText?.processorCode}`
                            : data?.result}
                        </Button>
                      </Box>
                    </Box>

                    <Box
                      className="rules-settings-buttons-container"
                      sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                    >
                      <Button
                        onClick={() => {
                          handleEditSaveClick(data);
                          setEditRule(false);
                        }}
                        disabled={data?.status === "DISABLED"}
                        className="btn btn-sm btn-flex btn-light-primary"
                        // sx={{
                        //   width: { xs: "5%", sm: "auto" },
                        //   marginBottom: { xs: "10px", sm: "0" },
                        // }}
                      >
                        {isEditing ? t("SAVE") : t("EDIT")}
                      </Button>
                      {isEditing && (
                        <Button
                          onClick={handleCancel}
                          className="btn btn-sm btn-flex btn-light-primary"
                        >
                          Cancel
                        </Button>
                      )}
                      <div
                        className={isEditing ? "buttons-hide" : "buttons-show"}
                      >
                        <Button
                          onClick={() =>
                            handleEnableDisableClick(data.status, data.name)
                          }
                          className="btn btn-sm btn-flex btn-light-primary text-transform"
                          // sx={{
                          //   width: { xs: "5%", sm: "auto" },
                          //   marginBottom: { xs: "10px", sm: "0" },
                          // }}
                        >
                          {data.status === "ACTIVE"
                            ? t("DISABLE")
                            : t("ENABLE")}
                        </Button>
                        <Button
                          onClick={() => handleDuplicateCall(data)}
                          className="btn btn-sm btn-flex btn-light-primary text-transform"

                          // sx={{
                          //   width: { xs: "5%", sm: "auto" },
                          //   marginBottom: { xs: "10px", sm: "0" },
                          // }}
                        >
                          {t("DUPLICATE")}
                        </Button>
                        <Button
                          onClick={() => handleDeleteClick()}
                          className="btn btn-sm btn-flex btn-light-primary text-transform"
                          // sx={{
                          //   width: { xs: "5%", sm: "auto" },
                          //   marginBottom: { xs: "10px", sm: "0" },
                          // }}
                        >
                          {t("DELETE")}
                        </Button>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          ) : (
            !showAddRule && (
              <div className="container-xxl rulescontainer rule-empty-message">
                No rules are configured yet
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default RulesSettingCard;
