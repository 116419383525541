import React, { createContext, useContext, useState } from "react";
import { PROCESSOR_ENDPOINT } from "constants/Constants";
import { httpClient } from "utils/HttpClient";

const FunctionsContext = createContext();

export const FunctionsProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState("");
  const [todayAndYesterdayDate, setTodayAndYesterdayDate] = useState(false);
  const [processorMetadata, setProcessormetadata] = useState();

  const handleButtonClick = (value) => {
    setActiveButton(value);
  };

  // const handleTodayYesterdayDate = (todayValue) => {
  //   setTodayAndYesterdayDate(todayValue);
  // };

  return (
    <FunctionsContext.Provider
      value={{
        handleButtonClick,
        activeButton,
        // handleTodayYesterdayDate,
        // todayAndYesterdayDate,
      }}
    >
      {children}
    </FunctionsContext.Provider>
  );
};

export const useFunctions = () => useContext(FunctionsContext);
