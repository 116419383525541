import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./AddPaymentProcessorModal.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Wallet from "../Icons/Wallet";
import Close from "../Icons/Close";
import Radio from "../Icons/Radio";
import SelectedRadio from "../Icons/SelectedRadio";
import { URL, PROCESSOR_ENDPOINT } from "constants/Constants";
import { httpClient } from "utils/HttpClient";
import { processors } from "utils/processors";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  regions,
  countries,
  currencies,
  paymentTypes,
  countrycurrencies,
} from "../../utils/processorFiltersMap";
import SaveIcon from ".././Icons/FloppyDisk";
const style = {};

const Item = ({ selected, item }) => {
  const title = (
    item?.processorCode?.charAt(0)?.toUpperCase() +
    item?.processorCode?.slice(1)?.toLocaleLowerCase()
  ).replaceAll("_", " ");
  console.log(selected, item, "Payment List");

  return (
    <div
      className={`payment-processor-item payment-processor-item-${
        selected ? "selected" : "not-selected"
      }`}
    >
      <div className="payment-processor-item-selection-container">
        <div className="payment-processor-item-title-container">
          <div className="payment-processor-item-img-container">
            <img
              loading="lazy"
              src={item?.logo_url}
              className="payment-processor-item-img"
            />
          </div>
          <div className="payment-processor-item-title">
            {item?.displayName}
          </div>
        </div>
        <div className="payment-processor-item-radio-container">
          {selected ? <Radio /> : <SelectedRadio />}
        </div>
      </div>
      <div className="payment-processor-item-subtitle">
        {/* Accepts Bank transfer, wallets and more. */}
        {item?.description}
      </div>
    </div>
  );
};

const AddPaymentProcessorModal = ({
  open,
  setOpen,
  addPayment,
  processorData,
  setSelectedItem,
  setGetBackendProcessorData,
}) => {
  const isKeyCloakTokenExchanged = useSelector(
    (state) => state.merchant.isKeyCloakTokenExchanged
  );
  const [list, setList] = React.useState([]);
  const [paymentList, setPaymentList] = React.useState();
  const [selected, setSelected] = React.useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  // const [paymentType, setPaymentType] = useState("");
  const [paymentType, setPaymentType] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [regionInputStatuts, setRegionInputStatuts] = useState(true);
  const [countryInputStatus, setCountryInputStatus] = useState(true);
  const [currencyInputStatus, setCurrencyInputStatus] = useState(true);
  const [paymentInputStatus, setPaymentInputStatus] = useState(true);
  const [updateDuplicateList, setUpdateDuplicateList] = useState("");
  const [updateProcessors, setUpdateProcessors] = useState(false);
  const [isAddProcessorEnabled, setIsAddProcessorEnabled] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [allCountries, setAllCountries] = useState();
  const [allCountriesDataBasedOnRegion, setAllCountriesDataBasedOnRegion] =
    useState([]);
  const [
    allCountriesDataBasedOnCurrencies,
    setAllCountriesDataBasedOnCurrencies,
  ] = useState([]);
  const [searchRegionTerm, setSearchRegionTerm] = useState("");
  const [searchCountryTerm, setSearchCountryTerm] = useState("");
  const [searchCurrencyTerm, setSearchCurrencyTerm] = useState("");

  // console.log(activeList, "activeList");
  // console.log(allCountries, "allcountries");
  // console.log(selectedCurrencies, "selecetdCurrencies");
  // console.log(selectedCurrencies, "currencies");
  // console.log(allCountriesDataBasedOnRegion, "basedonregion");
  // console.log(list, "listonregion");
  // console.log(allCountriesDataBasedOnCurrencies, "basedonCurrencies");
  console.log(selectedCountries, "countries");
  console.log(selectedCurrencies, "currencies");

  const countryList = region.flatMap((r) => countries[r] || []);
  // const uniqueCountryList = [...new Set(countryList)];
  // const allCountriesSelected =
  //   selectedCountries.length === uniqueCountryList.length &&
  //   uniqueCountryList.length > 0;

  // const countryList = region.flatMap((r) => countries[r] || []);
  // const uniqueCountryList = [...new Set(countriesList)];
  // console.log(uniqueCountryList, "unique");
  // const allCountriesSelected =
  //   selectedCountries.length === uniqueCountryList.length &&
  //   uniqueCountryList.length > 0;

  const uniqueCountryList = [...new Set(countriesList)];
  const allCountriesSelected =
    selectedCountries.length === uniqueCountryList.length &&
    uniqueCountryList.length > 0;

  useEffect(() => {
    const groupedCountries = regions.map((region) => countries[region] || []);
    const allCountries = groupedCountries.flat();
    setAllCountries(allCountries);

    // setCountriesList(
    //   region.length > 0
    //     ? countryList
    //     : selectedCountries?.length > 0
    //     ? selectedCountries
    //     : allCountries
    // );

    setCountriesList(
      region.length > 0
        ? countryList
        : selectedCountries?.length > 0 && region?.length === 0
        ? allCountries
        : selectedCountries?.length > 0
        ? selectedCountries
        : allCountries
    );
  }, [region, selectedCountries]);

  const handleRegionChange = (event) => {
    const value = event.target.value;

    if (value.includes("Select All")) {
      setRegion(region.length === regions.length ? [] : [...regions]);
      // applyFilters();
    } else {
      setRegion(value);
      // applyFilters();
    }
  };

  const handlePaymentChange = (event) => {
    const value = event.target.value;

    if (value.includes("Select All")) {
      setPaymentType(
        paymentType.length === paymentTypes.length ? [] : [...paymentTypes]
      );
    } else {
      setPaymentType(value);
    }
  };

  const getCurrenciesForSelectedCountries = () => {
    const selectedCurrenciesSet = new Set();

    const availableCurrenciesBasedOnCountries =
      selectedCountries?.length > 0 ? selectedCountries : countriesList;
    console.log(availableCurrenciesBasedOnCountries, "available countries");

    availableCurrenciesBasedOnCountries.forEach((country) => {
      Object.entries(countrycurrencies).forEach(([currency, countries]) => {
        if (countries.includes(country)) {
          selectedCurrenciesSet.add(currency);
        }
      });
    });

    return Array.from(selectedCurrenciesSet);
  };

  const filteredCurrencies = getCurrenciesForSelectedCountries();

  // const selectAllCurrencies =
  //   selectedCurrencies.length === filteredCurrencies.length &&
  //   filteredCurrencies.length > 0;
  const selectAllCurrencies =
    selectedCurrencies.length === filteredCurrencies.length;

  const handleCurrencyChange = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      setSelectedCurrencies(selectAllCurrencies ? [] : filteredCurrencies);
    } else {
      setSelectedCurrencies(value);
    }
  };

  // const handleCountriesChange = (event) => {
  //   const value = event.target.value;
  //   setUpdateDuplicateList(value);

  //   if (value.includes("Select All")) {
  //     setSelectedCountries(allCountriesSelected ? [] : [...countriesList]);
  //   } else {
  //     setSelectedCountries(value);
  //   }
  // };

  const handleCountriesChange = (event) => {
    const value = event.target.value;

    if (value.includes("Select All")) {
      if (selectedCountries.length === uniqueCountryList.length) {
        setSelectedCountries([]);
      } else {
        setSelectedCountries(uniqueCountryList);
      }
    } else {
      setSelectedCountries(value);
    }
  };

  React.useEffect(() => {
    fetchListData();
    fetchSettingsData();
  }, [processorData, isKeyCloakTokenExchanged]);

  function transformPaymentOptions(paymentOptions) {
    const transformed = {};

    for (const [key, value] of Object?.entries(paymentOptions)) {
      transformed[key] = {
        currencies: [],
        defaultOption: false,
        status: "DISABLED",
      };
    }

    return transformed;
  }

  React.useEffect(() => {
    if (selected) {
      const selectedData = paymentList?.find(
        (item) => item.code === selected
      )?.paymentMethods;

      setSelectedItem({
        id: null,
        processorCode: selected,
        status: "ACTIVE",
        name: "",
        processorConfigurations: {
          merchantId: "",
          clientKey: "",
          serverKey: "",
        },
        paymentTypes: transformPaymentOptions(selectedData),
      });
    }
  }, [selected]);
  const fetchListData = () => {
    httpClient
      .fetchData(
        `${URL}${PROCESSOR_ENDPOINT}`,

        "GET"
      )
      .then((settings) => {
        // setPaymentList(
        //   settings.filter((setting) => {
        //     return !processorData?.find(
        //       (item) =>
        //         item?.processorCode?.toLowerCase() ===
        //         setting?.code?.toLowerCase()
        //     );
        //   })
        // );

        setPaymentList(settings);
        // console.log("get Be data", settings);
      });
  };

  const fetchSettingsData = () => {
    // const processorList = processors.filter((setting) => {
    //   return !processorData?.find(
    //     (item) =>
    //       item.processorCode.toLowerCase() ===
    //       setting.processorCode.toLowerCase()
    //   );
    // });
    // setList(processorList);
    // processors.filter((eachItem) => {
    //   return paymentList?.some((each) => {
    //     if (each.code === eachItem.processorCode) {
    //       console.log(eachItem, "each");
    //       setList((prev) => [...prev, each]);
    //       return true;
    //     }
    //   });
    // });
    //setList(processors);
  };

  useEffect(() => {
    const updatedList = [];
    const notInProcessorArray = [];

    paymentList?.forEach((each) => {
      let isMatched = false;

      const paymentMethodKeys = Object.keys(each.paymentMethods);
      console.log(paymentMethodKeys, "keys");

      const formattedMethods = paymentMethodKeys
        .map((str) =>
          str
            .toLowerCase()
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())
            .replace(/\s\w/g, (char) => char.toLowerCase())
        )
        .join(", ");

      let message = "";
      if (
        paymentMethodKeys.length === 1 &&
        paymentMethodKeys.includes("CARDS")
      ) {
        message = "Accept Cards";
      } else if (
        paymentMethodKeys.length === 2 &&
        paymentMethodKeys.includes("CARDS") &&
        paymentMethodKeys.includes("BANK_TRANSFER")
      ) {
        message = "Accepts Cards and Bank transfer";
      } else if (
        paymentMethodKeys.length >= 3 &&
        paymentMethodKeys.includes("CARDS") &&
        paymentMethodKeys.includes("BANK_TRANSFER")
      ) {
        message = "Accepts Cards, Bank transfer, wallets and more.";
      } else {
        message = `${formattedMethods?.length > 0 ? "Accepts" : "Accept"} ${
          formattedMethods?.length > 0 ? formattedMethods : "Cards"
        }`;
      }

      processors?.forEach((eachItem) => {
        if (
          eachItem?.processorCode?.toLocaleLowerCase() ===
          each.code?.toLocaleLowerCase()
        ) {
          const updateFeProcessors = {
            displayName: eachItem?.displayName || eachItem?.processorCode,
            processorCode: eachItem.processorCode,
            description: message,
            logo_url: eachItem.logo_url,
          };

          updatedList.push(updateFeProcessors);
          isMatched = true;
        }
      });

      if (!isMatched) {
        const getProcessorIcon = each?.code?.toLocaleLowerCase();
        const logoUrl = `logos/${getProcessorIcon}.svg`;
        const notInProcessorObject = {
          displayName: each?.displayName || each?.code,
          processorCode: each.code,
          description: message,
          logo_url: logoUrl,
        };

        notInProcessorArray.push(notInProcessorObject);
      }
    });

    const finalList = [...updatedList, ...notInProcessorArray];

    setList(finalList);
    setGetBackendProcessorData(finalList);
    console.log("Be Data", paymentList);
    const sortedPaymentList = paymentList?.sort((a, b) => {
      if (!a.displayName) return 1; // Move null/undefined to the end
      if (!b.displayName) return -1;
      return a.displayName.localeCompare(b.displayName);
    });

    console.log("Be Data sorting", sortedPaymentList);

    console.log("Fe Data", processors);
    console.log("Not In Fe Data:", notInProcessorArray);
    console.log("Final List:", finalList);
  }, [
    paymentList,
    processors,
    updateDuplicateList,
    updateProcessors,
    selectedCurrencies,
    countriesList?.length > 0,
    region,
    // selectedCountries,
  ]);

  const duplicatedList = Array.from(
    new Map(list.map((item) => [item.processorCode, item])).values()
  );

  const newProcessorsFilters = (
    duplicatedList?.sort((a, b) =>
      a?.processorCode?.localeCompare(b?.processorCode)
    ) || []
  )?.filter(
    (item) =>
      item?.displayName?.toLowerCase()?.includes(searchTerm) &&
      item?.processorCode !== "GEIDEA"
  );

  console.log(newProcessorsFilters, "newprocssors");

  const displayNamesArray =
    newProcessorsFilters?.map((item) => item.displayName) || [];
  console.log(displayNamesArray, "processorArayData");

  console.log(filteredCurrencies, "filteredCurrencies");

  // console.log(newProcessorsFilters, "searching filters");
  // console.log(searchTerm, "searchTerm");

  useEffect(() => {
    duplicatedList.map((eachProcessor) => {
      if (eachProcessor?.processorCode?.toLowerCase()?.includes(searchTerm)) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, [searchTerm]);

  // console.log(loader, "loader");

  const applyFilters = () => {
    console.log(region, "region");
    console.log(selectedCountries, "country");
    console.log(selectedCurrencies, "currencies");

    // Reset list to the full unfiltered list before filtering
    let updatedList =
      selectedCountries?.length > 0
        ? [...allCountriesDataBasedOnRegion]
        : region?.length === 0 && selectedCountries?.length === 0
        ? [...allCountriesDataBasedOnCurrencies]
        : [...duplicatedList];

    // console.log(paymentList, "paymentList");

    const getCountriesList =
      selectedCountries?.length > 0 ? selectedCountries : countriesList;

    // Step 1: Convert selected countries to their respective currencies
    const sortedCurrencies =
      // selectedCountries
      //

      getCountriesList?.flatMap((country) =>
        Object.entries(countrycurrencies)
          ?.filter(([currency, countries]) => countries.includes(country))
          .map(([currency]) => currency)
      );

    console.log(sortedCurrencies, "Mapped Currencies");
    const availableCurrencies =
      selectedCurrencies?.length > 0 ? selectedCurrencies : sortedCurrencies;

    console.log(availableCurrencies, "available currencies");

    // Step 2: Filter processors that support these currencies
    const availableProcessors = paymentList
      ?.filter((provider) =>
        Object.values(provider.paymentMethods).some((method) =>
          method.currencies?.some(
            (currency) =>
              //sortedCurrencies
              availableCurrencies.includes(currency)
            // &&
            //   selectedCurrencies.includes(currency)
          )
        )
      )
      .map((provider) => provider.code);

    console.log(
      availableProcessors?.length > 0 ? availableProcessors : "No Processor"
    );

    // Step 3: Apply the filtering on the original full list (`duplicatedList`)

    // const filteredProcessors = updatedList.filter(
    //   (eachProcessor) =>
    //     availableProcessors.includes(eachProcessor.displayName) &&
    //     eachProcessor.displayName.toLowerCase().includes(searchTerm)
    // );

    const lowerCaseProcessors = availableProcessors?.map((p) =>
      p.toLowerCase()
    );
    console.log(lowerCaseProcessors, "Lowercase Available Processors");

    const filteredProcessors = updatedList?.filter((eachProcessor) => {
      return lowerCaseProcessors.includes(
        eachProcessor.processorCode.toLowerCase()
      );
    });
    console.log(availableProcessors, "available Processors");
    console.log(filteredProcessors, "available Filtered Processors");
    // console.log(updatedList, "basedonUpdate");

    // if (region?.length > 0 && selectedCountries?.length === 0) {
    //   setAllCountriesDataBasedOnRegion(filteredProcessors);
    // }

    setList(filteredProcessors);
  };

  const resetFilters = () => {
    setRegion([]);
    setSelectedCountries([]);
    setSelectedCurrencies([]);
    setList([]);
    setUpdateProcessors(true);
    setSearchTerm("");
    setTimeout(() => setUpdateProcessors(false), 100);
  };

  const reloadFilters = () => {
    //setRegion([]);
    //  setSelectedCountries([]);
    setSelectedCurrencies([]);
    setList([]);
    setUpdateProcessors(true);
    setSearchTerm("");
    setTimeout(() => setUpdateProcessors(false), 100);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedCurrencies]);

  useEffect(() => {
    applyFilters();
  }, [countriesList?.length]);

  useEffect(() => {
    applyFilters();
  }, [selectedCountries]);

  useEffect(() => {
    applyFilters();
  }, [selectedCountries?.length]);

  useEffect(() => {
    if (region && selectedCountries) {
      applyFilters();
    }
  }, [region && selectedCountries]);

  // useEffect(() => {
  //   if (selectedCountries?.length !== 0) {
  //   }
  // }, [selectedCountries]);

  useEffect(() => {
    if (region) {
      setSelectedCountries([]);
      setSelectedCurrencies([]);
    }
  }, [region]);

  useEffect(() => {
    setSelectedCurrencies([]);
  }, [selectedCountries]);

  useEffect(() => {
    if (
      region?.length === 0 &&
      selectedCountries?.length === 0 &&
      selectedCurrencies?.length === 0
    ) {
      setAllCountriesDataBasedOnCurrencies(duplicatedList);
      setAllCountriesDataBasedOnRegion(duplicatedList);
    }

    // if (region?.length === 0 && selectedCurrencies?.length === 0) {
    //   setAllCountriesDataBasedOnRegion(duplicatedList);
    // }
  }, [list]);

  const filterRegionData = regions
    ?.sort()
    ?.filter((eachItem) =>
      eachItem?.toLowerCase()?.includes(searchRegionTerm.toLowerCase())
    );

  const filterCountryData = countriesList
    ?.sort()
    ?.filter((eachItem) =>
      eachItem?.toLowerCase()?.includes(searchCountryTerm.toLowerCase())
    );

  const filterCurrencyData = filteredCurrencies
    ?.sort()
    ?.filter((eachItem) =>
      eachItem?.toLowerCase()?.includes(searchCurrencyTerm.toLowerCase())
    );

  return (
    <div className="card-body py-4 ">
      <Box sx={style} className="modal-content-processor ">
        <div className="modal-header">
          <span className="modal-header-container">
            <div className="modal-header-img-container">
              <Wallet />
            </div>
            <div className="modal-header-title-container">
              <div className="modal-header-title">
                {t("ADD_A_PAYMENT_PROCESSOR")}
              </div>
              <div className="modal-header-subtitle">
                {t("SELECT_THE_PAYMENT_PROCESSOR")}
              </div>
            </div>
          </span>
          <span className="modal-header-close" onClick={() => setOpen(false)}>
            <Close />
          </span>
        </div>
        <div className="payment-processor-alignment-container">
          {/* Search Input */}
          {/* <div style={{ width: "300px" }}> */}
          <div>
            <input
              className="payment-processor-search"
              placeholder={t("SEARCH")}
              type="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
          {/* </div> */}
          {/* Filters */}
          {/* Region Filter */}

          <Box>
            <FormControl size="small" fullWidth>
              <InputLabel>Region</InputLabel>
              <Select
                multiple
                value={region}
                onChange={handleRegionChange}
                renderValue={(selected) => selected.join(", ")}
                className="processor-currency-dropdown"
                label={regionInputStatuts ? "Region" : ""}
                // onFocus={() => setRegionInputStatuts(true)}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="dropdown-search-field"
                >
                  <input
                    type="search"
                    value={searchRegionTerm}
                    placeholder="Search"
                    onChange={(e) => setSearchRegionTerm(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    spellCheck={false}
                  />
                </div>

                <MenuItem
                  value="Select All"
                  className="each-dropdown-menu-item"
                >
                  <Checkbox checked={region.length === regions.length} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                <hr className="hr-line" />

                {
                  // regions
                  //   ?.sort()
                  //   ?.filter((eachItem) =>
                  //     eachItem
                  //       ?.toLowerCase()
                  //       ?.includes(searchRegionTerm.toLowerCase())
                  //   )
                  filterRegionData?.map((r) => (
                    <MenuItem
                      key={r}
                      value={r}
                      className="each-dropdown-menu-item"
                    >
                      <Checkbox checked={region.includes(r)} />
                      <ListItemText primary={r} />
                    </MenuItem>
                  ))
                }
                {/* {filterRegionData?.length === 0 && (
                  <div className="no-data-found-container">
                    <p>No Data Found</p>
                  </div>
                )} */}
              </Select>
            </FormControl>
          </Box>

          {/* Country Filter (Dynamic based on Region) */}

          <Box>
            <FormControl size="small" fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
                multiple
                value={selectedCountries}
                //selectedCountries
                onChange={handleCountriesChange}
                // disabled={!region || countryList.length === 0}
                renderValue={(selected) => selected.join(", ")}
                className="processor-currency-dropdown"
                label={countryInputStatus ? "Country" : ""}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="dropdown-search-field"
                >
                  <input
                    type="search"
                    value={searchCountryTerm}
                    placeholder="Search"
                    onChange={(e) => setSearchCountryTerm(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    spellCheck={false}
                  />
                </div>

                <MenuItem
                  value="Select All"
                  className="each-dropdown-menu-item"
                >
                  <Checkbox checked={allCountriesSelected} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                <hr className="hr-line" />

                {
                  // countriesList?.sort()?
                  filterCountryData?.map((c) => (
                    <MenuItem
                      key={c}
                      value={c}
                      className="each-dropdown-menu-item"
                    >
                      <Checkbox checked={selectedCountries.includes(c)} />
                      {/* selectedCountries */}
                      <ListItemText primary={c} />
                    </MenuItem>
                  ))
                }

                {/* {filterCountryData?.length === 0 && (
                  <div className="no-data-found-container">
                    <p>No Data Found</p>
                  </div>
                )} */}
              </Select>
            </FormControl>
          </Box>

          {/* Currency Filter */}

          <Box>
            <FormControl size="small">
              <InputLabel>Currency</InputLabel>
              <Select
                multiple
                value={selectedCurrencies}
                onChange={handleCurrencyChange}
                // disabled={!selectedCountries.length > 0}
                className="processor-currency-dropdown"
                renderValue={(selected) => selected.join(", ")}
                label={currencyInputStatus ? "Currency" : ""}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="dropdown-search-field"
                >
                  <input
                    type="search"
                    value={searchCurrencyTerm}
                    placeholder="Search"
                    onChange={(e) => setSearchCurrencyTerm(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    spellCheck={false}
                  />
                </div>
                <MenuItem value="all" className="each-dropdown-menu-item">
                  <Checkbox checked={selectAllCurrencies} />
                  Select All
                </MenuItem>
                <hr className="hr-line" />
                {
                  // filteredCurrencies?.sort()
                  filterCurrencyData?.map((currency) => (
                    <MenuItem
                      key={currency}
                      value={currency}
                      className="each-dropdown-menu-item"
                    >
                      <Checkbox
                        checked={selectedCurrencies.includes(currency)}
                      />
                      {currency}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>

          {/* Payment Type Filter */}

          {/* <Box>
            <FormControl size="small" fullWidth>
              <InputLabel>Payment Type</InputLabel>
              <Select
                // onClick={() => setPaymentInputStatus(true)}
                multiple
                value={paymentType}
                onChange={handlePaymentChange}
                renderValue={(selected) => selected.join(", ")}
                className="processor-currency-dropdown"
                label={paymentInputStatus ? "Payment Type" : ""}
              >
                {/* <div className="drop-down-menu-item"> */}
          {/* <MenuItem
                  value="Select All"
                  className="each-dropdown-menu-item"
                >
                  <Checkbox
                    checked={
                      paymentType.length === paymentTypes.length &&
                      paymentTypes.length > 0
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                <hr className="hr-line" />
                {paymentTypes.map((p) => (
                  <MenuItem
                    key={p}
                    value={p}
                    className="each-dropdown-menu-item"
                  >
                    <Checkbox checked={paymentType.includes(p)} />
                    <ListItemText primary={p} />
                  </MenuItem>
                ))}
                {/* </div> */}
          {/* </Select>
            </FormControl>
          </Box>  */}
          <div className="apply-cancel-button-container">
            {/* <Button
              variant="contained"
              className="btn btn-primary mb-2"
              startIcon={<SaveIcon />}
              onClick={() => {
                applyFilters();
              }}
              // disabled={!selectedCurrencies?.length > 0}
            >
              {t("APPLY")}
            </Button> */}
            <Button
              variant="outlined"
              className="btn btn-light-primary me-3 mb-2"
              onClick={() => {
                resetFilters();
              }}
            >
              {t("RESET")}
            </Button>
          </div>
        </div>

        <div className="mt-4">
          <Box
            sx={{
              // flexGrow: 1,
              // gap: "2",
              display: "flex",
              flexWrap: "wrap",
              height: "50vh",
              overflow: "auto",
              padding: "24px 32px",
            }}
          >
            {newProcessorsFilters?.length > 0 ? (
              <Grid
                container
                // spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {newProcessorsFilters?.map((item) => {
                  // console.log(item, "each seaching item");
                  return (
                    <Grid item key={item.processorCode}>
                      <div
                        onClick={() => {
                          setSelected(item.processorCode);
                          setIsAddProcessorEnabled(true);
                        }}
                      >
                        <Item
                          selected={item.processorCode === selected}
                          item={item}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box className="spinner-container pt-3">
                {loader ? (
                  <div className="spinner"></div>
                ) : (
                  "No Matching Processor"
                )}
              </Box>
            )}
          </Box>
        </div>

        {/* <div className="modal-footer">
          <Box className="modal-footer-container mb-3 space-between">
            <button
              className="modal-footer-cancel btn btn-light-primary me-2 mb-2"
              onClick={() => setOpen(false)}
            >
              {t("CANCEL")}
            </button>
            <button
              className="modal-footer-ok btn btn-primary mb-2 w"
              onClick={() => addPayment(selected)}
            >
              {t("ADD_PROCESSOR")}
            </button>
          </Box>
        </div> */}
      </Box>
      <div className="modal-footer mt-4">
        <Box className="modal-footer-container mb-3 space-between">
          <button
            className="modal-footer-cancel btn btn-light-primary me-2 mb-2"
            onClick={() => setOpen(false)}
          >
            {t("CANCEL")}
          </button>
          <button
            className="modal-footer-ok btn btn-primary mb-2 w"
            onClick={() => addPayment(selected)}
            disabled={!isAddProcessorEnabled}
          >
            {t("ADD_PROCESSOR")}
          </button>
        </Box>
      </div>
    </div>
  );
};

export default AddPaymentProcessorModal;
